import { Colors } from './Colors';
import { styles } from './styles';
const baseTransitionTime = .2;
const baseRadius = 5;
export const theme = {
    palette: Colors,
    spacing: {
        default: {
            borderRadius: baseRadius,
            padding: styles.basePadding,
            margin: styles.baseMargin,
            menuZIndex: 2000,
            baseFontSize: 1.4,
            denseFontSize: 1.2,
            headerFontSize: 1.6,
            largeFontSize: 1.4,
            transitionTime: baseTransitionTime
        },
        primary: {
            borderRadius: baseRadius,
            padding: styles.basePadding,
            margin: styles.baseMargin,
            baseFontSize: 1.4,
            denseFontSize: 1.2,
            headerFontSize: 1.6,
            largeFontSize: 1.4
        },
        outlined: {
            borderRadius: baseRadius,
            padding: styles.basePadding,
            margin: styles.baseMargin,
            baseFontSize: 1.4,
            denseFontSize: 1.2,
            headerFontSize: 1.6,
            largeFontSize: 1.4
        },
        tertiary: {
            borderRadius: baseRadius,
            padding: styles.basePadding,
            margin: styles.baseMargin,
            baseFontSize: 1.4 ,
            denseFontSize: 1.2,
            headerFontSize: 1.6,
            largeFontSize: 1.4
        },
        tooltip: {
            padding: styles.basePadding,
            margin: styles.basePadding,
            fontSize: 1.4,
            maxWidth: 300
        },
        table: {
            fontSize: '1.4rem',
            padding: styles.basePadding,
            header: {
                minHeight: 48 - (styles.basePadding * 2)
            },
            cell: {
                fontSize: '1.3rem',
                lineHeight: '1.3rem',
                padding: styles.basePadding,
                sortWidth: 10
            },
            row: {
                minHeight: null
            }
        },
        switches: {
            padding: styles.basePadding,
            margin: styles.baseMargin,
            borderRadius: 0,
            width: 18,
            height: 18,
            fontSize: 1.3,
            errorFontSize: (1.4 / 1.5),
            borderWidth: 2,
            toggleBackgroundHeight: 16,
            toggleBackgroundWidth: 32
        },
        tabmenu: {
            height: styles.basePadding * 6,
            minWidth: styles.basePadding * 20,
            paddingLeft: styles.basePadding * 4,
            paddingRight: styles.basePadding * 4,
            fontSize: '1.5rem',
            slimFontSize: '1.3rem',
            indicatorHeight: 2,
            inactiveIndicatorHeight: 1,
            tabHeight: styles.basePadding * 6
        },
        inputs: {
            padding: styles.basePadding,
            margin: styles.baseMargin,
            fontSize: styles.baseFontSize,
            legacyErrorFontSize: styles.baseFontSize * .6667, // deprecated, only used with TextBox
            errorFontSize: styles.baseFontSize,
            labelFontSize: styles.baseFontSize - .4,
            borderRadius: '2px',
            underlineHeight: 2,
            dropDownOffset: 0,
            menuZIndex: 2000
        },
        dropDown: {
            default: {
                padding: styles.basePadding,
                defaultPadding: styles.basePadding,
                lineHeight: 40,
                height: 40,
                ulheight: 'fit-content',
                width: 300,
                iconSize: 15,
                fontSize: 1.4,
            },
            dense: {
                padding: styles.basePadding,
                defaultPadding: styles.basePadding,
                lineHeight: 32,
                height: 32,
                ulheight: 'fit-content',
                width: 192,
                iconSize: 15,
                fontSize: 1.2,
            },
            dark: {
                padding: styles.basePadding,
                defaultPadding: styles.basePadding,
                lineHeight: 32,
                height: 32,
                ulheight: 'fit-content',
                width: 192,
                iconSize: 15,
                fontSize: 1.2,
            }
        },
        card: {
            padding: styles.basePadding,
            margin: styles.baseMargin,
            bodyFontSize: 1.4,
            headerFontSize: 2.4,
            subtitleFontSize: 1.8
        },

        dialog: {
            padding: styles.basePadding,
            margin: styles.basePadding,
            menuZIndex: 2000
        }
    }
};
