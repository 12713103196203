import React, { useContext } from "react";
import {Card} from 'zebra-stripes/Layouts'
import {enrichedProductDescription} from '../../helpers/ProductHelper'
import {Icon} from "zebra-stripes/Symbols/Icon";
import './ProductCard.scss';
import {EventContext} from "../../contexts/EventContext";

export const ProductCard = (props) => {

    const {
        product = null,
        onClick = () => false,
    } = props;
    const { event, session, track } = useContext(EventContext);
    let productTitle = product.name;
    const extendedProductObj = {
        ...product,
        event: event,
        session: session,
        track: track,
    }
    return product.cost > 0  ? (
        <Card className={"purchase-option " + (onClick ? "activeclick" : "")} onClick={onClick} style={onClick ? {cursor: 'pointer'} : null} title={product.customName ?? "Purchase this " + productTitle}>
            <div className="price-point">
                <div>Price:<p>${product.cost}</p></div>
            </div>
            <p className='product-description'>
                {product.customDescription ?? enrichedProductDescription(extendedProductObj)}
            </p>
        </Card>
    ) : (
        <Card className={"purchase-option " + (onClick ? "activeclick" : "")} onClick={onClick} style={onClick ? {cursor: 'pointer'} : null} title={product.customName ?? "Register for this " + productTitle}>
            <div className="free-price-point">
                <div>
                    <Icon iconid="add" color="white" size="60px" basestyle={{position: 'relative', top: '0'}}/>
                </div>
            </div>
            <p className='product-description'>
                {product.customDescription ?? enrichedProductDescription(extendedProductObj)}
            </p>
        </Card>
    );
}