import {API} from "../../controllers/API";

export const getMediaURL = (type, site, sessionid) => {
    const presentationUrl = API.presentationMediaURL;
    const videoUrl = API.videoURL;
    const VIDEOS = {
        'flv' :   presentationUrl + '/' + site + '/AS-' + sessionid + '.flv',
        'flvAS' : presentationUrl + '/' + site + '/AS-' + sessionid + '.mp4',
        'flvVS' : presentationUrl + '/' + site + '/VS-' + sessionid + '.mp4',
        'flvV' :  presentationUrl + '/' + site + '/V-' + sessionid + '.mp4',
        'flvEN' : presentationUrl + '/' + site + '/EN-' + sessionid + '.mp4',
        'flvWD' : presentationUrl + '/' + site + '/WD-' + sessionid + '.mp4',

        'P_flvAS' : videoUrl + '/' + btoa(site) + '/AS/' + sessionid,
        'P_flvVS' : videoUrl + '/' + btoa(site) + '/VS/' + sessionid,
        'P_flvV' :  videoUrl + '/' + btoa(site) + '/V/' + sessionid,
        'P_flvEN' : videoUrl + '/' + btoa(site) + '/EN/' + sessionid,
        'P_flvWD' : videoUrl + '/' + btoa(site) + '/WD/' + sessionid,


        'ppt' :   presentationUrl + '/' + site + '/' + sessionid + '.ppt',
        'pptx' :  presentationUrl + '/' + site + '/' + sessionid + '.pptx',
        'pdf' :   presentationUrl + '/' + site + '/' + sessionid + '.pdf',
        'mp3' :   presentationUrl + '/' + site + '/' + sessionid + '.mp3',
        'doc' :   presentationUrl + '/' + site + '/' + sessionid + '.doc',
        'docx' :  presentationUrl + '/' + site + '/' + sessionid + '.docx'
    };
    return (VIDEOS['P_' + type]);
};