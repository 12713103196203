import React, {useRef, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {AnimaticHeader} from "./AnimaticHeader";
import {ContactForm} from "./ContactForm";
import {Icon} from "zebra-stripes/Symbols/Icon";
import './CODHomepage.scss';

export const About = () => {

    const [imageKey, setImageKey] = useState(1);
    const imageKeyRef = useRef(1);
    const interval = useRef(null);

    useEffect(() => {
        interval.current = setInterval(() => {
            imageKeyRef.current = imageKeyRef.current === 4 ? 1 : imageKeyRef.current + 1
            setImageKey(imageKeyRef.current);
        }, 4000);
        return () => {
            clearInterval(interval.current);
        }
    }, []);

    return (
        <article className="homepage">
            <AnimaticHeader />
            <div className='hero mini'>
                <a href='/home'><img alt='COD Logo' src='/images/homepage/COD-Live_LOGO2020_4c.png' /></a>
            </div>
            <div className="content_wrapper" id='homepage_hosting'>

                <div>
                    <h3>About</h3>
                    <div className='split'>
                        <div className='image_rotator'>
                            <img className={imageKey === 1 ? 'show' : ''} src='/images/homepage/vMIX_Call-In-Page_SAMPLE.jpg' alt='VMIX Call Image' />
                            <img className={imageKey === 2 ? 'show' : ''} src='/images/homepage/Slide1-3.jpg' alt='COD Marketing Image' />
                            <img className={imageKey === 3 ? 'show' : ''} src='/images/homepage/NOHC-Slides+1.jpg' alt='COD Marketing Image' />
                            <img className={imageKey === 4 ? 'show' : ''} src='/images/homepage/8up.jpg' alt='COD Marketing Image' />
                        </div>
                        <div className='content_box wide'>
                            <p>Conference-On-Demand +LIVE is a full feature virtual platform for events and tradeshows that can be delivered as recorded, hybrid or live sessions streamed to any number of simultaneous attendees.</p>
                            <p>We build this platform from the ground up to uniquely deliver the same polished production in a webinar format, that you have come to know <a href='http://www.animatic.com'>Animatic Media</a> for in our live onsite events.</p>
                            <p>Different them most “do-it-yourself” platforms our team will work with you to create a fully produced event working with you, your sponsors and speakers.</p>
                        </div>
                    </div>
                </div>



            </div>
            <div className="content_wrapper notop">
                <div>
                    <h3>Contact</h3>
                    <ContactForm isBlog={false} />
                </div>
            </div>
        </article>
    )
}