import React, {useRef, useState} from "react";
import { Link } from "react-router-dom";
import {AnimaticHeader} from "./AnimaticHeader";
import {ContactForm} from "./ContactForm";
import {Icon} from "zebra-stripes/Symbols/Icon";
import './CODHomepage.scss';

export const CODDemo = () => {
    return (
        <article className="homepage">
            <AnimaticHeader />
            <div className='hero mini'>
                <a href='/home'><img alt='COD Logo' src='/images/homepage/COD-Live_LOGO2020_4c.png' /></a>
            </div>
            <div className="content_wrapper" id='homepage_hosting'>

                <div>
                    <h3>Demo</h3>
                    <p>If you wish to visit out demo site on your own please feel free to visit <a href="https://www.conferenceondemand.net/demo/free">here</a></p>
                    <p>To demo the panelist experience you can click <a href='https://www.conferenceondemand.net/demojoin'>here</a>.</p>
                    <p>To schedule a live demo with a representative and see the full power of production services use the contact form below:</p>
                </div>




            </div>
            <div className="content_wrapper notop" id='homepage_contact'>
                <div>
                    <h3>Contact</h3>
                    <ContactForm isBlog={false} />
                </div>
            </div>
        </article>
    )
}