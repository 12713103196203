import React, { useContext, useState, useEffect } from "react";
import {EventContext} from "../contexts/EventContext";
import {AuthenticationContext} from "../contexts/AuthenticationContext";
import {TextBox, DropDown, Item, DatePicker, Button} from 'zebra-stripes/Forms'
import {Icon} from 'zebra-stripes/Symbols/Icon';
import './SessionSearch.scss';
import {UserEventContext} from "../contexts/UserEventContext";
var moment = require('moment-timezone');

export function SessionSearch({
    onSubmit,
}) {
    const { event } = useContext(EventContext);
    const { user } = useContext(AuthenticationContext);
    const { logEvent } = useContext(UserEventContext);

    const [ sort, setSort ] = useState('date');
    const [ search, setSearch ] = useState('');
    const [ hours, setHours ] = useState(null);
    const [ startdate, setStartdate ] = useState(event.startdate);
    const [ enddate, setEnddate ] = useState(event.enddate);

    // console.log(event, user);
    const executeSearch = () => {
        if (search) {
            logEvent(
                user?.userid,
                event.id,
                null,
                'search',
                search
            );
        }

        const searchOptions = {
            sort,
            search,
            hours,
            startdate: moment(startdate).format('YYYY-MM-DD'),
            enddate: moment(enddate).format('YYYY-MM-DD'),
        }
        onSubmit(searchOptions);
    }

    useEffect(() => {
        if (search === '') {
            executeSearch();
        }
    }, [search, sort]);

    const checkForSubmit = (keycode) => {
        if(keycode === 13) {
            executeSearch();
        }
    }

    const clearSearch = () => {
        setSearch('');
    }

    const clearAllSearch = () => {
        setSort('date');
        setSearch('');
        setHours(null);
        setStartdate(event.startdate);
        setEnddate(event.enddate);
    }

    let hours_options = [<Item value={null} defaultChecked={!hours} key={"ao0"}></Item>];
    for (let i = 0; i < 24; i++) {
        let lab = i === 0 ? 12 : (i > 12 ? i - 12 : i); //Math.abs(i-12);
        lab = lab + (i < 12 ? " AM" : " PM");
        hours_options.push(
            <Item value={i} defaultChecked={hours === i} key={"ao"+i}>{lab}</Item>,
        );
    }

    let sort_options = [
        <Item value="date" defaultChecked={sort === "date"} key="so1">Sort By Date</Item>,
        <Item value="session" defaultChecked={sort === "session"} key="so1">Alphabetical by Session</Item>,
        <Item value="track" defaultChecked={sort === "track"} key="so2">Alphabetical By Track</Item>,
        <Item value="speaker" defaultChecked={sort === "speaker"} key="so2">Alphabetical By Speaker</Item>
    ];

    const dateConstraint = [event.startdate,event.enddate];

    return (
        <div className='SessionSearch'>
            <div className="searchsessions_header">
                <h3>Search Sessions</h3>
            </div>
            <div className='search_fields_container'>
                <div className="searchcontainer">
                    <Icon key="i1" iconid="search" color="#a4a4a4"/>
                    <TextBox
                             onKeyPress={checkForSubmit}
                             width="100%"
                             style={{minWidth: '100px'}}
                             value={search}
                             dense
                             onChange={(e,v) => setSearch(v)}
                             placeholder="Search Sessions"
                             anchor={<Icon key="i2" style={{top: '2px'}} className="search_clear" onClick={clearSearch} size={'12px'} iconid="close" color="rgb(170,170,170)"/>}
                    />
                </div>
                <DatePicker dateConstraint={dateConstraint} onSet={(d) => setStartdate(d)} date={startdate} width="100%" placeholder="Date From"/>
                <DatePicker dateConstraint={dateConstraint} onSet={(d) => setEnddate(d)} date={enddate} width="100%" placeholder="Date To"/>
                <DropDown dense value={hours} onChange={(h) => setHours(h)} emptyLabel="any" width="100%" placeholder="Start Hour">
                    {hours_options}
                </DropDown>
                <DropDown dense value={sort} onChange={(s) => setSort(s)} emptyLabel="-- default sort --" style={{marginRight: 0}} width="100%" placeholder="Sort By">
                    {sort_options}
                </DropDown>
            </div>
            <div style={{textAlign: 'right'}}>
                <Button dense onClick={clearAllSearch}>Clear</Button> <Button dense type="primary" onClick={executeSearch}>Search Sessions</Button>
            </div>
        </div>
    )
}
