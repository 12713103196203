import React, { useState, useEffect } from "react";
import {TextBox, Button} from "zebra-stripes/Forms";
import {useParams} from "react-router-dom";
import {useAPI} from "../contexts/useAPI";
import {API} from "./API";
import './JoinController.scss';
const m = require('moment-timezone');

export const JoinController = (props) => {

    const {
        password = '7606654865',
    } = props;

    let paths = useParams();

    const [name, setName] = useState('');
    const [invitationPassword, setInvitationPassword] = useState(password);
    const [sessionID, setSessionID] = useState(paths.sessionId || 'null');
    const [nameError, setNameError] = useState(null);
    const [invitationError, setInvitationError] = useState(null);
    const [event, setEvent] = useState(null);
    const [session, setSession] = useState(null);

    const {
        postService
    } = useAPI();

    useEffect(() => {
        if (sessionID) {
            const getSessionPayload = {
                session_id: sessionID
            };
            postService(API.sessionInfo, getSessionPayload)
                .then((data) => {
                    if (data) {
                        setEvent(data.event);
                        setSession(data.session);
                    }
                });
        }
    }, []);

    const submitForm = () => {
        if(!name || !invitationPassword) {
            setNameError(!name ? 'Please enter a name...' : null);
            setInvitationError(!invitationPassword ? 'Please enter an invitation code...' : null);
        } else {
            window.location = `/presenterShare/${invitationPassword}/${sessionID}/${btoa(name)}`;
            return false; // prevent the form from being submitted
        }
    };

    const testKey = (e) => {
        const  code = (e.keyCode ? e.keyCode : e.which);
        if(code === 13) { //Enter keycode
            submitForm();
        }
    };

    console.log(sessionID);
    return (
        <article className='join'>
            <div className='login_form'>
                <div className='logo_wrap'>
                    <img src='/images/COD-Live_LOGO2020_4c_Poweredby-AM-large.png' alt='COD LIVE Logo' />
                </div>
                <div/>
                <div>
                    {session && <SessionInfo session={session} event={event} />}
                    <div className='input_wrapper'>
                        <TextBox
                            width="100%"
                            tabIndex="1"
                            value={name}
                            onChange={(e,v) => setName(v)}
                            onEnterKey={submitForm}
                            placeholder="Enter Your Name"
                            error={nameError}
                            showBorder={true}
                        />
                    </div>
                    <div className='input_wrapper'>
                        <TextBox
                            width="100%"
                            tabIndex="2"
                            value={invitationPassword}
                            onChange={(e,v) => setInvitationPassword(v)}
                            onEnterKey={submitForm}
                            placeholder="Enter Invitation Code"
                            error={invitationError}
                            showBorder={true}
                        />
                    </div>
                    <div className='join_button_group'>
                        <Button tabIndex="3" type='primary' onClick={submitForm}>Join</Button>
                        <p>If you are experiencing technical difficulties call us at<br/>888-726-8500 and press Option #1</p>
                    </div>
                </div>
            </div>
        </article>
    )
}

const SessionInfo = (props) => {
    const { session, event } = props;
    let sdate = m(session.sessiondate);
    let stime = session.starttime ? session.starttime.split(":"): [0,0,0];
    sdate.hour(stime[0]);
    sdate.minutes(stime[1]);

    let edate = m(session.sessiondate);
    let etime = session.endtime ? session.endtime.split(":"): [0,0,0];
    edate.hour(etime[0]);
    edate.minutes(etime[1]);

    let speakers = session.speaker1 ? [
        <span className={`speaker_name`}>{session.speaker1}</span>
    ] : [];
    if(session.speaker2) {
        speakers.push(<span className={`speaker_name`}>{session.speaker2}</span>);
    }
    if(session.speaker3) {
        speakers.push(<span className={`speaker_name`}>{session.speaker3}</span>);
    }

    return (
        <div className='event_info'>
            <h4>{event.sitename}</h4>
            <h3>{atob(session.sessiontitle)}</h3>
            <p className="session_date">{sdate.format("dddd, MMMM D, YYYY h:mm a")} {event.timezone} {session.location && (' | ' + session.location)}</p>
            {speakers.length ? <p key="f4" className="session_speakers">Speaker(s):
                {speakers.map((speaker, i) => {
                    return [(i > 0 ? ', ' : ''),speaker];
                })}</p> : null}
            {session.panelists ? <span>Panelists: {session.panelists}</span> : null}
        </div>
    );
}