import React, {useRef, useState} from "react";
import { Link } from "react-router-dom";
import {AnimaticHeader} from "./AnimaticHeader";
import {ContactForm} from "./ContactForm";
import {Icon} from "zebra-stripes/Symbols/Icon";
import './CODHomepage.scss';

const videoObj = {
    default:   { file: "WhatIsCOD-Section02-720x270.mp4", title: "Default" },
    Section06: { file: "Section06.mp4", title: "Presentation with Slides" },
    Section04: { file: "Section04.mp4", title: "Slides with Audio" },
    Section07: { file: "Section07-wCPs.mp4", title: "Presentation with Slides and Cue Point" },
    Section03: { file: "Section03.mp4", title: "Session Video Alone" },
    Section05: { file: "Section05.mp4", title: "Audio Alone" }
};

export const CODHomepage = (props) => {

    const [videoShow, setVideoShow] = useState(false);
    const videoRef = useRef(null);

    const toggleVideo = () => {
        setVideoShow(!videoShow);
        if(!videoShow) {
            videoRef.current.load();
            videoRef.current.play();
        } else {
            videoRef.current.pause();
        }
    };

    return (
        <div>
            <article className="homepage">

                <AnimaticHeader />

                <div className='hero'>
                    <Link to={'/home'}><img alt='COD Logo' src='/images/homepage/COD-Live_LOGO2020_4c.png' /></Link>
                    <div>
                        <p>Our team of production experts and event professionals create and deliver engaging virtual events and exhibits.</p>
                        <button onClick={toggleVideo}>Watch Video</button>
                    </div>
                </div>

                <div className="content_wrapper" id='homepage_hosting'>
                    <div>
                        <h3>Host your event, your way!</h3>
                        <p className='subline'>Conference on Demand + LIVE technology is created built from the ground up giving you the ability to host a  virtual conference, event or webinar for an unlimited number of simultaneous attendees.</p>
                        <div className='threeUp'>
                            <div className='check_box_layout'>
                                <img src='/images/homepage/CheckMark.png' />
                                <div>
                                    <h4>Fully Customizable</h4>
                                    <p className='subline'>From sponsorship opportunities, to custom graphics, 'voice of god' announcements , dedicated landing pages, and secure sign in  your event is produced  to be as unique as you. </p>
                                </div>
                            </div>
                            <div className='check_box_layout'>
                                <img src='/images/homepage/CheckMark.png' />
                                <div>
                                    <h4>All Event Types</h4>
                                    <p className='subline'>From conferences, event companies, charities, marketing agencies,  professional services, financial  to talent acquisition and graduations we cover events of all shapes and sizes across all industries. </p>
                                </div>
                            </div>
                            <div className='check_box_layout'>
                                <img src='/images/homepage/CheckMark.png' />
                                <div>
                                    <h4>Event Engagement</h4>
                                    <p className='subline'>Your attendees will be fully engaged with your event with pre-event registration, live event streaming and on-demand post event viewing.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content_wrapper knockout" id='homepage_recent'>
                    <div>
                        <h3>Our Recent Events</h3>
                        <div className='threeUp'>
                            <div className='sample_layout'>
                                <div style={{backgroundImage:'url(/images/homepage/NOHC-Capture.jpg)'}} />
                                <div>
                                    <p className='phead'>NOHC2020</p>
                                    <p className='subline'>21 Session fully produced virtual event with 40+ speakers, panels, polling and CEU credits for the oral healthcare industry.</p>
                                </div>
                            </div>
                            <div className='sample_layout'>
                                <div style={{backgroundImage:'url(/images/homepage/LCA20-Capture.jpg)'}} />
                                <div>
                                    <p className='phead'>LCA20-DOS</p>
                                    <p className='subline'>8 Person panel session for the catering industry to discuss moving forward during COVID-19 streamed to a live audience.</p>
                                </div>
                            </div>
                            <div className='sample_layout'>
                                <div style={{backgroundImage:'url(/images/homepage/DCW2019.jpg)'}} />
                                <div>
                                    <p className='phead'>Data Center World</p>
                                    <p className='subline'>Over 100 Sessions in 5 tracks on demand with purchase access to the IT industry for Informa, PLC.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content_wrapper" id='homepage_exhibits'>
                    <div>
                        <h3>Exhibits-on-Demand</h3>
                        <p className='subline'>Virtual Exhibits and Poster Sessions with features such as chat, video chat, video presentations file downloads and attendee tracking.</p>
                        <div className='fourUp'>
                            <div>
                                <img src='/images/homepage/Home.png' />
                            </div>
                            <div>
                                <img src='/images/homepage/Premeire Exhibitors.png' />
                            </div>
                            <div>
                                <img src='/images/homepage/All Exhibitors.png' />
                            </div>
                            <div>
                                <img src='/images/homepage/Call Page.png' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content_wrapper knockout" id='homepage_testimonials'>
                    <div>
                        <h3>Testimonials</h3>
                        <p>Committed to helping our clients succeed</p>
                        <div className='threeUp'>
                            <div className='testimonials'>
                                <div><img src='/images/homepage/exl-logo.png' /></div>
                                <p className='subline thin'>"I so appreciate your willingness to not only provide a top-of-the-line service – but a consultant to ensure my meeting was a success. The knowledge, patience and understanding of last minute requests and changes was invaluable to me."</p>
                                <p className='subline'>Allison Thompson<br/>
                                    Sr. Marketing Manager, Events<br/>
                                    EXL Service</p>
                                <p><img src='/images/homepage/stars.png' /></p>
                            </div>
                            <div className='testimonials'>
                                <div><img src='/images/homepage/GTDC_New-Logo_132x65.png' /></div>
                                <p className='subline thin'>"This conference features “C-level” executives from the largest technology and distribution companies in the world. Your assistance in attending to their needs and guiding them in the “do’s and don’ts” for their presentations is a project in and of itself, and as always, this year you did a stellar job!"</p>
                                <p className='subline'>Andrea Salanco<br/>
                                    Director, Planning & Operations<br/>
                                    GTDC</p>
                                <p><img src='/images/homepage/stars.png' /></p>
                            </div>
                            <div className='testimonials'>
                                <div><img src='/images/homepage/NOHC_Logo-forSlido.png' /></div>
                                <p className='subline thin'>"Thank you so much for helping us transition out 3 day conference into a fully virtual event. The feedback we received from speaker and attendees has been phenomenal and our team couldn’t be more impressed with your platform and the professionalism of your staff. We will definitely be using the Conference-On-Demand +LIVE platform again!"</p>
                                <p className='subline'>Jan Aument<br/>
                                    Event Director<br/>
                                    NOHC</p>
                                <p><img src='/images/homepage/stars.png' /></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content_wrapper" id='homepage_contact'>
                    <div>
                        <h3>Contact</h3>
                        <ContactForm isBlog={false} />
                    </div>
                </div>
            </article>
            <div className={`video_player ${videoShow ? 'show' : ''}`}>
                <Icon iconid='close' onClick={() => toggleVideo()} color='white' size='large' />
                <video width="80%" ref={videoRef} controls>
                    <source src="/images/homepage/Live_Webinar_09_April_2020.mp4" type="video/mp4" />
                </video>
            </div>
        </div>
    )
}
