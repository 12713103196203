import React, {useContext} from "react";
import {Button} from "zebra-stripes/Forms";
import {RegistrationContext} from "../contexts/RegistrationContext";

export const AccountCreatePurchaseConfirmation = (props) => {
    const {
        paymentResponse,
        onClose,
        willCreateNewAccount = true,
    } = props;

    const { userObj } = useContext(RegistrationContext);
    const confirmation = paymentResponse.id;
    const price = paymentResponse.amount;
    const product = paymentResponse.product;

    let chargeObj = price ?
        <p>Your card has been charged ${price/100}, and you can proceed view the content purchased from this event.</p>
        :
        product?.cost === 0 ? <p>You now have access to this session.</p> : product?.id ? <p>Your promotion has granted you access to this session.</p> : null;

    let successDOM = product.id && <>
        <p>Your confirmation <strong>{confirmation}</strong> has been emailed to you at {userObj?.email}.</p>
        {chargeObj}
    </>;



    return (
        <div>
            <h3>Account Created and Purchase Confirmed</h3>
            {willCreateNewAccount && <>
            <p>An account with the email, {userObj?.email} has been created, and you will get a confirmation email to confirm your account information.</p>
            </>}
            {successDOM}
            <Button type="primary" onClick={onClose}>Continue To Content</Button>
        </div>
    );
}
