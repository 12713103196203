import React, { useContext } from 'react'
import {enrichedProductDescription, calculateTotalPrice} from '../../helpers/ProductHelper';
import './ProductPricingSummary.scss';
import {EventContext} from "../../contexts/EventContext";
import {RegistrationContext} from "../../contexts/RegistrationContext";

export const ProductPricingSummary = () => {
    const { event, session } = useContext(EventContext);
    const { promo, product } = useContext(RegistrationContext);
    let finalPricing = calculateTotalPrice(product, promo);

    const extendedProductObj = {
        ...product,
        event: event,
        session: session,
    }
    return (
        <div className="ProductPricingSummary">
            <div>
                <h3>{product?.cost > 0 ? "Purchase Summary" : "Product Summary"}</h3>
                <div>
                    <p>Product Info:</p>
                    {enrichedProductDescription(extendedProductObj)}
                </div>
                <div>
                    <p>Base Price:</p>
                    <p>${product?.cost.toFixed(2)}</p>
                </div>
                {promo?.id &&
                <>
                    <div className="promotion">
                        <p>Promotion:</p>
                        <p>{promo.name}</p>
                    </div>
                    <div className="promotion">
                        <p>Discount:</p>
                        <p>${finalPricing.discount.toFixed(2)}</p>
                    </div>
                </>
                }
                <div className="total_container">
                    <p>Total:</p>
                    <p>${finalPricing.final_price.toFixed(2)}</p>
                </div>
            </div>
        </div>
    )

}