import React, {useRef, useState} from "react";
import { Link } from "react-router-dom";
import {AnimaticHeader} from "./AnimaticHeader";
import {ContactForm} from "./ContactForm";
import {Icon} from "zebra-stripes/Symbols/Icon";
import './CODHomepage.scss';

export const Features = (props) => {
    return (
        <article className="homepage">
            <AnimaticHeader />
            <div className='hero mini'>
                <a href='/home'><img alt='COD Logo' src='/images/homepage/COD-Live_LOGO2020_4c.png' /></a>
            </div>
            <div className="content_wrapper" id='homepage_hosting'>
                <div>
                    <h3>Features</h3>
                    <p className='subline'>Here are some Common Features for ALL Conference-On-Demand and Conference-On-Demand +LIVE events:</p>
                    <div className='content_box'>
                        <ul>
                            <li>Customization of your event look on a unique URL</li>
                            <li>Registration Services
                                <ul>
                                    <li>Open Registration or Closed to a specific list supplied by you</li>
                                </ul></li>
                            <li>Monetization
                                <ul>
                                    <li>You dictate if, what and how to charge to view sessions</li>
                                    <li>Example, By Session, Track or Entire Conference</li>
                                    <li>You can also use Promo Codes and customize them as you like</li>
                                </ul>
                            </li>
                            <li>Continuing education testing after attending a session</li>
                            <li>Slides with Audio</li>
                            <li>Video with Slides</li>
                            <li>Audio Only</li>
                            <li>Video Only</li>
                        </ul>
                        <p>You can even pause, Play & Rewind on-demand videos for viewing at your leisure</p>
                    </div>

                </div>

            </div>
            <div className="content_wrapper" id='homepage_contact'>
                <div>
                    <h3>Contact</h3>
                    <ContactForm isBlog={false} />
                </div>
            </div>
        </article>
    )
}