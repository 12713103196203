import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { EventContext } from '../contexts/EventContext';
import { pageResolver } from "./pageResolver";
import {UserEventContext} from "../contexts/UserEventContext";
import {AuthenticationContext} from "../contexts/AuthenticationContext";

export function EventHomepage() {
    let paths = useParams();
    const [ viewComponent, setViewComponent ] = useState({
        header: null,
        page: null,
    });
    const { setEventRoute, event } = useContext(EventContext);
    const { user, getUserInfo, userRequestComplete$ } = useContext(AuthenticationContext);
    const { logEvent } = useContext(UserEventContext);
    const initialLog = useRef(null);

    useEffect(() => {
        setEventRoute([paths.client, paths.eventpath]);
        setViewComponent(pageResolver(`${paths.client}/${paths.eventpath}`));
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        if (event && user && !initialLog.current) {
            logEvent(
                user?.userid || null,
                event.id,
                null,
                "view",
                "event"
            );
            initialLog.current = true;
        }
    }, [event, user]);

    // console.log('event in homepage', paths, event);
    return (
        <article className={`${paths.client}${paths.eventpath}`}>
            {viewComponent.header}
            {viewComponent.page}
            {viewComponent.footer}
        </article>
    )
}
