import React, { useContext, useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EventContext } from '../contexts/EventContext';
import { pageResolver } from "./pageResolver";
import {API, API_SERVER} from "./API";
import {AuthenticationContext} from "../contexts/AuthenticationContext";
import {useAPI} from "../contexts/useAPI";
import {Button} from 'zebra-stripes/Forms';
import {TradeShowHeader} from "../components/TradeShow/TradeShowHeader";
import {ExpoRotator} from "../components/TradeShow/ExpoRotator";
import {ChatEngine} from "../components/SessionViewer/ChatEngine";
import {UserEventContext} from "../contexts/UserEventContext";
import {CODFooter} from "../components/CODFooter";
import {LoginForm} from "../components/LoginForm";
import {Registration} from "../components/Registration";
import { useBeforeunload } from 'react-beforeunload';

export function TradeShowExhibit(props) {
    let paths = useParams();
    const exhibitPath = paths.exhibit.toLowerCase();

    const { logEvent } = useContext(UserEventContext);
    const {
        postService
    } = useAPI();
    const [ exhibits, setExhibits ] = useState([]);
    const [ showRegistration, setShowRegistration ] = useState(false);
    const [ hasPremiere, setHasPremiere ] = useState(false);
    const [ exhibit, setExhibit ] = useState(null);
    const [ assetPath, setAssetPath ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ downloads, setDownloads ] = useState([]);
    const [ messageSent, setMessageSent ] = useState(false);
    const [ messageError, setMessageError ] = useState(null);
    const chatIsInitiated = useRef(false);
    const { isAuthenticated, user, getUserInfo, launchRegistration$, loginEvent$ } = useContext(AuthenticationContext);
    const { setEventRoute, event } = useContext(EventContext);

    const downloadSelect = useRef(null);
    const messageField = useRef(null);
    const chatEngineRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        setEventRoute([paths.client, paths.eventpath]);
        const loginSubscribe = loginEvent$.subscribe(initiateChat);
        return () => {
            loginSubscribe.unsubscribe();
        }
    }, []);

    useBeforeunload(() => {
        logClose();
    });

    useEffect(() => {
        if (event && exhibits.length === 0) {
            let getTradeShowPayload = {
                path: `${paths.client}/${paths.eventpath}/`,
                userid: user?.userid ?? null,
            };
            postService(
                API.tradeShowAPI,
                getTradeShowPayload,
            )
                .then(data => {
                    let premiereCount = 0;
                    data.exhibits.map((exhibit) => {
                        if (exhibit.featured) {
                            premiereCount++;
                        }
                    });
                    setHasPremiere(!!premiereCount);
                    setExhibits(data.exhibits);
                    const exhibit = data.exhibits.filter(e => {
                        const exhibitpath = e.name.toLowerCase().replace(/\s/g, '');
                        return exhibitpath === paths.exhibit.toLowerCase();
                    });
                    setExhibit(exhibit.length > 0 ? exhibit[0] : null);
                    setDownloads(exhibit[0].download_files.split(','));
                    setAssetPath(`${API_SERVER}/presentation_media${event.alias}tradeshow`);


                });
        }
    }, [event]);

    useEffect(() => {
        if (user && !chatIsInitiated.current) {
            initiateChat();
        }
        if (!user) {
            setShowRegistration(false);
        }
    }, [user]);

    useEffect(() => {
        if (exhibit) {
            logBoothEvent('view', 'booth');
            let sessionMP4 = document.getElementById("sessionMP4");
            if(sessionMP4) {
                sessionMP4.onplay = () => {
                    logBoothEvent("boothvideo", "play");
                };

                sessionMP4.onseeked = (e) => {
                    e.stopImmediatePropagation();
                    e.preventDefault();
                    logBoothEvent("boothvideo", "seek");
                };
                sessionMP4.onended = () => {
                    logBoothEvent("boothvideo", "finish");
                };
            }
            if (user) {
                initiateChat();
            }
        }
    }, [exhibit]);

    const logClose = () => {
        logBoothEvent("close", "booth");
    }

    const initiateChat = () => {
        if (chatEngineRef.current) {
            const chatId = (event.id + '' + exhibit.id)*1;
            chatEngineRef.current.initChat(chatId);
            chatIsInitiated.current = true;

        }
    }

    const submitForm = () => {
        const message = messageField.current.value;
        const userInfo = getUserInfo();

        const messageError = !message.length;
        if(messageError) {
            setMessageError(true);
        } else {

            const formPayload = {
                key: exhibitPath,
                event_id: exhibit.event_id,
                booth_id: exhibit.id,
                event_name: btoa(event.name),
                email: btoa(userInfo.email),
                fname: btoa(userInfo.firstname),
                lname: btoa(userInfo.lastname),
                company: btoa(userInfo.company),
                wphone: btoa(userInfo.wphone),
                mphone: btoa(userInfo.mphone),
                message: message,
            };

            postService(
                API.boothContactAPI,
                formPayload,
            )
                .then(() => {
                    setTimeout(() => {
                        messageField.current.value = "";
                        setMessageError(false);
                        setMessageSent(true);
                        setTimeout(() => {
                            setMessageSent(false);
                        }, 5000);
                    }, 10);
                });
        }
    };

    const logBoothEvent = (type, value) => {
        logEvent(
            getUserInfo()?.userid || null,
            event.id,
            exhibit?.id || null,
            type,
            value,
        );
    }

    const clickLogoLink = () => {
        if (exhibit.url) {
            window.open(exhibit.url, '_blank');
        }
    }

    const downloadFile = () => {
        if(downloadSelect.current.value !== '-- Select a File --') {
            const exhibitpath = exhibit.name.toLowerCase().replace(/\s/g, '');
            logBoothEvent('download', downloadSelect.current.value);
            window.open(`${assetPath}/${exhibitpath}/downloads/${downloadSelect.current.value}`, '_blank');
        }
    };

    const videoimages = exhibit?.images ? exhibit.images.split(',') : [];
    const contentStyle = {
        background: `url(${assetPath}/${exhibitPath}/background.jpg) rgba(102,102,102)`
    };
    return (
        <article className={`TradeShow ${paths.client}${paths.eventpath}`}>
            {!isAuthenticated ?
                <article className='login-insert'>
                    <img src={`${assetPath}/logo.png`} />
                    {showRegistration ?
                        <Registration
                            standAlone={true}
                            onShowLogin={() => {
                                setShowRegistration(false);
                            }}
                            ignorePurchasing={true}
                            onCancel={() => {
                                setShowRegistration(false);
                            }}
                        />
                        :
                        <LoginForm
                            standalone={true}
                            eventid={event?.id}
                            hasRegistrations={event?.hasRegistrations}
                            onShowRegistration={() => setShowRegistration(true)}
                        />
                    }
                </article>
            :
            exhibit &&
            <>
            <div className='booth'>
                <div>
                    <div className={`booth_header`}>
                        <TradeShowHeader
                            exhibits={exhibits}
                            assetPath={assetPath}
                            hasPremiere={hasPremiere}
                        />
                        <div className='header_image'>
                            <img style={{cursor: exhibit.url ? 'pointer' : 'default'}}
                                 height='100px'
                                 src={`${assetPath}/${exhibitPath}/headerlogo.png`}
                                 alt={exhibit.name}
                                 onClick={clickLogoLink}
                            />
                        </div>
                    </div>
                </div>

                <div className={`booth_content`} style={contentStyle}>
                    {exhibit.video &&
                    <div className={`video_panel ${videoimages.length > 1 ? 'with_images' : ''}`}>
                        {videoimages.length > 1 ?
                            <ExpoRotator path={assetPath+'/'+exhibitPath+'/'} url={exhibit.url !== '' ? exhibit.url : null} images={videoimages} /> : null
                        }
                        <video style={{backgroundImage:'url(/images/start-splash-480.385.jpg)', backgroundSize: 'cover'}}
                               poster={assetPath+'/'+exhibitPath+'/videoinfo.jpg'}
                               id="sessionMP4"
                               width='100%'
                               preload="auto" controls={true}>
                            <source src={assetPath+'/'+exhibitPath+'/'+exhibit.video} type='video/mp4' />
                        </video>
                    </div>
                    }
                    {!exhibit.video && videoimages.length ?
                        <div className='single_rotator'>
                            <ExpoRotator path={assetPath+'/'+exhibitPath+'/'} url={exhibit.url !== '' ? exhibit.url : null} images={videoimages} />
                        </div> : null
                    }
                    <div className={`split_content ${!exhibit.chat ? 'single_column' : ''}`}>
                        {exhibit.chat &&
                        <div className='live_chat'>
                            <h3>Live Chat</h3>
                            <ChatEngine
                                userInfo={getUserInfo()}
                                eventId={event.id}
                                event={event}
                                forceShow={true}
                                chatTypes={{
                                    GROUP: true,
                                    QA: false,
                                    PRIVATE: false
                                }}
                                showHide={false}
                                postButtonText={'POST'}
                                ref={chatEngineRef}
                            />
                        </div>
                        }
                        <div>
                            {downloads.length > 1 &&
                            <div className='downloads'>
                                <h3>Downloads</h3>
                                <h4>Select files you wish to download</h4>
                                <select className='downloadDropDown' ref={downloadSelect} onChange={downloadFile} placeholder='Select file'>
                                    <option value={null} key='itemd'>-- Select a File --</option>
                                    {downloads.map((file, i) => (
                                        <option value={file} key={`item${i}`}>{file}</option>
                                    ))}
                                </select>
                            </div>
                            }
                            <div className='contact'>
                                <h3>Email Us</h3>
                                <h4>Message</h4>
                                {messageSent ?
                                    <div className='message_sent'>
                                        Thank You, your message has been sent to the exhibitor.
                                    </div> :
                                    <div>
                                        <textarea maxLength="300" className={`c_message ${messageError ? 'error' : ''}`} ref={messageField}
                                                  placeholder="Your message here"/>
                                        <Button type="primary" key="submit"
                                                      onClick={submitForm}>Submit</Button>
                                    </div>
                                }
                                {messageError && <p>Please supply a message.</p>}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <CODFooter />
            </>
            }

        </article>
    )
}
