import React from "react";

export const MP4Video = (props) => {
    const {
        file = null,
        width = 720,
        height = 480,
        onStartPlay = () => false,
    } = props;

    return (
        <video style={{backgroundImage:'url(/images/start-splash-480.385.jpg)', backgroundSize: 'cover'}}
               width={width} height={height}
               preload="auto" controls={true}
               id="sessionMP4"
        >
            <source src={file} type='video/mp4' />
        </video>
    )
};