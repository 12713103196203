import React, { useEffect, useState } from "react";

export const ExpoRotator = ({
    images = [],
    path = '',
    url = null,
}) => {

  const [ imageKey, setImageKey ] = useState(1);

  useEffect(() => {
      const rotateInterval = setInterval(() => {
          setImageKey(imageKey === images.length ? 1 : imageKey + 1)
      }, 6000);
      return () => {
          clearInterval(rotateInterval);
      }
  }, []);

    const clickImages = () => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    return (
        <div className='ExpoRotator' style={{cursor: url ? 'pointer' : 'default'}} onClick={clickImages}>
            {images.map((image, i) => <img className={imageKey === i+1 ? 'show' : ''} src={path + image} alt='Expo Rotator Image' />)}
        </div>
    );
}