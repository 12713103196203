let styles = require('./styles').styles;

export const Colors = {
    default: {
        textColor: styles.darkText,
        idleColor: styles.gray50,
        hoverText: '',
        border: styles.gray25,
        backgroundColor: 'white',
        backgroundHover: 'rgba(0,0,0,.08)',
        iconColor: styles.blue,
        headerBackgroundColor: styles.blue,
        activeIcon: styles.blue,
        inactiveIcon: styles.gray50,
        offHandleColor: styles.gray50,
        onHandleColor: styles.blue,
        onSwitchBackColor: styles.darkBlue,
        controlBarColor: styles.gray25
    },
    slider: {
        default: {
            valueBackgroundColor: styles.blue,
            valueTextColor: 'white',
            disabledHandleColor: styles.gray50,
            offHandleColor: styles.gray50,
            onHandleColor: styles.blue,
            onSwitchBackColor: styles.darkBlue,
            controlBarColor: styles.gray25,
            labelColor: styles.gray75
        }
    },
    tooltip: {
        primary: {
            backgroundColor: 'white',
            color: styles.darkBlue
        }
    },
    primary: {
        textColor: 'white',
        hoverText: '',
        backgroundColor: styles.blue75,
        backgroundHover: styles.blue,
        iconColor: styles.blue,
        headerBackgroundColor: styles.darkText,
        activeIcon: styles.blue,
        inactiveIcon: styles.gray25
    },
    outlined: {
        textColor: styles.blue,
        hoverText: '',
        backgroundColor: 'transparent',
        backgroundHover: 'transparent',
        iconColor: styles.blue,
        headerBackgroundColor: styles.darkText,
        activeIcon: styles.blue,
        inactiveIcon: styles.gray25
    },
    tertiary: {
        textColor: 'white',
        hoverText: '',
        border: '',
        backgroundColor: styles.darkGreen,
        backgroundHover: styles.lightGreen,
        iconColor: 'blue',
        headerBackgroundColor: styles.lightBackground,
        activeIcon: styles.primaryColor,
        inactiveIcon: styles.lightBackground
    },
    inputs: {
        textColor: styles.black,
        underlineColor: styles.primaryColor,
        errorColor: styles.red,
        inactiveUnderlineColor: styles.lightBackground, // deprecated -- only used for TextInput
        inactiveUnderErrorlineColor: styles.lightRed,
        underErrorlineColor: styles.red,
        borderColor: styles.primaryColor,
        idleBorderColor: styles.gray50,
        highlightColor: styles.gray25,
        highlightBorderColor: styles.primaryColor,
        activeIcon: styles.gray80,
        inactiveIcon: styles.gray02,
        inputBackground: 'transparent', //'white', //'rgba(255,255,255,.33)',
        placeholderColor: styles.gray75
    },
    table: {
        textColor: styles.black,
        header: {
            backgroundColor: 'transparent',
            border: styles.gray50,
            highlight: styles.primaryColor,
            textColor: styles.black
        },
        row: {
            border: styles.gray25,
            hoverColor: styles.grayBackground,
            zebraStripe: 'transparent',
            backgroundColor: 'transparent'
        }
    },
    tabmenu: {
        default: {
            background: 'white',
            selectedBackground: 'white',
            textColor: styles.darkText,
            activeTextColor: styles.darkText,
            indicator: styles.darkText,
            inactiveIndicator: styles.gray50,
            hoverColor: 'rgba(0, 124, 176, 0.1)',
            hoverText: styles.darkText,
            activeColor: 'rgba(0, 124, 176, 0.2)',
        },
        strong: {
            background: 'white',
            selectedBackground: styles.darkText,
            textColor: styles.darkText,
            activeTextColor: 'white',
            indicator: styles.darkText,
            inactiveIndicator: styles.gray50,
            hoverColor: 'rgba(0, 124, 176, 0.1)',
            hoverText: styles.darkText,
            activeColor: 'rgba(0, 124, 176, 0.2)',
        }
    },

    switches: {
        textColor: styles.darkText,
        borderColor: styles.blue,
        fillColor: styles.blue,
        fillColorSecondary: 'white',
        fillCheckColor: 'white',
        fillCheckColorSecondary: styles.darkText,
        checkImage: (color) => { return 'url("data:image/svg+xml;base64,' + btoa('<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'512\' height=\'512\'><polygon fill=\'' + color + '\' points=\'424 96 184 336 88 240 48 280 184 416 464 136 424 96\'/></svg>') + '")'; },
        minusImage: (color) => { return 'url("data:image/svg+xml;base64,' + btoa('<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox="0 0 512 512"><rect fill=\'' + color + '\' y="230" x="50" width="400" height="60"/></svg>') + '")'; },
        offHandleColor: '#ffffff',
        onHandleColor: styles.darkText,
        onSwitchBackColor: '#65a6c8',
        activeIcon: styles.blue,
        inactiveIcon: styles.gray50,
        hoverColor: '#EBEBEB',
        checkedHoverColor: 'rgba(0,122,186,0.1)',
        activeColor:'#D7D7D7',
        checkedActiveColor: 'rgba(0,124,176,0.2)',
        disabledColor: styles.gray50,
        toggleBackground: styles.darkBlue,
        toggleDisabledcolor: styles.gray25,
        toggleBorderColor: styles.darkBlue
    },

    card: {
        default: {
            titleColor: styles.darkText,
            textColor: styles.darkText,
            backgroundColor: 'white',
        },
        primary: {
            titleColor: styles.darkText,
            textColor: styles.darkText,
            backgroundColor: 'white'
        },
        secondary: {
            titleColor: styles.blue,
            textColor: styles.blue,
            backgroundColor: styles.grayBackground
        }
    },

    dropDown: {
        default: {
            textColor: styles.darkText,
            placeholderColor: styles.gray50,
            backgroundColor: 'white',
            dropdownBackgroundColor: 'white',
            backgroundHover: styles.gray25,
            iconColor: styles.gray75,
            shadow: styles.shadow.primaryShadow,
            border: styles.gray25,
            categoryColor: styles.blue
        },
        dense: {
            textColor: styles.darkText,
            placeholderColor: styles.gray50,
            backgroundColor: 'white',
            dropdownBackgroundColor: 'white',
            backgroundHover: styles.gray25,
            iconColor: styles.gray75,
            shadow: styles.shadow.primaryShadow,
            border: styles.gray25,
            categoryColor: styles.blue
        },
        dark: {
            textColor: styles.white,
            placeholderColor: styles.gray50,
            backgroundColor: styles.primaryTextColor,
            dropdownBackgroundColor: styles.primaryTextColor,
            backgroundHover: styles.gray80,
            iconColor: styles.gray25,
            shadow: styles.shadow.primaryShadow,
            border: 'transparent',
            categoryColor: styles.gray50
        }
    },

    dialog: {
        iconColor: 'white'
    },
};
