import React, {useContext, useEffect, useRef, useState} from "react";
import { Button } from 'zebra-stripes/Forms'
import {StripeForm} from './StripeForm'
import {EventContext} from "../../contexts/EventContext";
import {RegistrationContext} from "../../contexts/RegistrationContext";
import {AuthenticationContext} from "../../contexts/AuthenticationContext";

export const ProductCheckout = (props) => {

    const {
        onReturn = () => false,
        onSuccess = (response) => false,
        onCancel = () => false,
        onBackToUserInfo = () => false,
    } = props;
    const { isAuthenticated } = useContext(AuthenticationContext);
    const { purchaseOptions } = useContext(EventContext);
    const { setProduct, product } = useContext(RegistrationContext);
    const [ price, setPrice ] = useState(product?.cost || 0);
    const [ successCheckout, setSuccessCheckout ] = useState(false);

    const handleSuccess = (paymentResponse) => {
        setPrice(paymentResponse.amount);
        setSuccessCheckout(true);
        onSuccess(paymentResponse);
    }

    return (
        <div className='ProductCheckout'>
            <div className='navigation_links'>
                {!isAuthenticated && <Button type='primary' dense onClick={onBackToUserInfo}>Back to User Information</Button>}
                {purchaseOptions.length && <Button type='primary' dense onClick={() => {
                    setProduct(null);
                    onReturn();
                }}>Return to Product Selection</Button>}
                <Button type='outlined' dense onClick={onCancel}>Cancel</Button>
            </div>
            {product && <StripeForm {...props} onSuccess={handleSuccess} />}
        </div>
    )
}
