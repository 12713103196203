import React, {useContext, useEffect, useState} from "react";
import {EventContext} from "../contexts/EventContext";
import {AuthenticationContext} from "../contexts/AuthenticationContext";
import {Button} from "zebra-stripes/Forms";
import {UserAccountForm} from "./UserAccountForm";
import './Registration.scss';
import {PurchaseOptions} from "./PurchaseOptions";
import {AccountCreatePurchaseConfirmation} from "./AccountCreatePurchaseConfirmation";
import {RegistrationContext} from "../contexts/RegistrationContext";
import {ProductCheckout} from "./Payment/ProductCheckout";
import {UserCustomQuestions} from "./UserCustomQuestions";

export function Registration(props) {
    const {
        onCancel = () => false,
        onShowLogin = () => false,
        ignorePurchasing = false,
        standAlone = false,
    } = props;

    const { event, purchaseOptions } = useContext(EventContext);
    const { userObj, setUserObj, product, setProduct, setIgnorePurchasing, completeRegistration } = useContext(RegistrationContext);
    const { user } = useContext(AuthenticationContext);

    const [ step, setStep ] = useState(!user ? 1 : 3);
    const [ paymentResponse, setPaymentResponse ] = useState({});
    const [ newAccountCreation, setNewAccountCreation ] = useState(!user);

    const hasCustomQuestions = event.custom_questions.length;
    const steps = {
        userInformation: 1,
        customQuestions: 2,
        productSelection: 3,
        purchaseForm: 4,
        confirmation: 5,
    }
    const totalSteps = Object.values(steps).length - (hasCustomQuestions ? 1 : 2);

    useEffect(() => {
        setIgnorePurchasing(ignorePurchasing);
    }, []);

    const handleAccountSubmission = async (userObj) => {
        setUserObj(userObj);

        if (!hasCustomQuestions && (ignorePurchasing || purchaseOptions?.length === 0)) {
            // will create an account right here and now, and confirm, no purchasing can be made.
            const paymentResponse = await completeRegistration(userObj);
            setPaymentResponse({
                ...paymentResponse,
                product: {...product},
            });
            setProduct(null);
            setStep(steps.confirmation);
        } else {
            setStep(hasCustomQuestions ? steps.customQuestions : steps.productSelection);
        }
    }

    const handleCustomQuestionSubmission = async () => {
        if (ignorePurchasing || purchaseOptions?.length === 0) {
            // will create an account right here and now, and confirm, no purchasing can be made.
            const paymentResponse = await completeRegistration(userObj);
            setPaymentResponse({
                ...paymentResponse,
                product: {...product},
            });
            setProduct(null);
            setStep(steps.confirmation);
        } else {
            setStep(steps.productSelection);
        }
    }

    const handleAccountAndPurchaseSubmission = (paymentResponse, productObj) => {
        if (paymentResponse.status === 'succeeded') {
            setPaymentResponse({
                ...paymentResponse,
                product: {...productObj},
            });
            setProduct(null);
            setStep(steps.confirmation);
        }
    }

    let displayDOM = null;

    switch(step) {
        case steps.userInformation:
            displayDOM = <UserAccountForm
                onShowLogin={() => onShowLogin()}
                onCancel={() => onCancel()}
                onSubmit={handleAccountSubmission}
                ignorePurchasing={ignorePurchasing}
            />;
            break;
        case steps.customQuestions:
            displayDOM = <UserCustomQuestions
                onBackToUserInfo={() => setStep(steps.userInformation)}
                onCancel={() => onCancel()}
                onSubmit={handleCustomQuestionSubmission}
                ignorePurchasing={ignorePurchasing}
            />
            break;
        case steps.productSelection:
            displayDOM =
                <PurchaseOptions
                    onBackToUserInfo={() => setStep(steps.userInformation)}
                    onCancel={() => onCancel()}
                    onSetProduct={() => setStep(steps.purchaseForm)}
                />
            break;
        case steps.purchaseForm:
            displayDOM =
                <ProductCheckout
                    onBackToUserInfo={() => setStep(steps.userInformation)}
                    onReturn={() => setStep(steps.productSelection)}
                    onSuccess={(response) => handleAccountAndPurchaseSubmission(response, product)}
                    onCancel={() => onCancel()}
                />

            break;
        case steps.confirmation:
            displayDOM = <AccountCreatePurchaseConfirmation
                willCreateNewAccount={newAccountCreation}
                paymentResponse={paymentResponse}
                onClose={() => onCancel()}
            />
    }

    return (
        <div className={`RegistrationWizard ${standAlone ? 'stand_alone' : ''}`}>
            {!standAlone && <div className='registration_navigator'>
                <h3>{`Create a ${event.name} Account`}</h3>
                {(step === steps.confirmation || ignorePurchasing) ? null : <div className='step_container'>
                    Steps {hasCustomQuestions ? step : step > 1 ? step - 1 : step} of {totalSteps}
                </div>}
            </div>}
            <div>{displayDOM}</div>
        </div>
    )
}
