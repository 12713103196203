import React, { useContext, useEffect, useState, useRef } from "react";
import {EventContext} from "../contexts/EventContext";
import {AuthenticationContext} from "../contexts/AuthenticationContext";
import { SessionSearch } from './SessionSearch';
import {Icon} from 'zebra-stripes/Symbols/Icon';
import {ShowHide} from 'zebra-stripes/Layouts';
import './SessionListing.scss';
import { Link, useParams} from "react-router-dom";
import {useAPI} from "../contexts/useAPI";
import {API} from "../controllers/API";
import {SpeakerBioPopUp} from "./SpeakerBioPopUp";
var moment = require('moment-timezone');

const dateLabelStyle = {
    background: 'rgb(250,250,250)',
    fontSize: '22px',
    padding: '10px',
    margin: '5px 0',
    borderBottom: '1px solid #ccc'
};

export function SessionListing(props) {
    let paths = useParams();
    const {
        postService
    } = useAPI();
    const {
        showPresenterBio = false,
        showTrackViewer = false,
        openDataByDefault = false,
    } = props;
    const { event, sessions, getSessionListing, eventRoute, aggregateSessions } = useContext(EventContext);
    const { user, productAccess, resolveSessionAccess, resolvePendingSessionAccess, getProductAccessByEvent } = useContext(AuthenticationContext);
    const closeBox = useRef(null);
    const speakerBios = useRef([]);
    const [ currentSpeaker, setCurrentSpeaker ] = useState({
        show: false,
        image: null,
        bio: null,
        x: 0,
        y: 0
    });
    const [ openDateContainers, setOpenDateContainers ] = useState(openDataByDefault);
    const [ sessionDOM, setSessionDOM ] = useState([]);
    const sessionsRef = useRef(sessions);

    useEffect(() => {
        if (event && user && !productAccess) {
            getProductAccessByEvent(event.id, user.userid)
        }
    }, [user, event]);

    useEffect(() => {
        if (sessions.length) {
            sessionsRef.current = sessions;
            setSessionDOM(getSessionDOMItems());
        }
    }, [sessions]);

    useEffect(() => {
        const dateInterval = setInterval(() => {
            setSessionDOM(getSessionDOMItems());
        }, 5000);
        return () => {
            clearInterval(dateInterval);
        }
    }, []);

    const postSearch = (searchOptions) => {
        getSessionListing(searchOptions);
    }

    const launchSpeakerBio = (name, e, show) => {
        //console.log(e.target.getBoundingClientRect(), e.target.scrollTop);
        if(show) {
            clearTimeout(closeBox.current);
            const panelLocation = e.target.getBoundingClientRect();
            if(speakerBios.current[name]) {
                //console.log('already have the bio: ', speakerBios[name]);
                setCurrentSpeaker({
                    show: true,
                    image: speakerBios.current[name].image,
                    bio: atob(speakerBios.current[name].bio),
                    x: panelLocation.x,
                    y: panelLocation.y,
                });
            } else {
                const payload = {
                    path: `${paths.client}/${paths.eventpath}`,
                    speaker: btoa(name)
                }
                postService(
                    API.speakerAPI,
                    payload
                )
                    .then((data) => {
                        if(data) {
                            if(data.bio || data.image) {
                                speakerBios.current[name] = data;
                                setCurrentSpeaker({
                                        show: true,
                                        image: data.image,
                                        bio: atob(data.bio),
                                        x: panelLocation.x,
                                        y: panelLocation.y,
                                });
                            }
                        }
                    });
            }

        } else {
            clearTimeout(closeBox.current);
            closeBox.current = setTimeout(() => {
                setCurrentSpeaker({
                    show: false,
                    image: null,
                    bio: null
                })
            }, 500);
        }
    }

    const getSessionDOMItems = () => {
        let sessionObject = aggregateSessions(sessionsRef.current);
        const sessionDisplay = [];
        const allSessions = [];

        Object.keys(sessionObject).map((date,i) => {
            let sessionsCollection = [];
            let tracktitle = "";
            sessionObject[date].map((s,i) => {
                const url = `/${eventRoute[0]}/${eventRoute[1]}`;
                const hasAccess = resolvePendingSessionAccess(s).pending || resolveSessionAccess(s);
                if (tracktitle !== s.tracktitle) {
                    sessionsCollection.push(
                        <h4 key={'h4' + i} className={!!s.sessionid.match(/[a-z]/i) ? "minor" : ""}>
                            {s.tracktitle}
                            {showTrackViewer && <Link to={`${url}/track/${s.trackid}`}><button className='trackViewerButton'>Launch Track Viewer</button></Link>}
                        </h4>);
                    tracktitle = s.tracktitle;
                }

                let sessionDOM = <SessionItem
                    key={`session${s.id}`}
                    eventRoute={`/${eventRoute[0]}/${eventRoute[1]}`}
                    session={s}
                    speakerCB={showPresenterBio ? launchSpeakerBio : null}
                    hasAccess={hasAccess}
                />;
                sessionsCollection.push(sessionDOM);
                allSessions.push(sessionDOM);
            });
            sessionDisplay.push(
                <ShowHide
                    key={'showhide' + i}
                    initialShow={openDateContainers}
                    labelStyle={dateLabelStyle}
                    maxOverflow="20000px"
                    label={moment(date).format("dddd, MMMM D, YYYY")}>
                    {sessionsCollection}
                </ShowHide>);
        });
        return sessionDisplay;
    }

    return (
        <div className='SessionListing'>
            { event && <SessionSearch onSubmit={postSearch} /> }
            <div>
                {sessionDOM}
            </div>
            <SpeakerBioPopUp
                speaker={currentSpeaker}
                onMouseOver={() => clearTimeout(closeBox.current)}
                onMouseOut={() => launchSpeakerBio(null,null,false)}
            />
        </div>
    )
}

const SessionItem = ({
    session,
    speakerCB = () => false,
    hasAccess,
    eventRoute
}) => {
    const { event, getSessionDateAccess } = useContext(EventContext);
    const openURL = (url) => {
        /*if(trackURL) {
            document.location = trackURL;
            return false;
        } else {*/
        window.open(url , 'player','toolbar=1,resizable=1,scrollbars=1,width=1100,height=900');
        return false;
        //}
    }
    const buildSpeakerInteractionDOM = (speaker) => {
        // console.log(speaker)
        return (<span
            key={`${session.id}${speaker.trim()}`}
            onMouseEnter={(e) => speakerCB && speakerCB(speaker,e, true)}
            onMouseLeave={(e) => speakerCB && speakerCB(speaker,e, false)}
            className={`speaker_name ${speakerCB ? 'active' : ''}`}
        >{speaker}</span>);
    }
    const speakerFields = [session.speaker1, session.speaker2, session.speaker3, session.panelists];
    const speakerDOM = [];
    speakerFields.forEach((speakerField) => {
        if (speakerField) {
            speakerDOM.push(buildSpeakerInteractionDOM(speakerField));
        }
    });

    const mediaIcon = [];
    if(session.media && session.media.length) {
        session.media.map(m => {
            switch (m) {
                case "flv" :
                    mediaIcon.push(<img key='img1' src="/images/flv_video_large.png" alt='COD Video Icon='/>);
                    break;
                case "flvAS" :
                    mediaIcon.push(<img key='img2' src="/images/flv_slides_audio_large.png" alt='COD Video Icon='/>);
                    break;
                case "flvVS" :
                    mediaIcon.push(<img key='img3' src="/images/flv_slides_video_large.png" alt='COD Video Icon='/>);
                    break;
                case "flvV" :
                    mediaIcon.push(<img key='img4' src="/images/flv_video_large.png" alt='COD Video Icon='/>);
                    break;
                case "flvEN" :
                    mediaIcon.push(<img key='img5' src="/images/flv_enhanced_video_large.png" alt='COD Video Icon='/>);
                    break;
                case "flvWD" :
                    mediaIcon.push(<img key='img6' src="/images/flv_enhanced_video_large.png" alt='COD Video Icon='/>);
                    break;
                case "pptx" :
                case "ppt" :
                    //mediaIcon.push(<img className="media" src="/images/ppt.png" alt='COD PPT Icon='/>);
                    break;
                case "pdf" :
                    //mediaIcon.push(<img className="media" src="/images/pdf.svg" alt='COD PDF Icon='/>);
                    break;
                case "mp3" :
                    //mediaIcon.push(<img src="/images/mp3_large.png" alt='COD MP3 Icon='/>);
                    break;
                case "doc" :
                    //mediaIcon.push(<img src="/images/doc.png" alt='COD DOC Icon='/>);
                    break;
            }
        });
    }

    let sdate = moment(session.sessiondate);
    let stime = session.starttime ? session.starttime.split(":"): [0,0,0];
    sdate.hour(stime[0]);
    sdate.minutes(stime[1]);
    let testSDate = moment(sdate);

    let edate = moment(session.sessiondate);
    let etime = session.endtime ? session.endtime.split(":"): [0,0,0];
    edate.hour(etime[0]);
    edate.minutes(etime[1]);
    const dateAccess = getSessionDateAccess(session);
    return (
        <div className='SessionItem' key={`session_${session.sessionid}`}>
            <div className='session_item_icons'>
                {dateAccess.icon ? <img className='streaming' src={`/images/${dateAccess.icon}.png`} /> : mediaIcon}
            </div>
            <div className='session_item_wrap'>
                <div className={`title_wrap ${mediaIcon.length ? 'with_media' : ''}`}>
                    <div className={"session_title"} onClick={() => openURL(`${eventRoute}/${session.id}`)}>
                        <Icon iconid={hasAccess ? "unlock_alt" : "lock"}
                              color={hasAccess ? "green" : "#ccc"} size="large"
                              basestyle={{float: 'right', top: '-5px'}}/>
                        <span className='session_id'>{session.sessionid} - </span>{atob(session.sessiontitle)}
                    </div>
                </div>
                <p className="session_date">{sdate.format("dddd, MMMM D, YYYY h:mm a")} {event?.timezone} {session.location && (' | ' + session.location)}</p>
                {speakerDOM.length ? <p key="f4" className="session_speakers">Speaker(s):
                    {speakerDOM.map((speaker, i) => {
                        return [(i > 0 ? ', ' : ''),speaker];
                    })}</p>: null}
                <p className='session_summary' dangerouslySetInnerHTML={{__html: atob(session.summary)}} />
            </div>
        </div>
    )
}
