import React, { useContext, useEffect, useState, useRef } from "react";
import {useParams, BrowserRouter, Routes, Route, Outlet, Link, useRoutes} from "react-router-dom";
import { EventContext } from '../contexts/EventContext';
import {TextBox, TextArea, Button, RadioButtonGroup, CheckBoxGroup, Fieldset, DropDown, Item} from "zebra-stripes/Forms";
import {Dialog} from 'zebra-stripes/Layouts'
import {Icon} from "zebra-stripes/Symbols/Icon";
import {API} from "../controllers/API";
import {AuthenticationContext} from "../contexts/AuthenticationContext";
import * as _ from 'lodash';
import './UserAccountForm.scss';
import {useAPI} from "../contexts/useAPI";
import {RegistrationContext} from "../contexts/RegistrationContext";
import './UserCustomQuestions.scss';
import {CustomQuestionType} from "../contexts/Registration";

export function UserCustomQuestions(props) {
    const {
        onCancel = () => false,
        onSubmit = null,
        onBackToUserInfo = () => false,
        ignorePurchasing = false,
    } = props;

    const {
        postService
    } = useAPI();

    const { userObj, customQuestionAnswers, setCustomQuestionAnswers } = useContext(RegistrationContext);
    const userState = userObj;
    const { event, eventRoute } = useContext(EventContext);
    const [ errorList, setErrorList ] = useState({});
    const { user, setAuthentication, validateEmail } = useContext(AuthenticationContext);

    useEffect(() => {
    }, [user, event]);

    const validateQuestions = () => {
        let isValid = true;
        const errorListObj = {};
        event.custom_questions.forEach((quest) => {
            if (quest.required && !quest.hide && !customQuestionAnswers[quest.id]) {
                errorListObj[quest.id] = 'Required Field';
                if (isValid) {
                    isValid = false;
                }
            } else {
                errorListObj[quest.id] = null;
            }
        });
        setErrorList(errorListObj);
        return isValid;
    }

    const submitForm = () => {
        if (validateQuestions()) {
            if (onSubmit) {
                onSubmit(customQuestionAnswers);
            } else {
                // TODO: what do we do here??????????
            }
        }
    }

    const setQuestionAnswer = (answer, id) => {
        //console.log('setQuestionAnswer', answer, id);
        const answerObj = { ...customQuestionAnswers };
        answerObj[id] = Array.isArray(answer) ? answer.join('#') : answer;
        setCustomQuestionAnswers(answerObj);
    }

    const buttonText = (ignorePurchasing && event.custom_questions.length) ? 'Complete Registration' : 'Continue to Next Step';
    return (
        <div className='UserCustomQuestions'>
            <div className='navigation_links'>
                <Button type='primary' dense onClick={onBackToUserInfo}>Back to User Information</Button>
                <Button type='outlined' dense onClick={onCancel}>Cancel</Button>
            </div>
            <p><span className='required'>*</span> Denotes required question</p>
            {event.custom_questions.map((question, i) => {
                const answer = customQuestionAnswers[question.id] || '';
                return !question.hide && <QuestionItem errorText={errorList[question.id]} key={`qitem${i}`} onUpdate={setQuestionAnswer} question={question} answer={answer} />
            })
            }

            <Button style={{width: "40%", marginLeft: 0, marginRight: '20px'}} type="primary" onClick={submitForm}>{buttonText}</Button>
        </div>
    )
}

export const QuestionItem = ({question, answer, onUpdate, errorText}) => {
    let answerOptions = null;

    const resolveOptions = (options) => {
        const optionList =  _.filter(options.split('#'), (opt) => opt.trim() !== '');
        const answerArray = answer?.split('#') || [];
        return optionList.map((option, i) =>
            <Item key={`qoption${question.id}${i}`} value={option} defaultChecked={answerArray?.includes(option.trim())}>{option}</Item>
        );
    }

    switch (question.type) {
        case CustomQuestionType.OpenEnded: // textbox
            answerOptions = <TextArea error={errorText} width={'100%'} onChange={(e, val) => onUpdate(val.trim(), question.id)} value={answer} />
            break;
        case CustomQuestionType.Single: // radio
            answerOptions = <><RadioButtonGroup error={errorText} value={answer} className={`radio_selector ${errorText ? 'has_error' : ''}`} onChange={(e, val) => onUpdate(val.trim(), question.id)}>
                    {resolveOptions(question.options)}
                </RadioButtonGroup>
                {errorText && <p className='input_error'>{errorText}</p>}
            </>;
            break;
        case CustomQuestionType.Multiple: // checkbox
            answerOptions = <><CheckBoxGroup error={errorText} className={`checkbox_selector ${errorText ? 'has_error' : ''}`} onChange={(e, val) => onUpdate(val, question.id)}>
                    {resolveOptions(question.options)}
                </CheckBoxGroup>
                {errorText && <p className='input_error'>{errorText}</p>}
            </>
            break;
    }
    return (
        <div className='question_item'>
            <h3>{question.question} {question.required ? <span className='required'>*</span> : ''}</h3>
            <div>
                {answerOptions}
            </div>
        </div>
    );
}