import React from "react";
import './TermsAndConditionsContent.scss';

export const TermsAndConditionsContent = () => {
    return (
        <div className='termsandconditions'>
            <h1>User Terms and Conditions</h1>
            <p>Last Updated: January 5, 2021</p>

            <p>PLEASE READ THIS DOCUMENT CAREFULLY. Animatic Media, LLC ("Animatic," "we," or "us") offers a virtual event platform for live, on-demand and hybrid events through its website branded as Conference-On-Demand located at the conferenceondemand.net website (the "Conference-On-Demand +LIVE Site") and related domains mobile applications, and desktop applications, (collectively, the "Conference-On-Demand Service"). By registering as a member or by using the Conference-On-Demand +LIVE Service in any way, you accept these Terms of Service ("Agreement"), which forms a binding agreement between you and Animatic Media, LLC.. If you do not wish to be bound by this Agreement, do not use the Conference-On-Demand +LIVE Service.</p>

            <h3>Content</h3>
            <ol>
                <li>Who May Use the Conference-On-Demand.net Service</li>
                <li>License to Use the Conference-On-Demand.net Service</li>
                <li>Privacy</li>
                <li>Membership</li>
                <li>Subscriptions and Purchases</li>
                <li>Term and Termination; Account Deletion</li>
                <li>Content Restrictions</li>
                <li>Code of Conduct</li>
                <li>Licenses Granted by You</li>
                <li>Your Representations and Warranties</li>
                <li>Indemnification</li>
                <li>Third Party Copyrights and Other Rights</li>
                <li>Disclaimers</li>
                <li>Limitation of Liability</li>
                <li>Compliance Notice Pursuant to 18 U.S.C. § 2257</li>
                <li>General Provisions</li>
            </ol>

            <h3>1. Who May Use the Conference-On-Demand.net Service</h3>
            <p><em>AGE REQUIREMENT</em>: You must be at least 13 years old to use the Conference-On-Demand.net Service. If you are at least 13, but are still a minor (this depends on where you live), you must have your parent or legal guardian's permission to use the Conference-On-Demand.net Service. Please have him or her read this Agreement with you.</p>
            <p><em>NOTICE TO PARENTS AND GUARDIANS</em>: By granting your child permission to use the Conference-On-Demand.net Service, you agree to the terms of this Agreement on behalf of your child. You are responsible for monitoring and supervising your child's use of the Conference-On-Demand.net Service. If your child is using the Conference-On-Demand.net Service and is either under 13 or does not have your permission, please contact us immediately so that we can disable his or her access. If you have questions about whether the Conference-On-Demand.net Service is appropriate for your child, please review our Frequently Asked Questions or contact us.</p>
            <p><em>WARNING</em>: Even if you are old enough to use the Conference-On-Demand.net Service and/or have your parent's or guardian's permission, some of the content available within the Conference-On-Demand.net Service may not be appropriate for you. Some content may contain "R- rated" material, nudity, profanity, and mature subject matter. If you are under 18, do not view such content.</p>

            <h3>License to Use the Conference-On-Demand.net Service</h3>
            <p><em>LICENSE</em>: Conference-On-Demand.net grants you a limited, non-exclusive license to access and use the Conference-On-Demand.net Service for your own personal, non-commercial purposes. This includes the right to view content available on the Conference-On-Demand.net Service. This license is personal to you and may not be assigned or sublicensed to anyone else.</p>
            <p><em>COMMERCIAL USE</em>: You may not use the Conference-On-Demand.net Service for commercial purposes unless:
                <ul>
                    <li>You are a Conference-On-Demand.net PRO user, in which case you may use and access the Conference-On-Demand.net Service for commercial and non-commercial purposes, subject to compliance with the Conference-On-Demand.net Guidelines; or</li>
                    <li>You are a small-scale independent production company, non-profit, or artist, in which case you may use the Conference-On-Demand.net Service to showcase or promote your own creative works.</li>
                </ul>
            </p>
            <p><em>RESTRICTIONS</em>: Except as expressly permitted by Conference-On-Demand.net in writing, you will not reproduce, redistribute, sell, create derivative works from, decompile, reverse engineer, or disassemble the Conference-On-Demand.net Service. Nor will you take any measures to interfere with or damage the Conference-On-Demand.net Service. All rights not expressly granted by Conference-On-Demand.net are reserved.</p>
            <p><em>MOBILE APP</em>: Your use of the Conference-On-Demand.net Service through one of our mobile applications is also subject to our Mobile App Addendum.</p>

            <h3>3. Privacy</h3>
            <p>Your privacy rights are set forth in our Privacy Policy, which forms a part of this Agreement.</p>
            <p>Please review the Privacy Policy to learn about:
                <ul>
                    <li>What information we may collect about you;</li>
                    <li>What we use that information for; and</li>
                    <li>With whom we share that information.</li>
                </ul>
            </p>

            <h3>4. Membership</h3>
            <p><em>REGISTRATION</em>: To fully use the Conference-On-Demand.net Service, you must register as a member by providing a user name, password, and valid email address. You must provide complete and accurate registration information to Conference-On-Demand.net and notify us if your information changes. If you are a business, government, or non-profit entity, the person whose email address is associated with the account must have the authority to bind the entity to this Agreement. USER NAME: We encourage you to use your real name. If you are a business, government, or non-profit entity, you must use the actual name of your organization. You may not use someone else's name, a name that violates any third party right, or a name that is obscene or otherwise objectionable.</p>
            <p>Users understand and agrees that their name/identity may be revealed as an attendee during a live event for the purpose of allowing other attendees to network and chat with them. User also understands that they will have control to make themselves “invisible” on the site should they choose to change the default setting of “visible”</p>
            <p><em>ACCOUNT SECURITY</em>: You are responsible for all activity that occurs under your account, including any activity by unauthorized users. You must not allow others to use your account. You must safeguard the confidentiality of your password. If you are using a computer that others have access to, you must log out of your account after using the Conference-On-Demand.net Service. If you become aware of an unauthorized access to your account, you must change your password and notify us immediately.</p>

            <h3>5. Term and Termination; Account Deletion</h3>
            <p><em>TERM</em>: This Agreement begins on the date you first use the Conference-On-Demand.net Service and continues as long as you have an account with us.</p>
            <p><em>ACCOUNT DELETION</em>: You may delete your account at any time. Basic accounts may be deleted from the Conference-On-Demand.net Service if they remain inactive (i.e., the user fails to log in) for a continuous period of at least six (6) months. Subscription accounts will remain active until the end of the subscription term and any renewal term.</p>
            <p><em>TERMINATION FOR BREACH</em>: Conference-On-Demand.net may suspend, disable, or delete your account (or any part thereof) or block or remove any content you submitted if Conference-On-Demand.net determines that you have violated any provision of this Agreement or that your conduct or content would tend to damage Conference-On-Demand.net's reputation and goodwill. If Conference-On-Demand.net deletes your account for the foregoing reasons, you may not re-register for the Conference-On-Demand.net Service. Conference-On-Demand.net may block your email address and Internet protocol address to prevent further registration.</p>
            <p><em>EFFECT OF TERMINATION/ACCOUNT DELETION</em>: Upon termination, all licenses granted by Conference-On-Demand.net will terminate. Sections 6 and 11 though 16 shall survive termination. In the event of account deletion for any reason, content that you submitted may no longer be available. Conference-On-Demand.net shall not be responsible for the loss of such content.</p>

            <h3>6. Content Restrictions</h3>
            <p>You may not upload, post, or transmit (collectively, "submit") any video, image, text, audio recording, or other work (collectively, "content") that:
                <ul>
                    <li>Infringes any third party's copyrights or other rights (e.g., trademark, privacy rights, etc.);</li>
                    <li>Contains sexually explicit content or pornography (provided, however, that non-sexual nudity is permitted);</li>
                    <li>Contains hateful, defamatory, or discriminatory content or incites hatred against any individual or group;</li>
                    <li>Exploits minors;</li>
                    <li>Depicts unlawful acts or extreme violence;</li>
                    <li>Depicts animal cruelty or extreme violence towards animals;</li>
                    <li>Promotes fraudulent schemes, multi level marketing (MLM) schemes, get rich quick schemes, online gaming and gambling, cash gifting, work from home businesses, or any other dubious money-making ventures; or</li>
                    <li>Violates any law.</li>
                </ul>
            </p>
            <p>All videos you submit must also comply with the Conference-On-Demand.net Guidelines, which are incorporated into this Agreement.</p>

            <h3>7. Code of Conduct</h3>
            <p>In using the Conference-On-Demand.net Service, you must behave in a civil and respectful manner at all times. Further, you will not:
                <ul>
                    <li>Act in a deceptive manner by, among other things, impersonating any person;</li>
                    <li>Harass or stalk any other person;</li>
                    <li>Harm or exploit minors;</li>
                    <li>Distribute "spam";</li>
                    <li>Collect information about others; or</li>
                    <li>Advertise or solicit others to purchase any product or service within the Conference-On-Demand.net Site (unless you are an official Conference-On-Demand.net partner or advertiser and have a written agreement with Conference-On-Demand.net).</li>
                </ul>
            </p>
            <p>Conference-On-Demand.net has the right, but not the obligation, to monitor all conduct on and content submitted to the Conference-On-Demand.net Service.</p>

            <h3>8. Licenses Granted by You</h3>

            <h3>8.1 Videos</h3>
            <p><em>LICENSE TO CONFERENCE-ON-DEMAND.NET</em>: As between you and Conference-On-Demand.net, you own the video content ("videos") that you submit to the Conference-On-Demand.net Service. By submitting a video, you grant Conference-On-Demand.net and its affiliates a limited, worldwide, non- exclusive, royalty-free license and right to copy, transmit, distribute, publicly perform and display (through all media now known or hereafter created), and make derivative works from your video for the purpose of (i) displaying the video within the Conference-On-Demand.net Service; (ii) displaying the video on third party websites and applications through a video embed or Conference-On-Demand.net's API subject to your video privacy choices; (iii) allowing other users to play, download, and embed on third party websites the video, subject to your video privacy choices; (iii) promoting the Conference-On-Demand.net Service, provided that you have made the video publicly available; and (iv) archiving or preserving the video for disputes, legal proceedings, or investigations.</p>
            <p><em>LICENSE TO OTHER USERS</em>: You further grant all users of the Conference-On-Demand.net Service permission to view your videos for their personal, non-commercial purposes. This includes the right to copy and make derivative works from the videos solely to the extent necessary to view the videos. The foregoing licenses are in addition to any license you may decide to grant (e.g., a Creative Commons license).</p>
            <p><em>DURATION OF LICENSES</em>: The above licenses will continue unless and until you remove your videos from the Conference-On-Demand.net Service, in which case the licenses will terminate within a commercially reasonable period of time. Notwithstanding the foregoing, the license for legal archival/preservation purposes will continue indefinitely. Please note that removed videos may be cached in search engine indices after removal and that Conference-On-Demand.net has no control over such caching.</p>

            <h3>8.2 Non-video Content</h3>
            <p>As between you and Conference-On-Demand.net, you own all non-video content that you submit to the Conference-On-Demand.net Service. You grant Conference-On-Demand.net and its affiliates a worldwide, perpetual, irrevocable, non-exclusive, royalty-free license and right to copy, transmit, distribute, publicly perform and display (through all media now known or hereafter created), and make derivative works from your non-video content. In addition, you waive any so-called "moral rights" in your non-video content. You further grant all users of the Conference-On-Demand.net Service permission to view your non-video content for their personal, non-commercial purposes. If you make suggestions to Conference-On-Demand.net on improving or adding new features to the Conference-On-Demand.net Service, Conference-On-Demand.net shall have the right to use your suggestions without any compensation to you.</p>

            <h3>9. Your Representations and Warranties</h3>
            <p>For each piece of content that you submit, you represent and warrant that: (i) you have the right to submit the content to Conference-On-Demand.net and grant the licenses set forth above; (ii) Conference-On-Demand.net will not need to obtain licenses from any third party or pay royalties to any third party; (iii) the content does not infringe any third party's rights, including intellectual property rights and privacy rights; and (iv) the content complies with this Agreement and all applicable laws.</p>

            <h3>10. Indemnification</h3>
            <p>You will indemnify, defend, and hold harmless Conference-On-Demand.net and its affiliates, directors, officers, employees, and agents, from and against all third party actions that: (i) arise from your activities on the Conference-On-Demand.net Service; (ii) assert a violation by you of any term of this Agreement; or (iii) assert that any content you submitted to Conference-On-Demand.net violates any law or infringes any third party right, including any intellectual property or privacy right.</p>

            <h3>11. Third Party Copyrights and Other Rights</h3>
            <p>Conference-On-Demand.net respects the intellectual property rights of others. If you believe that your copyright has been infringed, please send us a notice as set forth in our Copyright and DMCA Policy, which is incorporated into this Agreement. For other intellectual property claims, please send us a notice at legal[at]Conference-On-Demand.net[dot]com.</p>

            <h3>12. Disclaimers</h3>
            <p>Conference-On-Demand.net reserves the right to modify the Conference-On-Demand.net Service. You are responsible for providing your own access (e.g., computer, mobile device, Internet connection, etc.) to the Conference-On-Demand.net Service. Conference-On-Demand.net has no obligation to screen or monitor any content and does not guarantee that any content available on the Conference-On-Demand.net Service complies with this Agreement or is suitable for all users. Conference-On-Demand.net provides the Conference-On-Demand.net Service on an "as is" and "as available" basis. You therefore use the Conference-On-Demand.net Service at your own risk. Conference-On-Demand.net expressly disclaims any and all warranties of any kind, whether express or implied, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose, non- infringement, and any other warranty that might arise under any law. Without limiting the foregoing, Conference-On-Demand.net makes no representations or warranties:
                <ul>
                    <li>That the Conference-On-Demand.net Service will be permitted in your jurisdiction;</li>
                    <li>That the Conference-On-Demand.net Service will be uninterrupted or error-free;</li>
                    <li>Concerning any content submitted by any member;</li>
                    <li>Concerning any third party's use of content that you submit;</li>
                    <li>That any content you submit will be made available on the Conference-On-Demand.net Service or will be stored by Conference-On-Demand.net;</li>
                    <li>That the Conference-On-Demand.net Service will meet your business or professional needs;</li>
                    <li>That Conference-On-Demand.net will continue to support any particular feature of the Conference-On-Demand.net Service; or</li>
                    <li>Concerning sites and resources outside of the Conference-On-Demand.net Service, even if linked to from the Conference-On-Demand.net Service.</li>
                </ul>
            </p>
            <p>To the extent any disclaimer or limitation of liability does not apply, all applicable express, implied, and statutory warranties will be limited in duration to a period of thirty (30) days after the date on which you first used the Conference-On-Demand.net Service, and no warranties shall apply after such period.</p>

            <h3>13. Limitation of Liability</h3>
            <p>To the fullest extent permitted by law: (i) Conference-On-Demand.net shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data or other intangible losses; and (ii) Conference-On-Demand.net's total liability to you shall not exceed the amounts paid by you to Conference-On-Demand.net over the twelve (12) months preceding your claim(s).</p>

            <h3>14. Compliance Notice Pursuant to 18 U.S.C. § 2257</h3>
            <p>All pictures, graphics, videos, and other visual media displayed on the Conference-On-Demand.net Service are exempt from 18 U.S.C. § 2257 and 28 C.F.R. 75 because they do not consist of depictions of conduct as specifically listed in 18 U.S.C. § 2256 (2) (A) - (D), but are merely, at most, depictions of non-sexually explicit nudity, or are depictions of simulated sexual conduct, or are otherwise exempt because the visual depictions were created prior to July 3, 1995. Conference-On-Demand.net is not the primary producer of the visual content contained in the Conference-On-Demand.net Service.</p>

            <h3>15. General Provisions</h3>
            <p><em>GOVERNING LAW</em>: This Agreement shall be governed by the laws of the State of New York, United States of America, without regard to principles of conflicts of law. The Uniform Commercial Code, the Uniform Computer Information Transaction Act, and the United Nations Convention of Controls for International Sale of Goods shall not apply. DISPUTES: Any action arising out of or relating to this Agreement or your use of the Conference-On-Demand.net Service must be commenced in the state or federal courts located in New York County, New York, United States of America (and you consent to the jurisdiction of those courts). In any such action, Conference-On-Demand.net and you irrevocably waive any right to a trial by jury.</p>
            <p><em>INTERPRETATION; SEVERABILITY; WAIVER; REMEDIES</em>: Headings are for convenience only and shall not be used to construe the terms of this Agreement. If any term of this Agreement is found invalid or unenforceable by any court of competent jurisdiction, that term will be severed from this Agreement. No failure or delay by Conference-On-Demand.net in exercising any right hereunder will waive any further exercise of that right. Conference-On-Demand.net's rights and remedies hereunder are cumulative and not exclusive.</p>
            <p><em>SUCCESSORS; ASSIGNMENT; NO THIRD PARTY BENEFICIARIES</em>: This Agreement is binding upon and shall inure to the benefit of both parties and their respective successors, heirs, executors, administrators, personal representatives, and permitted assigns. You may not assign this Agreement without Conference-On-Demand.net's prior written consent. No third party shall have any rights hereunder.</p>
            <p><em>NOTICES</em>: You consent to receive all communications including notices, agreements, disclosures, or other information from Conference-On-Demand.net electronically. Conference-On-Demand.net may provide all such communications by email or by posting them on the Conference-On-Demand.net Service. For support-related inquiries, you may contact us. You may send notices of a legal nature to Conference-On-Demand.net at legal[at]Conference-On-Demand.net[dot]com or the following address:</p>

            <p><span>Animatic Media, LLC</span><br/>
                2280 Grand Avenue<br/>
                Suite 305<br/>
                Baldwin, NY 11510<br/>
                ©2021 Animatic Media, LLC. All rights reserved.
            </p>

            <p>Nothing herein shall limit Conference-On-Demand.net's right to object to subpoenas, claims, or other demands.</p>
            <p><em>MODIFICATION</em>: This Agreement may not be modified except by a revised Terms of Service posted by Conference-On-Demand.net on the Conference-On-Demand.net Site or a written amendment signed by an authorized representative of Conference-On-Demand.net. A revised Terms of Service will be effective as of the date it is posted on the Conference-On-Demand.net Site.</p>

            <p><em>ENTIRE AGREEMENT</em>: This Agreement incorporates the following documents by reference:
                <ul>
                    <li>Privacy Policy</li>
                    <li>Cookie Policy</li>
                    <li>Conference-On-Demand.net Guidelines</li>
                    <li>Mobile App Addendum</li>
                    <li>Payment Addendum</li>
                    <li>Government Entity Addendum</li>
                    <li>Copyright and DMCA Policy</li>
                </ul>
            </p>
            <p>This Agreement constitutes the entire understanding between Conference-On-Demand.net and you concerning the subject matter hereof and supersedes all prior agreements and understandings regarding the same. Notwithstanding the foregoing, this Agreement does not govern any use of Conference-On-Demand.net's application protocol interface (API), which is governed by our API Agreement.</p>
            <p><em>END OF DOCUMENT.</em> Have a nice day.</p>
        </div>
    )
}

export function PrivacyContent() {
    return (
        <div className="privacy">
            <h1>Conferenceondemand.net Privacy Policy:</h1>
            <p>Last Updated: January 5, 2021</p>

            <p>Conference-On-Demand +LIVE respects the privacy of our users and has developed this Privacy Policy to demonstrate its commitment to protecting your privacy. These privacy policies (the "Privacy Policy") are intended to describe for you, as an individual who is a user of <a href='https://www.Conferenceondemand.net' target='_blank'>Conferenceondemand.net</a>, or any of our related sites, mobile and connected TV applications, or other online services, the information we collect, how that information may be used, with whom it may be shared, and your choices about such uses and disclosures.</p>
            <p>We encourage you to read this Privacy Policy carefully when using our website or services or transacting business with us. By using our website, you are accepting the practices described in this Privacy Policy. Conference-On-Demand +LIVE is a part of the ANIMATIC MEDIA, LLC. family of businesses (<a href='https://www.Animatic.com' target='_blank'>www.Animatic.com</a>).</p>
            <p>If you have any questions about our privacy practices, please refer to the end of this Privacy Policy for information on how to contact us.</p>
            <h3>Information We Collect About You</h3>
            <p>In General. We may collect personal information that can identify you, such as your name and email address, and other information that does not identify you. When you provide personal information through our website, the information may be sent to servers located in the United States and other countries around the world.</p>
            <ul>
                <li><em>Information you provide.</em> We may collect and store any personal information you enter on our website or provide to us in some other manner, including personal information that may be contained in any video, comment or other submission you upload or post to the website. This includes identifying information, such as your name, address, e-mail address, and telephone number; your likeness; and, if you transact business with us, financial information such as your payment method (valid credit card number, type, expiration date or other financial information). We also may request information about your interests and activities, your gender and age, and other demographic information.</li>

                <li><em>Information from other sources.</em> We may also periodically obtain both personal and non-personal information about you from other ANIMATIC MEDIA, LLC. businesses, business partners, contractors and other third parties. Examples of information that we may receive include: updated delivery and address information, purchase history, and additional demographic information.</li>

                <li><em>Information about others.</em> We may also collect and store personal information about other people that you provide to us. If you use our website to send others (friends, relatives, colleagues, etc.) a product as a gift, information that may interest them or messages (such as invitations) through our system, we may store your personal information, and the personal information of each such recipient. Similarly, if you use our website to upload, share and/or distribute content (including videos, comments or other submissions), and such content contains personal information about others, such information may be stored in order to allow for such uploading, sharing and/or distribution.</li>
            </ul>

            <p><span>Use of cookies and other technologies to collect information.</span> We use various technologies to collect information from your computer and about your activities on our site. For more information on Cookies, please see our <a href='https://vimeo.com/cookie_policy' target='_blank'>Cookie Policy</a>.</p>
            <ul>
                <li><em>Information collected automatically.</em> We automatically collect information from your browser when you visit our website. This information includes your IP address, your browser type and language, access times, the content of any undeleted cookies that your browser previously accepted from us (see "Cookies" below), and the referring website address.</li>

                <li><em>Cookies.</em> When you visit our website, we may assign your computer one or more cookies, to facilitate access to our site and to personalize your online experience. Through the use of a cookie, we also may automatically collect information about your online activity on our site, such as the web pages you visit, the links you click, and the searches you conduct on our site. Most browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies. If you choose to decline cookies, please note that you may not be able to sign in or use some of the interactive features offered on our website.</li>

                <li><em>Other Technologies.</em> We may use standard Internet technology, such as web beacons and other similar technologies, to track your use of our site. We also may include web beacons in promotional e-mail messages or newsletters to determine whether messages have been opened and acted upon. The information we obtain in this manner enables us to customize the services we offer our website visitors to deliver targeted advertisements and to measure the overall effectiveness of our online advertising, content, programming or other activities.</li>

                <li><em>Information collected by third-parties.</em> We may allow third-parties, including our authorized service providers, ANIMATIC MEDIA, LLC. companies, advertising companies, and ad networks, to display advertisements on our site. These companies may use tracking technologies, such as cookies, to collect information about users who view or interact with their advertisements. Unless expressly stated otherwise, our website does not provide any personal information to these third parties. This information allows them to deliver targeted advertisements and gauge their effectiveness. Some of these third-party advertising companies may be advertising networks that are members of the Network Advertising Initiative, which offers a single location to opt out of ad targeting from member companies (<a href='http://www.networkadvertising.org' target='_blank'>www.networkadvertising.org</a>).</li>
            </ul>

            <h3>How We Use the Information We Collect</h3>
            <p>In General. We may use information that we collect about you to:</p>
            <ul>
                <li>deliver the products and services that you have requested;</li>

                <li>manage your account and provide you with customer support;</li>

                <li>perform research and analysis about your use of, or interest in, our products, services, or content, or products, services or content offered by others;</li>

                <li>communicate with you by e-mail, postal mail, telephone and/or mobile devices about products or services that may be of interest to you either from us, our sister ANIMATIC MEDIA, LLC. companies or other third parties;</li>

                <li>develop and display content and advertising tailored to your interests on our site and other sites;</li>

                <li>verify your eligibility and deliver prizes in connection with contests and sweepstakes;</li>

                <li>enforce our terms and conditions;</li>

                <li>manage our business and</li>

                <li>perform functions as otherwise described to you at the time of collection.</li>
            </ul>

            <p><span>Financial information.</span> We may use financial information or payment method to process payment for any purchases, subscriptions or sales made on our website, to protect against or identify possible fraudulent transactions, and otherwise as needed to manage our business.</p>
            <p>Please review "<a href='https://vimeo.com/privacy#your_choices' target='_blank'>Your Choices About Collection and Use of Your Information</a>" below.</p>

            <h3>With Whom We Share Your Information</h3>

            <p>We want you to understand when and with whom we may share personal or other information we have collected about you or your activities on our website or while using our services.</p>
            <p><span>Personal information.</span> We do not share your personal information with others except as indicated below or when we inform you and give you an opportunity to opt out of having your personal information shared. We may share personal information with:</p>
            <ul>
                <li><em>Authorized service providers:</em> We may share your personal information with our authorized service providers that perform certain services on our behalf. These services may include fulfilling orders, processing credit card payments, delivering packages, providing customer service and marketing assistance, performing business and sales analysis, supporting our website functionality, and supporting contests, sweepstakes, surveys and other features offered through our website. These service providers may have access to personal information needed to perform their functions but are not permitted to share or use such information for any other purposes.</li>

                <li><em>Business partners:</em> To the extent any personal information is contained in any video, comment or other user submission, such information When you make purchases, reservations or engage in promotions offered through our website or our services, we may share personal information with the businesses with which we partner to offer you those products, services, promotions, contests and/or sweepstakes. When you elect to engage in a particular merchant’s offer or program, you authorize us to provide your email address and other information to that merchant.</li>

                <li><em>Direct mail partners.</em> From time to time we may share our postal mailing list with selected providers of goods and services that may be of interest to you. If you prefer not to receive mailings from these providers, you can notify us at any time by clicking here.</li>

                <li><em>Other Attendees.</em> Users understand and agrees that their name/identity may be revealed as an attendee during a live event for the purpose of allowing other attendees to network and chat with them. User also understands that they will have control to make themselves “invisible” on the site should they choose to change the default setting of “visible”</li>

                <li><em>Other ANIMATIC MEDIA, LLC. businesses.</em> We are part of the ANIMATIC MEDIA, LLC. family of businesses (go to www.Animatic Media, LLC..com for a listing of these businesses). We may share information we collect, including personal information, with the ANIMATIC MEDIA, LLC. companies. Sharing information with our ANIMATIC MEDIA, LLC. companies enables us to provide you with information about a variety of products and services that might interest you.</li>

                <li><em>Other Situations.</em> We also may disclose your information:</li>

                <li>In response to a subpoena or similar investigative demand, a court order, or a request for cooperation from a law enforcement or other government agency; to establish or exercise our legal rights; to defend against legal claims; or as otherwise required by law. In such cases, we may raise or waive any legal objection or right available to us, in our sole discretion.</li>

                <li>When we believe disclosure is appropriate in connection with efforts to investigate, prevent, report or take other action regarding illegal activity, suspected fraud or other wrongdoing; to protect and defend the rights, property or safety of our company, our users, our employees, or others; to comply with applicable law or cooperate with law enforcement; or to enforce our website terms and conditions or other agreements or policies.</li>

                <li>In connection with a substantial corporate transaction, such as the sale of our business, a divestiture, merger, consolidation, or asset sale, or in the unlikely event of bankruptcy.</li>
            </ul>

            <p>Any third parties to whom we may disclose personal information may have their own privacy policies which describe how they use and disclose personal information. Those policies will govern use, handling and disclosure of your personal information once we have shared it with those third parties as described in this Privacy Policy. If you want to learn more about their privacy practices, we encourage you to visit the websites of those third parties. These entities or their servers may be located either inside or outside the United States.</p>
            <p><span>Aggregated and non-personal information.</span> We may share aggregated and non-personal information we collect under any of the above circumstances. We may also share it with third parties and our ANIMATIC MEDIA, LLC. sister companies to develop and deliver targeted advertising on our websites and on websites of third parties. We may combine non-personal information we collect with additional non-personal information collected from other sources. We also may share aggregated information with third parties, including advisors, advertisers and investors, for the purpose of conducting general business analysis. For example, we may tell our advertisers the number of visitors to our website and the most popular features or services accessed. This information does not contain any personal information and may be used to develop website content and services that we hope you and other users will find of interest and to target content and advertising.</p>

            <h3>Third-Party Websites</h3>
            <p>There are a number of places on our website or through our services where you may click on a link to access other websites that do not operate under this Privacy Policy. For example, if you click on an advertisement or a search result on our website, you may be taken to a website that we do not control. These third-party websites may independently solicit and collect information, including personal information, from you and, in some instances, provide us with information about your activities on those websites. We recommend that you consult the privacy statements of all third-party websites you visit by clicking on the "privacy" link typically located at the bottom of the webpage you are visiting.</p>
            <h3>How You Can Access Your Information</h3>
            <p>If you have an online account with us, you have the ability to review and update your personal information online by logging into your account. You can also review and update your personal information by contacting us. More information about how to contact us is provided below.</p>
            <p>If you have an online account with us, you also may choose to close your account at any time by going to the "Me" menu, selecting "Settings," then clicking "Delete My Account," contacting customer support at: support@Conferenceondemand.net. After you close your account, you will not be able to sign in to our website or access any of your personal information. However, you can open a new account at any time. If you close your account, we may still retain certain information associated with your account for analytical purposes and recordkeeping integrity, as well as to prevent fraud, collect any fees owed, enforce our terms and conditions, take actions we deem necessary to protect the integrity of our website or our users, or take other actions otherwise permitted by law. In addition, if certain information has already been provided to third parties as described in this Privacy Policy, retention of that information will be subject to those third parties’ policies.</p>

            <h3>Your Choices About Collection and Use of Your Information</h3>
            <p>You can choose not to provide us with certain information, but that may result in you being unable to use certain features of our website because such information may be required in order for you to register as a member; purchase products or services; participate in a contest, promotion, survey, or sweepstakes; ask a question; or initiate other transactions on our website.</p>
            <p>When you register on our website you may be given a choice as to whether you want to receive email messages and/or newsletters about product updates, improvements, special offers, or containing special distributions of content by us. At any time you can choose to no longer receive commercial or promotional emails or newsletters from us by clicking on the following link and entering the email address associated with your account: click here. You also will be given the opportunity, in any commercial e-mail that we send to you, to opt out of receiving such messages in the future. It may take up to 10 days for us to process an opt-out request. We may send you other types of transactional and relationship e-mail communications, such as service announcements, administrative notices, and surveys, without offering you the opportunity to opt out of receiving them. Please note that, changing information in your account, or otherwise opting out of receipt of promotional email communications will only affect future activities or communications from us. If we have already provided your information to a third party (such as a credit card processing partner or an event provider) before you have changed your preferences or updated your information, you may have to change you preferences directly with that third party.</p>
            <p>You may tell us not to share your personal information with third parties or the ANIMATIC MEDIA, LLC. family of companies for direct marketing purposes by clicking on the following link and entering the email address associated with your account: click here.</p>

            <h3>How We Protect Your Personal Information</h3>
            <p>We take appropriate security measures (including physical, electronic and procedural measures) to help safeguard your personal information from unauthorized access and disclosure. For example, only authorized employees are permitted to access personal information, and they may do so only for permitted business functions. In addition, we use encryption in the transmission of your sensitive personal information between your system and ours, and we use firewalls to help prevent unauthorized persons from gaining access to your personal information.</p>
            <p>We want you to feel confident using our website to transact business. However, no system can be completely secure. Therefore, although we take steps to secure your information, we do not promise, and you should not expect, that your personal information, searches, or other communications will always remain secure. Users should also take care with how they handle and disclose their personal information and should avoid sending personal information through insecure email. Please refer to the Federal Trade Commission’s website at <a href='http://www.ftc.gov/bcp/menus/consumer/data.shtm' target='_blank'>http://www.ftc.gov/bcp/menus/consumer/data.shtm</a> for information about how to protect yourself against identity theft.</p>

            <h3>User Submissions</h3>
            <p>We may provide areas on our websites where you can post information about yourself and others and communicate with others; upload content (e.g. pictures, videos, audio files, etc.); and post comments or reviews of content found on the Website. Such postings are governed by our Terms and Conditions of Use. In addition, such postings may appear on other websites or when searches are executed on the subject of your posting. Also, whenever you voluntarily disclose personal information on publicly-viewable web pages, that information will be publicly available and can be collected and used by others. For example, if you post your email address, you may receive unsolicited messages. We cannot control who reads your posting or what other users may do with the information you voluntarily post, so we encourage you to exercise discretion and caution with respect to your personal information. Once you have posted information, you may not be able to edit or delete such information.</p>

            <h3>Children’s Privacy</h3>
            <p>Our website is a general audience site, and we do not knowingly collect personal information from children under the age of 13.</p>
            <h3>Visiting Our Websites from Outside the United States</h3>
            <p>This Privacy Policy is intended to cover collection of information on or via our website from residents of the United States. If you are visiting our website from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States where our servers are located and our central database is operated. The data protection and other laws of the United States and other countries might not be as comprehensive as those in your country. Please be assured that we seek to take reasonable steps to ensure that your privacy is protected. By using our services, you understand that your information may be transferred to our facilities and those third parties with whom we share it as described in this privacy policy.</p>

            <h3>No Rights of Third Parties</h3>
            <p>This Privacy Policy does not create rights enforceable by third parties or require disclosure of any personal information relating to users of the website.</p>

            <h3>Changes to This Privacy Policy</h3>
            <p>We will occasionally update this Privacy Policy to reflect changes in our practices and services. When we post changes to this Privacy Policy, we will revise the "Last Updated" date at the top of this Privacy Policy. If we make any material changes in the way we collect, use, and/or share your personal information, we will notify you by sending an email to the email address you most recently provided us in your account, profile or registration (unless we do not have such an email address), and/or by prominently posting notice of the changes on our website. We recommend that you check our website from time to time to inform yourself of any changes in this Privacy Policy or any of our other policies.</p>
            <h3>How to Contact Us</h3>
            <p>If you have any questions about this Privacy Policy or our information-handling practices, or if you would like to request information about our disclosure of personal information to third parties for their direct marketing purposes, please contact us by email or postal mail as follows:</p>

            <p><span>Animatic Media, LLC</span><br/>
                2280 Grand Avenue<br/>
                Suite 305<br/>
                Baldwin, NY 11510<br/>
                ©2021 Animatic Media, LLC. All rights reserved.</p>

            <h3>Linked information:</h3>

            <h3>Cookies:</h3>
            <p>A cookie is a small text file that is stored on a user’s computer for record keeping purposes. Cookies can be either session cookies or persistent cookies. A session cookie expires when you close your browser and is used to make it easier for you to navigate our website. A persistent cookie remains on your hard drive for an extended period of time.</p>
            <p>For example, when you sign in to our website, we will record your user or member ID and the name on your user or member account in the cookie file on your computer. We also may record your password in this cookie file, if you indicated that you would like your password saved for automatic sign-in. For security purposes, we will encrypt any usernames, passwords, and other user or member account-related data that we store in such cookies. In the case of sites and services that do not use a user or member ID, the cookie will contain a unique identifier. We may allow our authorized service providers to serve cookies from our website to allow them to assist us in various activities, such as doing analysis and research on the effectiveness of our site, content and advertising.</p>
            <p>You may delete or decline cookies by changing your browser settings. (Click "Help" in the toolbar of most browsers for instructions.) If you do so, some of the features and services of our website may not function properly.</p>
            <p>We may allow third-parties, including ANIMATIC MEDIA, LLC. companies, advertising companies, and ad networks, to display advertisements on our site. These companies may use tracking technologies, such as cookies, to collect information about users who view or interact with their advertisements. Our website does not provide any personal information to these third parties, but they may collect information about where you, or others who are using your computer, saw and/or clicked on the advertisements they deliver, and possibly associate this information with your subsequent visits to the advertised websites. They also may combine this information with personal information they collect from you. The collection and use of that information is subject to the third-party’s privacy policy. This information allows them to deliver targeted advertisements and gauge their effectiveness. Some of these third-party advertising companies may be advertising networks that are members of the Network Advertising Initiative, which offers a single location to opt out of ad targeting from member companies (www.networkadvertising.org).</p>

            <h3>Web Beacons:</h3>
            <p>Web beacons (also known as clear gifs, pixel tags or web bugs) are tiny graphics with a unique identifier, similar in function to cookies, and are used to track the online movements of web users or to access cookies. Unlike cookies which are stored on the user’s computer hard drive, web beacons are embedded invisibly on the web pages (or in email) and are about the size of the period at the end of this sentence.</p>
            <p>Web beacons may be used to deliver or communicate with cookies, to count users who have visited certain pages and to understand usage patterns. We also may receive an anonymous identification number if you come to our site from an online advertisement displayed on a third-party website.</p>
        </div>
    );
}