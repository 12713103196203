import React, {useContext} from "react";
import { Link } from 'react-router-dom'
import {EventContext} from "../../contexts/EventContext";
import './SmallExhibitCard.scss';

export const SmallExhibitCard = (props) => {
    const { event } = useContext(EventContext);
    const exhibitpath = props.exhibit.name.toLowerCase().replace(/\s/g, '');
    return (
        <Link to={`${event.alias}tradeshow/${exhibitpath}`}>
            <section className={`SmallExhibitCard`}>
                <div className='image_holder'>
                    <img src={`${props.assetPath}/${exhibitpath}/logo.png`} />
                </div>
                <div>
                    <h3>{props.exhibit.name}</h3>
                    <p>{props.exhibit.description}</p>
                </div>
            </section>
        </Link>
    );
}


