import React from 'react';
import {API} from "../controllers/API";
import {useAPI} from "./useAPI";
import {UserEventContext} from "./UserEventContext";

export const UserEventProvider = (props) => {

    const {
        postService
    } = useAPI();

    const logEvent = (userid, eventid, sessionid, type, value) => {
        let payload = {
            userid: userid,
            eventid: eventid,
            sessionid: sessionid,
            event_type: type,
            event_value: value,
            url: window.location.pathname,
        };
        postService(API.logEventAPI, payload);
    }

const userEventValues = {
        logEvent,
    };

    return (<UserEventContext.Provider value={userEventValues}>
        {props.children}
    </UserEventContext.Provider>);
};
