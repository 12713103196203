import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EventContext } from '../contexts/EventContext';
import { pageResolver } from "./pageResolver";
import './TrackViewer.scss';

export function TrackViwer() {
    let paths = useParams();
    const [ viewComponent, setViewComponent ] = useState({
        header: null,
        page: null,
    });
    const { setEventRoute, event } = useContext(EventContext);

    useEffect(() => {
        setEventRoute([paths.client, paths.eventpath]);
        setViewComponent(pageResolver(`${paths.client}/${paths.eventpath}`));
    }, []);

    return (
        <article className={`${paths.client}${paths.eventpath}`}>
            {viewComponent.header}
            <div className='TrackViwer'>
                TRACK VIEWER
            </div>
        </article>
    )
}
