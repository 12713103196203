import React, { useContext, useEffect, useState, useRef } from "react";
import {Dialog, TwoColumnLayout} from 'zebra-stripes/Layouts'
import {TextArea, Button, TextBox} from 'zebra-stripes/Forms'
import {Icon} from 'zebra-stripes/Symbols/Icon';
import { TermsAndConditionsContent, PrivacyContent } from './TermsAndConditionsContent';
import {useAPI} from "../contexts/useAPI";
import {AuthenticationContext} from "../contexts/AuthenticationContext";
import './CODFooter.scss';
import {API} from "../controllers/API";

export const CODFooter = () => {

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [title, setTitle] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const [firstnameError, setFirstnameError] = useState(null);
    const [lastnameError, setLastnameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [messageError, setMessageError] = useState(null);
    const [sending, setSending] = useState(false);
    const [sendMessage, setSendMessage] = useState(false);
    const privacyDialogRef = useRef(null);
    const termsDialogRef = useRef(null);
    const contactDialogRef = useRef(null);
    const setDefaultsRef = useRef(false);
    const {
        postService
    } = useAPI();
    const { user, validateEmail } = useContext(AuthenticationContext);
    useEffect(() => {
        if (user && !setDefaultsRef.current) {
            setFirstname(user.firstname);
            setLastname(user.lastname);
            setCompany(user.company);
            setTitle(user.title);
            setEmail(user.email);
            setPhone(user.wphone || user.mphone);
            setDefaultsRef.current = true;
        }
    }, [user]);

    const submitContact = () => {
        let hasError = false;
        if(firstname === "") {
            setFirstnameError("Please enter a first name.");
            hasError = true;
        } else {
            setFirstnameError(null);
        }
        if(lastname === "") {
            setLastnameError("Please enter a last name.");
            hasError = true;
        } else {
            setLastnameError(null);
        }
        if(message === "") {
            setMessageError("Please supply a message.");
            hasError = true;
        } else {
            setMessageError(null);
        }
        if(email === "") {
            setEmailError("Please enter an email.");
            hasError = true;
        } else if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address.");
            hasError = true;
        } else {
            setEmailError(null);
        }

        if(!hasError) {
            let payload = {
                fname:firstname,
                lname:lastname,
                email:email,
                company:company,
                title:title,
                phone:phone,
                message:message
            };
            setSending(true);

            postService(
                API.contactAPI,
                payload
            ).then((data) => {
                if (data.success) {
                    setSendMessage({
                        success: true,
                        message: 'Message successfully sent.',
                    });
                    setTimeout(() => {
                        privacyDialogRef.current.close();
                    }, 3000);
                } else {
                    setSending(false);
                    setSendMessage({
                        success: false,
                        message: 'There was a problem with your submission. Please try again later.'
                    });
                }
                setTimeout(() => {
                    setSending(false);
                    setSendMessage('');
                }, 6000);
            });
        }
    }

    return (
        <footer className="AppFooter">
            <div>
                <div className={'social_links'}>
                    <a href='https://www.linkedin.com/company/animatic-media-llc'><Icon iconid='linkedin' /></a>
                    <a href='https://www.instagram.com/animaticm/'><Icon iconid='instagram' /></a>
                    <a href='https://www.facebook.com/AnimaticM/'><Icon iconid='facebook' /></a>
                    <a href='https://twitter.com/AnimaticM'><Icon iconid='twitter' />   </a>
                </div>
                <div className={'contact_links'}>
                    <div>CONTACT US: <a href="mailto:support@conferenceondemand.net">support@conferenceondemand.net</a>&nbsp;&nbsp;&nbsp;888-280-4800</div>
                    <div>
                        <Button onClick={() => privacyDialogRef.current.open()}>Privacy Policy</Button>
                        <Button onClick={() => termsDialogRef.current.open()}>Terms and Conditions</Button>
                        <Button onClick={() => contactDialogRef.current.open()}>Contact Form</Button>
                    </div>
                </div>
                <div className={'footer_logos'}>
                    <a href='https://www.animatic.com' target={'_blank'}><img src="/images/AM_logo_alpha.png" alt='AM Logo'/></a>
                </div>
            </div>
            <Dialog className="privacy-dialog" ref={privacyDialogRef}
                    width="80%"
                    cardStyle={{maxHeight: '80vh', overflow: 'auto'}}
                    showClose={true}
            >
                <>
                    <div className='close_button'><Button type='primary' onClick={() => privacyDialogRef.current.close()}>Close</Button></div>
                    <PrivacyContent />
                </>
            </Dialog>

            <Dialog className="terms-dialog" ref={termsDialogRef}
                    width="80%"
                    cardStyle={{maxHeight: '80vh', overflow: 'auto'}}
                    showClose={true}
            >
                <>
                    <div className='close_button'><Button type='primary' onClick={() => termsDialogRef.current.close()}>Close</Button></div>
                    <TermsAndConditionsContent />
                </>
            </Dialog>

            <Dialog className="contact-dialog" ref={contactDialogRef}
                    title={<div><Icon basestyle={{float: 'left', margin:'-6px 10px 0 0'}} iconid="email" size="large" color="rgb(0, 124, 176)"/>Contact Conference On Demand</div>}
                    width="75%"
                    cardStyle={{minWidth: '400px', maxHeight: '80vh', overflow: 'auto'}}
                    showClose={true}
                    forceBottomAlign={true}
                    actions={[
                        <Button key="cancelContact" onClick={() => {contactDialogRef.current.close();}}>Cancel</Button>,
                        <Button type="primary" key="submitContact" onClick={submitContact}>Send Message</Button>
                    ]}
            >
                    <div className={sending ? "sending": ""}>
                    {sendMessage ? <p className={`send-message ${sendMessage.success ? 'success' : 'failure'}`}>{sendMessage.message}</p> : null}
                    <TwoColumnLayout
                        columnOne={[
                            <TextBox showBorder={true} value={firstname} onChange={(e,v) => setFirstname(v)} error={firstnameError} width="100%" placeholder="First Name *" />,
                            <TextBox showBorder={true} value={email} onChange={(e,v) => setEmail(v)}  error={emailError} width="100%" placeholder="Email Address *" />,
                            <TextBox showBorder={true} value={title} onChange={(e,v) => setTitle(v)}   width="100%" placeholder="Title" />
                        ]}
                        columnTwo={[
                            <TextBox showBorder={true} value={lastname} onChange={(e,v) => setLastname(v)}  error={lastnameError} width="100%" placeholder="Last Name *" />,
                            <TextBox showBorder={true} value={phone} onChange={(e,v) => setPhone(v)}   width="100%" placeholder="Phone" />,
                            <TextBox showBorder={true} value={company} onChange={(e,v) => setCompany(v)}  width="100%" placeholder="Company" />
                        ]}
                        gutter={16}
                    />
                    <TextArea error={messageError} onChange={(e,v) => setMessage(v)} style={{height: '4em'}} width="100%" placeholder="Message *" />
                    <p>Phone: 888-280-4800</p>
                    </div>
            </Dialog>
        </footer>
    )
}
