import React, {useRef, useState} from "react";
import { Link } from "react-router-dom";
import {AnimaticHeader} from "./AnimaticHeader";
import {ContactForm} from "./ContactForm";
import {Icon} from "zebra-stripes/Symbols/Icon";
import './CODHomepage.scss';

export const AVProduction = (props) => {
    return (
        <article className="homepage">
            <AnimaticHeader />
            <div className='hero mini'>
                <a href='/home'><img alt='COD Logo' src='/images/homepage/COD-Live_LOGO2020_4c.png' /></a>
            </div>
            <div className="content_wrapper" id='homepage_hosting'>

                <div>
                    <h3>AV Production & Video</h3>
                    <p>Conference-On-Demand is a service from <a href="http://www.animatic.com">Animatic Media</a> a full service video and AV production company for corporate events.</p>
                </div>



            </div>
            <div className="content_wrapper" id='homepage_contact'>
                <div>
                    <h3>Contact</h3>
                    <ContactForm isBlog={false} />
                </div>
            </div>
        </article>
    )
}