import React, {useState, useEffect, useContext, useRef} from 'react';
import { EventContext } from './EventContext';
import {API} from "../controllers/API";
import {useAPI} from "./useAPI";
import {AuthenticationContext} from "./AuthenticationContext";
import {Subject, BehaviorSubject} from "rxjs";
import {isFullEventProduct, productType} from "../components/Payment/products";
import {ProductCard} from "../components/Payment/ProductCard";
import moment from "moment-timezone";
import * as _ from 'lodash';

const timeZoneMap = {
    "EST" : "America/New_York",
    "PST" : "America/Los_Angeles"
};

export const SessionViewStatus = {
    StreamingNow: 'STREAMING_NOW',
    UpcomingLive: 'UPCOMING_LIVE',
    Upcoming: 'UPCOMING',
    LiveSoon: 'LIVE_SOON',
    Completed: 'COMPLETED',
    LiveEventEnded: 'LIVE_EVENT_ENDED',
}

export const DEFAULT_REQUIRED_FIELDS = '';
export const EventContextProvider = (props) => {
    const [ eventRoute, setEventRoute ] = useState(null);
    const [ event, setEvent ] = useState(null);
    const eventRef = useRef(null);
    const [ purchaseOptions, setPurchaseOptions ] = useState(null);
    const [ products, setProducts ] = useState([]);
    const [ sessions, setSessions ] = useState([]);
    const [ session, setSession ] = useState(null);
    const [ track, setTrack ] = useState(null);
    const eventReady$Ref = useRef(new Subject(null));
    const sessionReady$Ref = useRef(new Subject(null));
    const launchRegistration$Ref = useRef(new BehaviorSubject(null));
    const purchaseCompleted$Ref = useRef(new Subject(null));
    const {
        postService
    } = useAPI();

    useEffect(() => {
        if (eventRoute) {
            requestEventInfo();
        }
    }, [eventRoute]);

    useEffect(() => {
        // if the event has products, lets pre-populate that for the user.
        if (event?.hasProducts && !purchaseOptions) {
            getPurchaseOptionsByEvent({});
        }
        eventRef.current = event;
    }, [event]);

    useEffect(() => {
        if (products.length) {
            setPurchaseOptions(resolveAvailableProducts(products));
        }
    }, [session]);

    const requestEventInfo = () => {
        getEventData(eventRoute);
    }

    const aggregateSessions = (model) => {
        let obj = [];
        if(model) {
            model.map(s => {
                if (obj[s.sessiondate]) {
                    obj[s.sessiondate].push(s);
                } else {
                    obj[s.sessiondate] = [];
                    obj[s.sessiondate].push(s);
                }
            });
        }
        return obj;
    }

    const getEventData = (eventRoute) => {
        const eventPath = eventRoute.slice().splice(0,2).join('/').toLowerCase();
        postService(
            API.eventUrl,
            {
                path: eventPath,
                event_only: true,
            }
        )
            .then(data => {
                setEvent(data.event);
                setPurchaseOptions(null);
                eventReady$Ref.current.next(data.event);
                if (eventRoute[2]) {
                    getSessionData(data.event, eventRoute[2]);
                }
            });
    }

    const getSessionListing = (sortObj = {}, trackID) => {
        const sessionPayload = {
            orderby: 'session.sessiondate asc, session.starttime asc, tracks.tracktitle asc',
            track_id: trackID ? trackID : null,
            sort: trackID ? 'sessions' : 'date',
            // session_id: this.props.sessionOverride !== undefined ? this.props.sessionOverride : this.props.match.params.sessionid*1,
            event_id: event?.id,
            event_path: (eventRoute.join('/')).toLowerCase(),
            event_media: [event?.doc,event?.ppt,event?.pdf,event?.mp3,event?.flv],
            search: null,
            hours: null,
            startdate: null,
            enddate: null,
            ...sortObj
        };
        postService(API.eventSessionsAPI, sessionPayload)
            .then(data => {
                if (data?.sessions) {
                    setSessions(data.sessions);
                }
            });
    };

    const getSessionData = (
        eventObj,
        sessionId,
    ) => {
        let getSessionPayload = {
            session_id: sessionId,
            event_id: eventObj.id,
            event_media: [eventObj.doc,eventObj.ppt,eventObj.pdf,eventObj.mp3,eventObj.flv],
        };
        postService(
            API.sessionAPI,
            getSessionPayload,
        )
            .then(data => {
                setSession(data.session);
                setTrack(data.track);
                sessionReady$Ref.current.next(data);
            });
    }

    const getPurchaseOptionsByEvent = ({
        sessionId = null,
        productId = null,
    }) => {
         if (purchaseOptions) {
             return purchaseOptions;
         }
        const payload = {
            event_id: event.id,
            session_id: sessionId,
            public: true,
            product_id: productId,
        };
        postService(
            API.eventProductsUrl,
            payload
        )
            .then(data => {
                if (data) {
                    setProducts(data.products);
                    setPurchaseOptions(resolveAvailableProducts(data.products));
                }
            });
    }

    const getTimeZone = (key) => {
        return timeZoneMap[key];
    };

    /**
     * function to resolve the products available for purchase based on the scope of the page you are on.
     */
    const resolveAvailableProducts = (productList) => {
        const products = [];
        let hasSpecificSessionProduct = false;
        let hasSpecificTrackProduct = false;
        productList?.forEach((p,i) => {
            switch (p.product_id) {
                case productType.Session:
                    if (session) {
                        if (p.session_id.length === 0 || p.session_id.includes(session.id)) {
                            const now = moment();
                            if (p.end_date === '0000-00-00' || now.diff(moment(p.end_date)) < 0) {
                                products.push(p);
                                if (!hasSpecificSessionProduct && p.session_id.includes(session.id)) {
                                    hasSpecificSessionProduct = true;
                                }
                            }
                        }
                    }
                    break;
                case productType.Track:
                    if (session && session.trackid) {
                        if (p.track_id.length === 0 || p.track_id.includes(session.trackid)) {
                            products.push(p);
                            if (!hasSpecificTrackProduct && p.track_id.includes(session.trackid)) {
                                hasSpecificTrackProduct = true;
                            }
                        }
                    }
                    break;
                case productType.Conference:
                case productType.FreeEvent:
                    products.push(p);
                    break;
            }
        });

        let filteredProducts = products;
        if (hasSpecificSessionProduct) {
            filteredProducts = _.remove(products, (prod) => {
                return !(prod.product_id === productType.Session && prod.session_id.length === 0);
            });
        }
        if (hasSpecificTrackProduct) {
            filteredProducts = _.remove(products, (prod) => {
                return !(prod.product_id === productType.Track && prod.track_id.length === 0);
            });
        }
        return filteredProducts;
    };

    const getSessionDateAccess = (sessionObj) => {
        let minAway = null;
        let isToday = null;
        let status = SessionViewStatus.Upcoming;
        let icon = null;
        let sdate = null;
        const START_MINUTES_DELTA = 15;
        const END_MINUTES_DELTA = 30;
        if (sessionObj) {
            const hasLiveEvent = !!sessionObj.LiveWebinar || !!sessionObj.live;
            if (hasLiveEvent) {
                status = SessionViewStatus.UpcomingLive;
            }
            sdate = moment(sessionObj.sessiondate);
            let stime = sessionObj.starttime ? sessionObj.starttime.split(":") : [0, 0, 0];
            sdate.hour(stime[0]);
            sdate.minutes(stime[1]);

            let edate = moment(sessionObj.sessiondate);
            let etime = sessionObj.endtime ? sessionObj.endtime.split(":") : [0, 0, 0];
            edate.hour(etime[0]);
            edate.minutes(etime[1]);

            const lengthOfSession = edate.diff(sdate, 'minutes');

            const now = moment();
            const timeZoneDiff = eventRef.current.timezone ?
                now.utcOffset() - moment(sdate).tz(getTimeZone(eventRef.current.timezone)).utcOffset() : 0;

            minAway = sdate.diff(now, 'minutes') + timeZoneDiff;
            isToday = sdate.format('MM/DD/YYYY') === now.format('MM/DD/YYYY');

            if (hasLiveEvent && minAway <= START_MINUTES_DELTA && minAway > (lengthOfSession*-1 - END_MINUTES_DELTA)) {
                status = SessionViewStatus.StreamingNow;
                icon = 'LIVE-StreamingNow';
            } else if (hasLiveEvent && isToday && (minAway > START_MINUTES_DELTA)) {
                status = SessionViewStatus.LiveSoon;
                icon = 'LIVE-StreamingSoon';
            } else if ((minAway) < (lengthOfSession*-1)) {
                status = hasLiveEvent ? SessionViewStatus.LiveEventEnded : SessionViewStatus.Completed;
            }
        }

        return {
            minAway: minAway,
            isToday: isToday,
            status: status,
            icon: icon,
            startDate: sdate,
        }
    }

    const eventValues = {
        setEventRoute,
        eventRoute,
        event,
        sessions,
        getSessionListing,
        getSessionData,
        purchaseOptions,
        allProductsForEvent: products,
        session,
        track,
        eventReady$: eventReady$Ref.current,
        sessionReady$: sessionReady$Ref.current,
        launchRegistration$: launchRegistration$Ref.current,
        purchaseCompleted$: purchaseCompleted$Ref.current,
        resolveAvailableProducts,
        getTimeZone,
        aggregateSessions,
        getSessionDateAccess,
    };

    return (<EventContext.Provider value={eventValues}>
        {props.children}
    </EventContext.Provider>);
};
