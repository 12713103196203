import React, {useContext, useEffect, useState, useRef} from "react";
import {EventHeader} from "./EventHeader";
import {Dialog} from 'zebra-stripes/Layouts'
import {Button} from "zebra-stripes/Forms";
import {Icon} from "zebra-stripes/Symbols/Icon";
import {AuthenticationContext} from "../contexts/AuthenticationContext";
import './EventLayout.scss';
import {AuthenticationEvents} from "../contexts/Authentication";
import {CODFooter} from "./CODFooter";
import {useLocation} from "react-router-dom";

export const HideHeaderPaths = [
    '/', '/home', '/features', '/about', '/avproduction', '/coddemo', '/graduation', '/resources',
];
export const HideHeaderPatterns = [
    '/join', '/presenterShare',
];
export const HideFooterPatterns = [
    '/join', '/presenterShare',
];

export function EventLayout(props) {
    const { loginEvent$, reAuthenticateUser, destroySession } = useContext(AuthenticationContext);
    const kickOutFormRef = useRef(null);
    const [ kickOutMessage, setKickoutMessage ] = useState(null);
    const ignoredExpiredRef = useRef(false);

    useEffect(() => {
        const authenticateSubscription = loginEvent$.subscribe((loginEvent) => {
            switch(loginEvent.action) {
                case AuthenticationEvents.Concurrent:
                    setKickoutMessage({
                        message: <span>You have been logged off as the result of simultaneous logins under this account. If this is a mistake please contact <a href='mailto:support@conferenceondemand.net'>support@conferenceondemand.net</a></span>,
                        actions: <Button tabIndex="3" type='primary' onClick={() => {
                                    setKickoutMessage(null);
                                    kickOutFormRef.current.close()
                                }}>
                                    Continue
                                </Button>
                    });
                    kickOutFormRef.current.open();
                    break;
                case AuthenticationEvents.SessionExpired:
                    setKickoutMessage({
                        message: 'You have been logged off based on an expired session. Please login again to access the site.',
                        actions: <Button tabIndex="3" type='primary' onClick={() => {
                            setKickoutMessage(null);
                            kickOutFormRef.current.close();
                            destroySession();
                        }}>
                            Continue
                        </Button>
                    });
                    kickOutFormRef.current.open();
                    break;
                case AuthenticationEvents.SessionAboutToExpire:
                    if (!ignoredExpiredRef.current) {
                        setKickoutMessage({
                            message: 'Your session is about to expire. if you wish to stay logged in, please confirm.',
                            actions: [
                                <Button key='button1' tabIndex="3" type='outlined' onClick={() => {
                                    setKickoutMessage(null);
                                    ignoredExpiredRef.current = true;
                                    kickOutFormRef.current.close()
                                }}>
                                    Ignore
                                </Button>,
                                <Button key='button2' tabIndex="3" type='primary' onClick={() => {
                                    reAuthenticateUser().then(() => {
                                        setKickoutMessage(null);
                                        ignoredExpiredRef.current = false;
                                        kickOutFormRef.current.close();
                                    });
                                }}>
                                    Stay Logged In
                                </Button>
                            ]
                        });
                        kickOutFormRef.current.open();
                    }
                    break;
            }
        });

        return () => {
            authenticateSubscription.unsubscribe();
        }
    }, []);

    const location = useLocation();
    // console.log(location);
    const hideHeader = (currentPath) => {
        const containsPartialPattern =
            HideHeaderPatterns.filter((pattern) => {
                return currentPath?.includes(pattern);
            });
        return HideHeaderPaths.includes(currentPath) || containsPartialPattern.length;
    }
    const hideFooter = (currentPath) => {
        const containsPartialPattern =
            HideFooterPatterns.filter((pattern) => {
                return currentPath?.includes(pattern);
            });
        return containsPartialPattern.length;
    }

    return (
        <section className='EventLayout'>
            {!hideHeader(location.pathname) &&
            <EventHeader />}
            <div className='EventBody'>
                {props.children}
            </div>
            {!hideFooter(location.pathname) &&
            <CODFooter />}
            <Dialog key="d2" className="kickout-dialog" ref={kickOutFormRef}
                    width="85%"
                    showClose={false}
                    dialogStyle={{maxWidth: '900px', maxHeight: '90vh', padding: '20px'}}
                    cardStyle={{padding: '20px'}}
            >
                {(kickOutMessage && !ignoredExpiredRef.current) &&
                <div className='kickout_content'>
                    <div>
                        <Icon iconid="alert" color="red" size="40px"/>
                    </div>
                    <div>
                        <p>{kickOutMessage.message}</p>
                        <div>
                            {kickOutMessage.actions}
                        </div>
                    </div>
                </div>
                }
            </Dialog>
        </section>
    )
}
