import { VHCHomepage } from './events/VHCHomepage';
import {VHCHeader} from "./events/VHCHeader";
import {NOHCHeader, NOHCFooter} from "./events/NOHCHeader";
import {NOHCHomepage} from "./events/NOHCHomepage";

export const pageResolver = (path) => {
    switch (path) {
        case 'vhc/2020':
        case 'vhc/2021':
            return {
                header: <VHCHeader />,
                page: <VHCHomepage />,
                footer: null,
            }
        case 'nohc/2021':
            return {
                header: <NOHCHeader />,
                page: <NOHCHomepage />,
                footer: <NOHCFooter />,
            }
        default:
            break;
    }
}
