import React, { useContext } from "react";
import { API } from '../../controllers/API';
import {AuthenticationContext} from "../../contexts/AuthenticationContext";
import {UserEventContext} from "../../contexts/UserEventContext";

export const SupplementalPresentations = (props) => {
    const {
        event_path = '',
        sessionid = null,
        event = null,
        files = [],
        className = '',
        downloadable = true,
        onDownload = () => false,
    } = props;
    const { logEvent } = useContext(UserEventContext);
    const { user } = useContext(AuthenticationContext);

    const launchDownload = (e, file) => {
        logEvent(
            user,
            event.id,
            sessionid,
            "supplement",
            "download"
        );
        onDownload();
        if(!user) {
            e.preventDefault();
            return false;
        }
    }

    const mediaObjs = files.map(f => {
        let typearr = f.name.split(".");
        let type = typearr.length ? typearr[typearr.length - 1] : null;
        let cleanname = f.name.split("/");
        cleanname = cleanname.length ? cleanname[cleanname.length - 1] : f.name;
        let url = API.presentationMediaURL + '/' + event_path + f.name;
        return (
            <p key={cleanname}>
                <a onClick={(e) => { launchDownload(e, url); }} href={url} download={cleanname} target="_self">
                    <img src={"/images/" + (type ? type : 'file') + ".svg"}  alt='Download Icon' /> {cleanname}
                </a> ({f.size < 100000 ? `${(f.size/1000).toFixed(2)}kb` : `${(f.size/1000000).toFixed(2)}mb`})</p>
        );
    });

    return (
        files.length ?
            <div className={"SupplementalFilesPanel " + className}>
                <p key="titleline">Below are supplemental files included in the current session by the speaker:</p>
                {mediaObjs}
            </div>
            : null
    )
};