import React from "react";
import {API_SERVER} from "../controllers/API";
import './SpeakerBioPopUp.scss';

export const SpeakerBioPopUp = (props) => {
    const {speaker, onMouseOver, onMouseOut} = props;
    return (
        <div className={`speaker_bio ${speaker.show ? 'show' : ''}`} style={{left: speaker.x + 'px', top: (speaker.y - 200) + 'px'}}
             onMouseOver={onMouseOver} onMouseOut={onMouseOut}
        >
            {speaker.image && <div className='bio_image'><img src={`${API_SERVER}${speaker.image}`} /></div>}
            <div dangerouslySetInnerHTML={{__html : speaker.bio}} />
        </div>
    )
}
