import React, { useContext, useEffect } from "react";
import {EventContext} from "../../contexts/EventContext";
import './NOHCHomepage.scss';
import { Link } from "react-router-dom";
import {SessionListing} from "../../components/SessionListing";

export function NOHCHomepage() {
    const { event } = useContext(EventContext);

    const openEventApp = (url) => {
        window.open(url , '_blank');
    }

    const launchHome = () => {

    }

    const launchExhibits = () => {

    }
    return (
        <div className='NOHCHomepage2021'>
            <style>
                @import url('https://fonts.googleapis.com/css?family=Lato');
            </style>
            <div>
                <div className='homepage bodyContent'>
                    <div className = "menu">
                        <button onClick={launchHome}>HOME</button>
                        <button onClick={() => false}>EDUCATIONAL SESSIONS</button>
                        <Link to={'./tradeshow'}><button onClick={launchExhibits}>EXHIBITS</button></Link>
                        <button onClick={() => false}>POSTER SESSIONS</button>
                        <button onClick={() => false}>SOCIAL EVENTS</button>
                    </div>

                    <section key="s1" className="welcome">
                        <h1>National Oral Health Conference</h1>
                        <h1>Connecting Globally for Oral Health</h1>
                        <p style={{fontWeight: 'bold'}}>Fridays and Saturdays April 16-30, 2021</p>
                        <p>On-Demand Content will be made available the week of May 3rd 2021</p>
                    </section>

                    <SessionListing
                        showPresenterBio={true}
                    />
                </div>
                <div className='sponsors'>
                    <img src='/images/vhc/2021_Catalyst_Summit_Sponsor_Logos.png' />
                </div>
            </div>
        </div>
    )
}
