import React, {useState, useEffect, useContext, useRef} from 'react';
import {API} from "../controllers/API";
import {useAPI} from "./useAPI";
import * as _ from 'lodash';
import {RegistrationContext} from "./RegistrationContext";
import {EventContext} from "./EventContext";
import {AuthenticationContext} from "./AuthenticationContext";
import {calculateTotalPrice, cleanProductDescription, enrichedProductDescription} from "../helpers/ProductHelper";

export const CustomQuestionType = {
    OpenEnded: 1,
    Single: 2,
    Multiple: 3,
};

export const RegistrationProvider = (props) => {
    const { event, session, track, purchaseCompleted$ } = useContext(EventContext);
    const { user, setAuthentication, setProductAccess, productAccessStorageIDRef } = useContext(AuthenticationContext);
    const [ regClientSecret, setRegClientSecret ] = useState('');
    const [ ignorePurchasing, setIgnorePurchasing ] = useState(false);
    const [ paymentIntentID, setPaymentIntentID ] = useState(null);
    const [ countries, setCountries ] = useState([]);
    const [ customQuestionAnswers, setCustomQuestionAnswers ] = useState({});
    const [ userObj, setUserObj ] = useState(user);

    const [ billingInfo, setBillingInfo ] = useState({
        firstname: '',
        lastname: '',
        address: '',
        city: '',
        state: '',
    });

    const [ promo, setPromo ] = useState(null);
    const [ product, setProduct ] = useState(null);
    const [ confirmation, setConfirmation ] = useState(null);

    useEffect(async () => {
        if (product) {
            await initialize();
        }
    }, [promo, product, billingInfo]);

    useEffect(() => {
        setUserObj(user);
    }, [user]);

    useEffect(() => {
        getCountries();
    }, []);

    const {
        postService
    } = useAPI();

    const initialize = async () => {
        const payload = {
            product_id: product.id,
            promo: promo?.id,
            event_name: event.name,
            email: userObj?.email,
            intentID: paymentIntentID,
            ...billingInfo,
        };
        await postService(
            API.stripeIntentAPI,
            payload,
        ).then((data) => {
            if (data) {
                setRegClientSecret(data.clientSecret);
                setPaymentIntentID(data.intentID);
            }
        });
    }

    const validatePromo = async (promoInput, cb) => {
        let payload = {
            event_id: event.id,
            session_id: product.session?.id || null,
            user_id: userObj?.userid || null,
            track_id: product.track?.id || null,
            product_id: product.id,
            product_type_id: product.product_id,
            promo: promoInput,
        };
        postService(
            API.validatePromoAPI,
            payload
        ).then((data) => {
            setPromo(data.promo);
            cb(data);
        });
    }

    const getProductSessionId = (product, session) => {
        if (product.product_id === 1 && session?.id) {
            return session.id;
        } else {
            return '';
        }
    }
    const getProductTrackId = (product, track) => {
        if (product.product_id === 2 && track?.id) {
            return track.id;
        } else {
            return '';
        }
    }

    const completeRegistration = async (userPayloadObject) => {
        const finalPricing = calculateTotalPrice(product, promo);
        let payloadObj = {
            ...userPayloadObject,
            custom_questions: convertCustomQuestionstoArray(customQuestionAnswers),
        };
        console.log(userPayloadObject);
        let paymentResponse = {};
        const productDescription = product ? cleanProductDescription({
            ...product,
            event: event,
            session: session,
        }) : null;
        if (product) {
            payloadObj = {
                ...payloadObj,
                product_type_id: product.product_id,
                product_id: product.id,
                start_date: product.start_date === '0000-00-00' ? null : product.start_date,
                end_date: product.end_date === '0000-00-00' ? null : product.end_date,
                product_name: productDescription,
                session_id: getProductSessionId(product, session),
                track_id: getProductTrackId(product, track),
                eventid: event ? event.id : null,
                eventname: event ? event.name : null,
                promo: promo?.id,
                confirmation: null,
                price: finalPricing.final_price,
            }
            //payloadObj = btoa(JSON.stringify(payloadObj))
        }
        await postService(
                API.postAccountInfoURL,
            {userPayload: btoa(JSON.stringify(payloadObj))}
        ).then((data) => {
            if (data.isAuthenticated) {
                if (!user) {
                    setAuthentication(data.token);
                }
                setProductAccess(data.access);
                // will broadcast that access should be updated across all windows
                window.localStorage[productAccessStorageIDRef.current] = 'true';
                paymentResponse = {
                    status: 'succeeded',
                    id: data.confirmation,
                    amount: finalPricing.final_price,
                    confirmation: data.confirmation,
                }
            } else {
                paymentResponse = {
                    status: 'failed',
                    id: null,
                    amount: null,
                    confirmation: null,
                }
            }
        });
        setProduct(null);
        setPromo(null);
        setRegClientSecret('');
        setPaymentIntentID(null);
        return paymentResponse;
    }

    const submitPayment = async (stripe, elements) => {
        const payResponse = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
            confirmParams: {
                receipt_email: userObj?.email,
            },
        });
        const productDescription = cleanProductDescription({
            ...product,
            event: event,
            session: session,
        })
        if (!payResponse.error) {
            // needs to extend the payload if a product is also being added
            let payloadObj = {
                ...userObj,
                custom_questions: convertCustomQuestionstoArray(customQuestionAnswers),
            };
            if (product) {
                //payloadObj = JSON.parse(atob(payloadObj));
                payloadObj = {
                    ...payloadObj,
                    product_type_id: product.product_id,
                    product_id: product.id,
                    start_date: product.start_date === '0000-00-00' ? null : product.start_date,
                    end_date: product.end_date === '0000-00-00' ? null : product.end_date,
                    eventid: event ? event.id : null,
                    eventname: event ? event.name : null,
                    session_id: getProductSessionId(product, session),
                    track_id: getProductTrackId(product, track),
                    product_name: productDescription,
                    promo: promo?.id,
                    confirmation: payResponse.paymentIntent.id,
                    price: payResponse.paymentIntent.amount/100,
                }
            }

            await postService(
                API.postAccountInfoURL,
                {userPayload: btoa(JSON.stringify(payloadObj))}
            ).then((data) => {
                if (data.isAuthenticated) {
                    if (!user) {
                        setAuthentication(data.token);
                    }
                    setProductAccess(data.access);
                    // will broadcast that access should be updated across all windows
                    console.log('will broadcast');
                    window.localStorage[productAccessStorageIDRef.current] = 'true';
                }
            });
            setProduct(null);
            setPromo(null);
            setRegClientSecret('');
            setPaymentIntentID(null);
        }
        return payResponse;
    }

    const convertCustomQuestionstoArray = (answerObj) => {
        return Object.keys(answerObj).map((answerKey) => {
            return {
                id: answerKey*1,
                answer: answerObj[answerKey],
            };
        });
    }
    // console.log(customQuestionAnswers, convertCustomQuestionstoArray(customQuestionAnswers));

    const getCountries = async () => {
        if (countries.length) {
            return countries;
        }
        const headers = new Headers();
        headers.append("X-CSCAPI-KEY", API.geoKEY);

        const requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        };

        fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
            .then(response => response.json())
            .then(result => {
                setCountries(_.sortBy(result, ({name}) => name === 'United States' ? 0 : 1));
            })
            .catch(error => console.log('error', error));
    }

    const getStatesByCountry = async (countryISO) => {
        const headers = new Headers();
        headers.append("X-CSCAPI-KEY", API.geoKEY);

        const requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        };

        return fetch(`https://api.countrystatecity.in/v1/countries/${countryISO}/states`, requestOptions)
            .then(response => response.json())
            .then(result => {
                return result;
            })
            .catch(error => console.log('error', error));
    }

    const registrationValues = {
        initialize,
        regClientSecret,
        setPromo,
        promo,
        setProduct,
        product,
        setUserObj,
        userObj,
        confirmation,
        validatePromo,
        submitPayment,
        completeRegistration,
        setBillingInfo,
        setIgnorePurchasing,
        ignorePurchasing,
        countries,
        getStatesByCountry,
        setCustomQuestionAnswers,
        customQuestionAnswers,
    };

    return (<RegistrationContext.Provider value={registrationValues}>
        {props.children}
    </RegistrationContext.Provider>);
};
