import React, {useState, useEffect, useRef, useContext} from 'react';
import {AuthenticationContext} from "./AuthenticationContext";
import {UserEventContext} from "./UserEventContext";
import { API } from '../controllers/API';
import { Subject } from 'rxjs';
import {useAPI} from "./useAPI";
import {EventContext} from "./EventContext";
var moment = require('moment-timezone');

export const AuthenticationEvents = {
    Concurrent: 'ConcurrentUser',
    SessionExpired: 'SessionExpired',
    SessionAboutToExpire: 'SessionAboutToExpire',
    Logout: 'Logout',
    Login: 'Login',
}

export const ProductType = {
    Event: 'Event',
    Session: 'Session',
    Track: 'Track',
};

export const AuthenticationContextProvider = (props) => {

    const [ user, setUser ] = useState(null);
    const [ productAccess, setProductAccess ] = useState(null);
    const [ pendingProductAccess, setPendingProductAccess ] = useState(null);
    const [ isAuthenticated, setIsAuthenticated ] = useState(null);
    const loginEvent$Ref = useRef(new Subject(null));
    const launchRegistration$Ref = useRef(new Subject(null));
    const userRequestComplete$Ref = useRef(new Subject(false));
    const userRef = useRef(null);
    const lastTokenRef = useRef(null);
    const { logEvent } = useContext(UserEventContext);
    const { event, eventReady$ } = useContext(EventContext);
    const eventRef = useRef(null);
    const SESSION_TIME_TO_LIVE = 3 * 24 * 60 * 60 * 1000; // 3 days
    const SESSION_ABOUT_TO_EXPIRE_TIME = 60 * 1000; // 60 seconds
    const VALIDATE_SESSION_TIMER = 10000;
    const LOGIN_TEST_TOKEN_TIMER = 1000;
    const {
        postService
    } = useAPI();
    const userStorageIDRef = useRef(`animatic_key${event?.id}`);
    const productAccessStorageIDRef = useRef(`animatic_product_access_key${event?.id}`);

    const testLoginInterval = useRef(null);
    const testLoginTokenInterval = useRef(null);
    const productAccessRef = useRef(productAccess);
    const pendingProductAccessRef = useRef(pendingProductAccess);

    useEffect(() => {
        /**
         * fired when the event object is resolved based on url and broadcast throught the app
         */
        const authenticateSubscription = eventReady$.subscribe(async (eventObj) => {
            userStorageIDRef.current = `animatic_key${eventObj?.id}`;
            productAccessStorageIDRef.current = `animatic_product_access_key${eventObj?.id}`;
            const userInfo = getUserInfo();
            setUser(userInfo);
            userRef.current = userInfo;
            eventRef.current = eventObj;
            setIsAuthenticated(!!userInfo);
            await testAuthentication();
            if (!userInfo) { userRequestComplete$Ref.current.next(true); }
        });

        /**
         * periodic test to see if anyone else on the network has logged in as the same user, if so, it will
         * immediately kick the user out.
         * @type {number}
         */
        testLoginInterval.current = setInterval(() => testAuthentication(), VALIDATE_SESSION_TIMER);
        testLoginTokenInterval.current = setInterval(() => testValidToken(), LOGIN_TEST_TOKEN_TIMER);
        return () => {
            authenticateSubscription.unsubscribe();
            clearInterval(testLoginInterval.current);
            clearInterval(testLoginTokenInterval.current);
        }
    }, []);

    useEffect(() => {
        userRef.current = user;
        userRequestComplete$Ref.current.next(user);
    }, [user]);

    useEffect(() => {
        productAccessRef.current = productAccess;
    }, [productAccess]);

    const getProductAccessByEvent = async (event_id, user_id) => {
        return postService(
            API.getUserProductAccess,
            {
                event_id: event_id,
                user_id: user_id,
            }
        )
            .then((response) => {
                setProductAccess(response.access);
                productAccessRef.current = response.access;
                setPendingProductAccess(response.pending);
                pendingProductAccessRef.current = response.pending;
            });
    }

    const login = async ({
        email = '',
        password = '',
        force = false,
        eventid = null,
        sessionid = null,
    }) => {
        // console.log('login:', email+" "+password+" "+eventid+" "+force);
        return postService(
            API.loginAPI,
            {
                sessionTest: btoa(email+" "+password+" "+eventid+" "+force)
            }
        )
            .then((response) => {
                if (response?.isAuthenticated) {
                    if (!response.externally_logged || force) {
                        setAuthentication(response.token);
                        setProductAccess(response.access);
                        productAccessRef.current = response.access;
                        logEvent(
                            getUserInfo() ? getUserInfo().userid : null,
                            eventid,
                            sessionid,
                            "login",
                            "login"
                        );
                    } else {
                        setUserInfo(response.token);
                    }
                } else {
                    destroySession();
                }
                return response;
            });
    }

    const reAuthenticateUser = () => {
        return postService(
            API.reauthenticateAPI,
            {
                sessionTest: btoa(userRef.current.userid+" "+userRef.current.session)
            }
        );
    }

    const logout = async ({
        eventId,
        sessionId,
    }) => {
        await postService(
            API.logoutAPI,
            {
                user_id: userRef.current?.userid
            }
        ).then(() => {
            logEvent(
                getUserInfo() ? getUserInfo().userid : null,
                eventId,
                sessionId,
                "login",
                "logout"
            );
            destroySession();
        });
    }

    const destroySession = (cb) => {
        //window.localStorage[userStorageIDRef.current] = "";
        window.localStorage.removeItem(userStorageIDRef.current);
        setIsAuthenticated(false);
        setUser(null);
        setProductAccess(null);
        loginEvent$Ref.current.next({
            action: AuthenticationEvents.Logout,
            user: null,
        });
        userRef.current = null;
        if(cb) {
            cb();
        }
    };

    const testAuthentication = async () => {
        if (userRef.current) {
            postService(
                API.sessionVerification,
                {
                    sessionTest: btoa(userRef.current.userid+" "+userRef.current.session)
                }
            )
                .then(response => {
                    if (response) {
                        if (!response?.isAuthenticated) {
                            destroySession();
                            loginEvent$Ref.current.next({
                                action: AuthenticationEvents.Concurrent,
                                user: null
                            });
                        } else {
                            /**
                             * testing if the session is expired based on the last login date.
                             */
                            const now = moment();
                            const lastLogin = moment(response.lastLoginDate);
                            if (now.diff(lastLogin) > SESSION_TIME_TO_LIVE) {
                                logout();
                                loginEvent$Ref.current.next({
                                    action: AuthenticationEvents.SessionExpired,
                                    user: null,
                                });
                            } else if ((now.diff(lastLogin)) > (SESSION_TIME_TO_LIVE - SESSION_ABOUT_TO_EXPIRE_TIME)) {
                                loginEvent$Ref.current.next({
                                    action: AuthenticationEvents.SessionAboutToExpire,
                                    user: userRef.current,
                                });
                            }
                        }
                    }
                });
        }
        if (lastTokenRef.current !== window.localStorage[userStorageIDRef.current]) {
            lastTokenRef.current = window.localStorage[userStorageIDRef.current];
            setUserInfo(window.localStorage[userStorageIDRef.current]);
            setIsAuthenticated(window.localStorage[userStorageIDRef.current] ? !!window.localStorage[userStorageIDRef.current] : false);
        }
        return window.localStorage[userStorageIDRef.current];
    };

    const testValidToken = () => {
        if (userRef.current && !window.localStorage[userStorageIDRef.current]) {
            destroySession();
        } else if (!userRef.current && window.localStorage[userStorageIDRef.current]) {
            setIsAuthenticated(window.localStorage[userStorageIDRef.current] ? !!window.localStorage[userStorageIDRef.current] : false);
            setAuthentication(window.localStorage[userStorageIDRef.current]);
        }
        /**
         * when a user purchases access to a product on another window in the same browser, this will trigger a refresh
         * of the user's product access, so all windows stay in sync.
         */
        if (window.localStorage[productAccessStorageIDRef.current] === 'true') {
            getProductAccessByEvent(eventRef.current.id, userRef.current.userid).then(() => {
                window.localStorage[productAccessStorageIDRef.current] = 'false';
            });
        }
    };

    const getCurrentUTCDateTime = () => {
        const date = new Date();
        const now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
            date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        return new Date(now_utc);
    }

    const getUserInfo = () => {
        var JWT = window.localStorage[userStorageIDRef.current] ? window.localStorage[userStorageIDRef.current].split(".") : null;
        return JWT && JWT[1] ? JSON.parse(atob(JWT[1])) : null;
    };

    const setUserInfo = (token) => {
        if (token && token !== 'undefined') {
            window.localStorage[userStorageIDRef.current] = token;
            setUser(getUserInfo());
            userRef.current = getUserInfo();
        }
    }

    const setAuthentication = (token) => {
        setUserInfo(token);
        loginEvent$Ref.current.next({
            action: AuthenticationEvents.Login,
            user: getUserInfo(),
        });
        setIsAuthenticated(!!token);
    };

    const getUserName = () => {
        return `${user?.firstname} ${user?.lastname}`;
    }

    const resolveSessionAccess = (session) => {
        const accessObj = productAccess ?? productAccessRef.current;
        if (accessObj) {
            if (accessObj.event) {
                return true;
            } else return !!accessObj.sessions.includes(session.id) || !!accessObj.tracks.includes(session.trackid);
        } else {
            return false;
        }
    }

    const resolvePendingSessionAccess = (session) => {
        const pendingAccessObj = pendingProductAccess ?? pendingProductAccessRef.current;
        if (pendingAccessObj) {
            let type = null;
            let start = null;
            let isPending = false;
            if (pendingAccessObj.event) {
                type = ProductType.Event;
                start = pendingAccessObj.event;
                isPending = true;
            } else if (pendingAccessObj.sessions[session.id + '']) {
                type = ProductType.Session;
                start = pendingAccessObj.sessions[session.id + ''];
                isPending = true;
            } else if (pendingAccessObj.tracks[session.trackid + '']) {
                type = ProductType.Track;
                start = pendingAccessObj.tracks[session.trackid + ''];
                isPending = true;
            }
            return {
                pending: !!isPending,
                type: type,
                date: start,
            };
        } else {
            return {
                pending: false,
                type: null,
                date: null,
            };
        }
    }

    const validateEmail = (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    const validatePhone = (phone) => {
        if(!isNaN(phone) && phone.length >= 10 && phone.length <= 11) {
            return true;
        }
        var re = /^(\([0-9]{3}\)\s*|[0-9]{3}\-)[0-9]{3}-[0-9]{4}$/;
        return re.test(phone);
    };

    const authenticationValues = {
        user,
        loginEvent$: loginEvent$Ref.current,
        launchRegistration$: launchRegistration$Ref.current,
        userRequestComplete$: userRequestComplete$Ref.current,
        isAuthenticated,
        setAuthentication,
        login,
        logout,
        getUserName,
        destroySession,
        productAccess,
        pendingProductAccess,
        resolveSessionAccess,
        resolvePendingSessionAccess,
        getProductAccessByEvent,
        reAuthenticateUser,
        setProductAccess,
        getUserInfo,
        validateEmail,
        validatePhone,
        productAccessStorageIDRef,
    };

    return (<AuthenticationContext.Provider value={authenticationValues}>
        {props.children}
    </AuthenticationContext.Provider>);
};
