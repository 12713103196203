import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EventContext } from '../contexts/EventContext';
import { pageResolver } from "./pageResolver";
import './TradeShow.scss';
import {AuthenticationContext} from "../contexts/AuthenticationContext";
import {LoginForm} from "../components/LoginForm";
import {useAPI} from "../contexts/useAPI";
import {API, API_SERVER} from "./API";
import {AllExhibitors} from "../components/TradeShow/AllExhibitors";

export function TradeShow() {
    let paths = useParams();
    const {
        postService
    } = useAPI();

    const { isAuthenticated, user } = useContext(AuthenticationContext);
    const { setEventRoute, event, launchRegistration$ } = useContext(EventContext);
    const [ assetPath, setAssetPath ] = useState('');
    const [ isLoading, setIsLoading ] = useState(true);
    const [ exhibits, setExhibits ] = useState([]);
    const [ hasPremiere, setHasPremiere ] = useState(false);

    useEffect(() => {
        setEventRoute([paths.client, paths.eventpath]);
    }, []);

    useEffect(() => {
        if (event && exhibits.length === 0) {
            let getTradeShowPayload = {
                path: `${paths.client}/${paths.eventpath}/`,
                userid: user?.userid ?? null,
            };
            postService(
                API.tradeShowAPI,
                getTradeShowPayload,
            )
                .then(data => {
                    let premiereCount = 0;
                    data.exhibits.map((exhibit) => {
                        if (exhibit.featured) {
                            premiereCount++;
                        }
                    });
                    setExhibits(data.exhibits);
                    setHasPremiere(!!premiereCount);
                    setAssetPath(`${API_SERVER}/presentation_media${event.alias}tradeshow`);
                    setIsLoading(false);
                });
        }
    }, [event]);

    // console.log(assetPath, event);
    return (

        <article className={`TradeShow ${paths.client}${paths.eventpath}`}>
            {event && <>
            {!isAuthenticated ?
                <article className='login-insert'>
                    <img src={`${assetPath}/logo.png`} />
                    {isLoading ?
                        <div className='loading_panel'>Logging in...</div> :
                        <LoginForm
                            standalone={true}
                            eventid={event?.id}
                            hasRegistrations={event?.hasRegistrations}
                            onShowRegistration={() => {
                                launchRegistration$.next({
                                    show: true,
                                    ignorePurchasing: true,
                                });
                            }}
                        />
                    }
                </article>
                : <AllExhibitors
                        exhibits={exhibits}
                        assetPath={assetPath}
                        hasPremiere={hasPremiere}
                    />}
            </>}
        </article>
    )
}
