const COLORS = {
    blue: '#007aba',
    blue75: '#156da4',
    blue50: '#5d9aba',
    blue10: '#E5F1F8',
    yellow: '#fcb500',
    orange: '#ff8000',
    red: '#ED1C24',
    green: '#00CC00',
    darkGreen: '#009200',
    lightGreen: '#55B033',
    dimmedGreen: '#4c5a4c',
    darkBlue: '#333E46',
    extraDarkBlue: '#1e252a',
    darkBlue40: '#acb1b5',
    gray02: '#e1e1e1',
    gray10: '#dcdcdc',
    gray25: '#DBD9D6',
    gray50: '#BDBDBD',
    gray75: '#4c4c4c',
    gray80: '#2e2e2e',
    headerGray: '#4c4c4c',
    panelBackground: '#ffffff',
    iconGray: '#80868b',
    black: '#000000',
    inputBackground: '#f5f5f5'
};

const BASEUNIT = 8;

export const colorList = [
    COLORS.blue,
    COLORS.yellow,
    COLORS.red
];

export const styles = {
    xlarge: '(min-width: 1080px)',   // greater than 1080px
    large:  '(max-width: 1079px)',   // smaller than 1080px
    small:  '(max-width: 839px)',    // smaller than 840px
    xsmall: '(max-width: 600px)',    // smaller than 600px
    mediumHeight: '(max-height: 640px)',    // height less than 640px
    smallHeight:  '(max-height: 480px)',    // height less than 480px

    minPageWidth: 360,
    minPageHeight: 500,
    baseMargin: BASEUNIT,
    basePadding: BASEUNIT,
    baseFontSize: 1.4,
    maxTrailerHeight : 600,
    appBarHeight: BASEUNIT*8,
    breadcrumbHeight: BASEUNIT*4,
    windowResizeThreshold: 1260,

    dimmedBorder: '1px solid rgba(128, 128, 128, .2)',

    /* base application icon and header colors */
    primaryTextColor: 'rgb(30,30,30)',
    secondaryTextColor: '#5C5C5C',
    white: '#FFFFFF',
    orange: '#FF8000',
    redorange: '#E65100',
    lightBack: '#DBD9D6',
    texthover: COLORS.blue,
    primaryColor: COLORS.blue,
    primaryIconColor: COLORS.blue,
    grayIconColor: '#8e8e8e',
    secondaryIconColor: '#919191',
    grayBackground: '#DBD9D6',
    lightBackground: '#DCDCDC',
    appBorder: '#919191',
    highlightRow: '#e5f1f7',
    chromeColor: '#333E47',
    controlColor: '#279ed5',
    alertColor: '#D0021B',
    lightRed: '#DB8D86',
    darkText: '#222A30',
    goodGreen: '#00B400',
    lightGreen: '#7acb7d',
    activeIconBackground: COLORS.gray75,
    inactiveIconBackground: COLORS.gray75,
    modalBackground: COLORS.gray10,
    inputBackground: COLORS.inputBackground,

    tabBackground: 'white',
    tabBackgroundInactive: '#eaebec',

    /* Pixi Canvas Colors */
    canvas: {
        text: COLORS.panelBackground,
        nextROIDefault: '#333333',
        pass: COLORS.green,
        fail: '#cc2900',
        live: COLORS.blue,
        blueBorder: COLORS.blue,
        orangeBorder: COLORS.orange,
        gray: '#555555',
        baseROIColor: '#00ff00',
        searchROIColor: '#800080',
        edgeContourLine: COLORS.blue50,
        edgeLine: COLORS.blue,
        pixelCount: COLORS.yellow,
    },

    // Colors for the draw polygon feature of pattern match
    drawPolygon: {
        pathPoint: '#00B400',
        connectablePoint: '#00B400',
        pathSegment: '#7acb7d',
        willComplete: '#b300b3',
        pointer: {
            valid:  '#00B400',
            invalid: '#DB8D86'
        },
        connectingSegment: {
            valid: '#00B400',
            invalid: '#DB8D86',
        },
        circuit: {
            point: '#00ff00',
            segment: '#00ff00',
        }
    },

    text: {
        dark: COLORS.darkBlue,
        light: COLORS.blue
    },

    success: {
        pass: COLORS.green,
        fail: COLORS.red,
    },

    blue: COLORS.blue,
    blue75: COLORS.blue75,
    blue50: COLORS.blue50,
    blue10: COLORS.blue10,
    yellow: COLORS.yellow,
    red: COLORS.red,
    green: COLORS.green,
    darkGreen: COLORS.darkGreen,
    dimmedGreen: COLORS.dimmedGreen,
    darkBlue: COLORS.darkBlue,
    extraDarkBlue: COLORS.extraDarkBlue,
    darkBlue40: COLORS.darkBlue40,
    gray02: COLORS.gray02,
    gray25: COLORS.gray25,
    gray50: COLORS.gray50,
    gray75: COLORS.gray75,
    gray80: COLORS.gray80,
    headerGray: COLORS.headerGray,
    panelBackground: COLORS.panelBackground,
    iconGray: COLORS.iconGray,
    black: COLORS.black,
    borderColor: '#c1c3c5',

    shadow: {
        primaryShadow: '0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)',
    },

    //Font Styles
    fontFamily: 'proximanovacond-regular',
    buttonFontSize: 16,
    titleLetterSpacing : -0.18,
    //Button Styles
    buttonHeight: 32,
    //Header Styles
    headerColor: '#333333',

    histogramMargin: `${BASEUNIT * 2}px ${BASEUNIT}px ${BASEUNIT * 3}px ${BASEUNIT * 2}px`,
};
