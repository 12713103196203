import React from "react";
import './Loading.scss';

export function Loading() {

    return (
        <div className='Loading'>
            <div>Loading....</div>
        </div>
    )
}