import React, { useContext, useRef, useState, useEffect } from "react";
import { API } from '../../controllers/API';
import {AuthenticationContext} from "../../contexts/AuthenticationContext";
import {UserEventContext} from "../../contexts/UserEventContext";
import {MP4Video} from "./MP4Video";
import {getMediaURL} from "./getMediaURL";

export const VideoPanel = (props) => {
    const {
        media = [],
        event_path = '',
        session = {},
        event = null,
        width = 720,
        height = 480,
        onVideoEnd = () => false,
    } = props;

    const videoPanelRef = useRef(null);
    const [videoResolution, setVideoResolution] = useState({
        width: width,
        height: height,
    });
    const startedRef = useRef(false);
    const videoRatio = .666666667;

    const { logEvent } = useContext(UserEventContext);
    const { user } = useContext(AuthenticationContext);

    useEffect(() => {
        let sessionMP4 = document.getElementById("sessionMP4");
        // console.log(sessionMP4);
        if (sessionMP4) {
            sessionMP4.onplay = () => {
                if (!startedRef.current) {
                    videoLogEvent("video", "play");
                }
                startedRef.current = true;
            };

            sessionMP4.onpause = (e) => {
                //videoLogEvent("video", "pause");
                //startedRef.current = false;
            };

            sessionMP4.onseeking = (e) => {
                e.stopImmediatePropagation();
                e.preventDefault();
                let timeat = parseInt(sessionMP4.currentTime);
                videoLogEvent("video", "seek");
            };
            sessionMP4.onended = () => {
                onVideoEnd();
                videoLogEvent("video", "finish");
            };

            window.onbeforeunload = () => {
                let timeat = parseInt(sessionMP4.currentTime);
                let fulltime = parseInt(sessionMP4.duration);
                if ((parseInt((timeat * 100) / fulltime)) < 100 || sessionMP4.ended) {
                    return null;
                }
                return "Are you sure you want to navigate away from this page before the video is completed?";
            };
            const panelWidth = videoPanelRef.current.clientWidth;
            setVideoResolution({
                width: panelWidth + 'px',
                height: Math.floor(panelWidth * videoRatio) + 'px',
            });
            return () => {
                let sessionMP4 = document.getElementById("sessionMP4");
                if (sessionMP4) {
                    let timeat = parseInt(sessionMP4.currentTime);
                    let fulltime = parseInt(sessionMP4.duration);
                    let percwatched = "viewed (" + parseInt((timeat * 100) / fulltime) + "%)";
                    videoLogEvent("video", percwatched);
                    if ((parseInt((timeat * 100) / fulltime)) < 100) {
                        // TODO: show this error: "Are you sure you want to navigate away from this page before the video is completed?"
                    }
                }
            }
        }
    }, []);

    const videoLogEvent = (type, action) => {
        logEvent(
            user.userid,
            event.id,
            session.id,
            type,
            action
        );
    }

    const mediaObjs = media.map((m, i) => {
        return (
            m === "flvAS" || m === "flvVS" || m === "flvV" ?
                <MP4Video
                    width={videoResolution.width}
                    height={videoResolution.height}
                    file={getMediaURL(m,event_path,session.sessionid)}
                    key={`mp4video_${i}`}
                /> : null
        );
    });

    return (
        <div ref={videoPanelRef} className="VideoPanel">
            {mediaObjs.length ? mediaObjs : ''}
        </div>
    )
};