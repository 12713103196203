import React from 'react'
var moment = require('moment-timezone');

export const ProductType = {
    Session: 1,
    Track: 2,
    Conference: 3,
    Event: 4,
    LiveSession: 5,
}
const PRODUCTS = {
    1: {
        type: 'session'
    },
    2: {
        type: 'track'
    },
    3: {
        type: 'event'
    },
    4: {
        type: 'event'
    },
    5: {
        type: 'livesession'
    }
};

/*
Expires on xx/xx/xxxx (specific date)
Expires xxx days from xx/xx/xxxx
Expires xxx days from Purchase date
Set access date RANGES. This would be a uniform option for all purchase of that same product chosen. It should specify a Access START Date and Access END date range. So those people would ONLY have access on those dates.
No expiration date (never expires)
 */
export const ExpirationType = {
    DaysFromPurchase: 'daysFromPurchase',
    DaysFromDate: 'daysFromDate',
    ExpiresOnDate: 'expiresOnDate',
    StartAndEndDate: 'startAndEndDate',
    NoExpiration: 'noExpiration',
};

export const resolveExpirationType = (m, prefix = 'access') => {
    const datFormat = "M/D/YYYY";
    if (!m.expiration_days && m.start_date !== '0000-00-00' && m.end_date !== '0000-00-00')   {
        return {
            type: ExpirationType.StartAndEndDate,
            description: `${prefix} from ${moment(m.start_date).format(datFormat)} to ${moment(m.end_date).format(datFormat)}`,
        };
    } else if (m.expiration_days && m.start_date === '0000-00-00' && m.end_date !== '0000-00-00') {
        return {
            type: ExpirationType.DaysFromDate,
            description: `${prefix} for ${m.expiration_days} day${parseInt(m.expiration_days) === 1 ? '' : 's'} from ${moment(m.end_date).format(datFormat)}`,
        };
    } else if (!m.expiration_days && m.start_date === '0000-00-00' && m.end_date === '0000-00-00') {
        return {
            type: ExpirationType.NoExpiration,
            description: `(open access)`,
        };
    } else if (m.expiration_days && m.start_date === '0000-00-00' && m.end_date === '0000-00-00') {
        return {
            type: ExpirationType.DaysFromPurchase,
            description: `${prefix} for ${m.expiration_days} day${parseInt(m.expiration_days) === 1 ? '' : 's'}`,
        };
    } else if (!m.expiration_days && m.start_date === '0000-00-00' && m.end_date !== '0000-00-00') {
        return {
            type: ExpirationType.ExpiresOnDate,
            description: `${prefix} until ${moment(m.end_date).format(datFormat)}`,
        };
    } else if (m.expiration_days && m.start_date !== '0000-00-00' && m.end_date === '0000-00-00') {
        return {
            type: ExpirationType.DaysFromDate,
            description: `${prefix} for ${m.expiration_days} day${parseInt(m.expiration_days) === 1 ? '' : 's'} from ${moment(m.start_date).format(datFormat)}`,
        };
    } else {
        return {
            type: ExpirationType.NoExpiration,
            description: '(open access)',
        };
    }
}

export function enrichedProductDescription(obj) {
    let text = '';
    const productTypeDescription = resolveExpirationType(obj);
    if(PRODUCTS[obj.product_id].type === 'session') {
        text = <p>Grant access to this single session, <strong>{obj.session?.sessiontitle ? atob(obj.session.sessiontitle) : '--'}</strong>, {productTypeDescription.description}.</p>;
    }
    if(PRODUCTS[obj.product_id].type === 'track') {
        text = <p>Grant access to all sessions within this track, <strong>{obj.track?.title ? obj.track?.title : obj.description}</strong>, {productTypeDescription.description}.</p>;
    }
    if(PRODUCTS[obj.product_id].type === 'event') {
        text = <p>Grant access to all sessions from this event, <strong>{obj.event?.name}</strong>, {productTypeDescription.description}.</p>;
    }
    if(PRODUCTS[obj.product_id].type === 'livesession') {
        text = <p>Grant access to the upcoming live broadcast of this session, <strong>{obj.session?.sessiontitle ? atob(obj.session.sessiontitle) : ''}</strong>.</p>;
    }
    return text;
}

export function cleanProductDescription(obj) {
    console.log(obj, resolveExpirationType(obj));
    const expirationDescription = resolveExpirationType(obj, '')?.description ?? '';
    let text = "";
    if(PRODUCTS[obj.product_id].type === 'session') {
        text = `access to the session, ${obj.session?.sessiontitle ? atob(obj.session.sessiontitle) : '--'} ${expirationDescription}`;
    }
    if(PRODUCTS[obj.product_id].type === 'track') {
        text = `access to all sessions within the track, ${obj.track?.title ? obj.track?.title : obj.description} ${expirationDescription}`;
    }
    if(PRODUCTS[obj.product_id].type === 'event') {
        text = `access to all sessions from this event, ${obj.event?.name} ${expirationDescription}`;
    }
    if(PRODUCTS[obj.product_id].type === 'livesession') {
        text = `access to the upcoming live broadcast of this session, ${obj.session?.sessiontitle ? atob(obj.session.sessiontitle) : ''}`;
    }
    return text;
}

const PROMOMAP = {
    1 : (price, discount) => { return [price - (price*(discount/100)),price*(discount/100)]; },
    2 : (price, discount) => { return [(price - discount), discount]; },
    3 : (price, discount) => { return [discount, price-discount]; },
    4 : (price, discount) => { return [discount, price-discount]; },
    5 : (price, discount) => { return [discount, price-discount]; },
    6 : (price, discount) => { return [0, price]; },
    7 : (price, discount) => { return [0, price]; },
    8 : (price, discount) => { return [0, price]; }
};

export function calculateTotalPrice(product, promo) {
    if (!product) {
        return {final_price: 0, discount: 0};
    }
    let final = parseFloat(Number(product.cost).toFixed(2));
    let promo_valuation = promo ? promo.valuation : 0;
    let discountResolver = [final,0];
    if(promo && promo.name) {
        discountResolver = PROMOMAP[promo.promo_type_id](final, promo_valuation);
    }
    return {final_price: discountResolver[0], discount: discountResolver[1]};
}
