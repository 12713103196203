import React, { useContext, useEffect, useState, useRef } from "react";
import { EventContext } from '../contexts/EventContext';
import {TextBox, Button, CheckBox, Fieldset} from "zebra-stripes/Forms";
import {Dialog} from 'zebra-stripes/Layouts'
import {Icon} from "zebra-stripes/Symbols/Icon";
import {API} from "../controllers/API";
import {AuthenticationContext} from "../contexts/AuthenticationContext";
import * as _ from 'lodash';
import './UserAccountForm.scss';
import {TermsAndConditionsContent} from "./TermsAndConditionsContent";
import {useAPI} from "../contexts/useAPI";
import {DEFAULT_REQUIRED_FIELDS} from "../contexts/Event";
import {RegistrationContext} from "../contexts/RegistrationContext";

const blackListStates = [
    'Baker Island',
    'Howland Island',
    'Johnston Atoll',
    'Jarvis Island',
    'Kingman Reef',
    'Midway Atoll',
    'Navassa Island',
    'Northern Mariana Islands',
    'Palmyra Atoll',
    'Wake Island',
];

export function UserAccountForm(props) {
    const {
        showLogin = true,
        onLogin = () => false,
        onShowLogin = () => false,
        onCancel = () => false,
        onComplete = null,
        onSubmit = null,
        ignorePurchasing = false,
    } = props;

    const {
        postService
    } = useAPI();

    const { userObj, countries, getStatesByCountry } = useContext(RegistrationContext);
    const userState = userObj;

    const termsDialogRef = useRef(null);
    const [address, setAddress] = useState(userState?.address || '');
    const [city, setCity] = useState(userState?.city || '');
    const [company, setCompany] = useState(userState?.company || '');
    const [email, setEmail] = useState(userState?.email || '');
    const [confirmEmail, setConfirmEmail] = useState(userState?.confirm_email || '');
    const [password1, setPassword1] = useState(userState?.password ? atob(userState?.password) : '');
    const [password2, setPassword2] = useState(userState?.password ? atob(userState?.password) : '');
    const [firstname, setFirstname] = useState(userState?.fname || '');
    const [lastname, setLastname] = useState(userState?.lname || '');
    const [mphone, setMphone] = useState(userState?.mphone || '');
    const [wphone, setWphone] = useState(userState?.wphone || '');
    const [postalcode, setPostalcode] = useState(userState?.postalcode || '');
    const [state, setState] = useState(userState?.state || '');
    const [country, setCountry] = useState(userState?.country || 'United States');
    const [title, setTitle] = useState(userState?.title || '');

    /*const [address, setAddress] = useState(userState?.address || '123 Myaddress St');
    const [city, setCity] = useState(userState?.city || 'City');
    const [company, setCompany] = useState(userState?.company || 'Company');
    const [email, setEmail] = useState(userState?.email || 'test110@conferenceondemand.net');
    const [confirmEmail, setConfirmEmail] = useState(userState?.confirm_email || 'test110@conferenceondemand.net');
    const [password1, setPassword1] = useState('!!Test123');
    const [password2, setPassword2] = useState('!!Test123');
    const [firstname, setFirstname] = useState(userState?.fname || 'Firstname');
    const [lastname, setLastname] = useState(userState?.lname || 'Lastname');
    const [mphone, setMphone] = useState(userState?.mphone || '555-555-5555');
    const [wphone, setWphone] = useState(userState?.wphone || '555-555-5555');
    const [postalcode, setPostalcode] = useState(userState?.postalcode || '12345');
    const [state, setState] = useState(userState?.state || 'New York');
    const [country, setCountry] = useState(userState?.country || 'United States');
    const [title, setTitle] = useState(userState?.title || 'tester');*/

    const [addressError, setAddressError] = useState(null);
    const [cityError, setCityError] = useState(null);
    const [companyError, setCompanyError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [confirmEmailError, setConfirmEmailError] = useState(null);
    const [firstnameError, setFirstnameError] = useState(null);
    const [lastnameError, setLastnameError] = useState(null);
    const [mPhoneError, setMPhoneError] = useState(null);
    const [wPhoneError, setWPhoneError] = useState(null);
    const [postalcodeError, setPostalcodeError] = useState(null);
    const [stateError, setStateError] = useState(null);
    const [titleError, setTitleError] = useState(null);
    const [countryError, setCountryError] = useState(null);
    const [password1Error, setPassword1Error] = useState(null);
    const [password2Error, setPassword2Error] = useState(null);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [emailExists, setEmailExists] = useState(null);
    const [termsChecked, setTermsChecked] = useState(userState?.termsChecked || false);
    const [termsError, setTermsError] = useState(null);
    const [hasErrors, setHasErrors] = useState(false);
    const [stateItems, setStateItems] = useState([]);

    const { setEventRoute, event, eventRoute } = useContext(EventContext);
    const { user, setAuthentication, validateEmail } = useContext(AuthenticationContext);

    useEffect(() => {
        if (user) {
            setAddress(user.address);
            setCity(user.city);
            setState(user.state);
            setTitle(user.title);
            setCompany(user.company);
            setEmail(user.email);
            setFirstname(user.firstname);
            setLastname(user.lastname);
            setMphone(user.mphone);
            setWphone(user.wphone);
            setPostalcode(user.postalcode);
            setCountry(user.country);
        }
    }, [user, event]);

    useEffect(() => {
        if (countries.length > 0) {
            handleCountryChange(country);
        }
    }, [countries]);

    const testEmail = (emailTest) => {
        setEmail(emailTest);
        if (!!emailTest && validateEmail(emailTest)) {
            postService(
                API.validateEmailURL,
                {
                    email: emailTest,
                    event_id: event.id,
                }
            ).then((response) => {
                setEmailExists(response?.exists);
                setEmailError(response?.exists ? "An account with this email already exists." : null);
            });
        }
    }

    const addedRegistrationFields = event?.reg_fields?.split(',') || DEFAULT_REQUIRED_FIELDS;
    const requiredFields = event?.req_fields?.split(',') || [];

    const validateForm = () => {
        //console.log(event, customFields);
        let isValid = !emailExists;

        if(!user) {
            if(email === "" || !validateEmail(email)) {
                setEmailError('Please enter a valid email.');
                isValid = false;
            } else {
                setEmailError(null);
            }
            if(confirmEmail === "" || email !== confirmEmail) {
                setConfirmEmailError('Email addresses do not match.');
                isValid = false;
            } else {
                setConfirmEmailError(null);
            }
        }
        setEmailError(emailExists ? 'An account with this email already exists.' : null);
        if(firstname === "") {
            setFirstnameError('Please enter a firstname.');
            isValid = false;
        } else {
            setFirstnameError(null);
        }
        if(lastname === "") {
            setLastnameError('Please enter a firstname.');
            isValid = false;
        } else {
            setLastnameError(null);
        }
        if(addedRegistrationFields?.includes('address') && requiredFields?.includes('address')) {
            if (address === "") {
                setAddressError('Please enter an address.');
                isValid = false;
            } else {
                setAddressError(null);
            }
        } else {
            setAddressError(null);
        }
        if(addedRegistrationFields?.includes('city') && requiredFields?.includes('city')) {
            if (city === "") {
                setCityError('Please enter a city.');
                isValid = false;
            } else {
                setCityError(null);
            }
        } else {
            setCityError(null);
        }
        if(addedRegistrationFields?.includes('state') && requiredFields?.includes('state')) {
            if (state === "" && stateItems.length > 1) {
                setStateError('Please enter a state.');
                isValid = false;
            } else {
                setStateError(null);
            }
        } else {
            setStateError(null);
        }
        if(addedRegistrationFields?.includes('postalcode') && requiredFields?.includes('postalcode')) {
            if (postalcode === "") {
                setPostalcodeError('Please enter a postal code.');
                isValid = false;
            } else {
                setPostalcodeError(null);
            }
        } else {
            setPostalcodeError(null);
        }
        if(addedRegistrationFields?.includes('company') && requiredFields?.includes('company')) {
            if (company === "") {
                setCompanyError('Please enter your company.');
                isValid = false;
            } else {
                setCompanyError(null);
            }
        } else {
            setCompanyError(null);
        }
        if(addedRegistrationFields?.includes('title') && requiredFields?.includes('title')) {
            if (title === "") {
                setTitleError('Please enter your credentials.');
                isValid = false;
            } else {
                setTitleError(null);
            }
        } else {
            setTitleError(null);
        }
        if(addedRegistrationFields?.includes('wphone') && requiredFields?.includes('wphone')) {
            if (wphone === "") {
                setWPhoneError('Please enter a work phone number.');
                isValid = false;
            } else {
                setWPhoneError(null);
            }
        } else {
            setWPhoneError(null);
        }
        if(addedRegistrationFields?.includes('mphone') && requiredFields?.includes('mphone')) {
            if (mphone === "") {
                setMPhoneError('Please enter a mobile phone number.');
                isValid = false;
            } else {
                setMPhoneError(null);
            }
        } else {
            setMPhoneError(null);
        }

        if(!user && !termsChecked) {
            setTermsError('You must accept the terms and conditions to create an account.');
            isValid = false;
        } else {
            setTermsError(null);
        }

        if(!user && password1 === '') {
            setPassword1Error('Please enter a password.');
            isValid = false;
        } else if (password1 !== '' && password1.length > 16) {
                setPassword1Error('Passwords cannot be longer than 16 characters.');
                isValid = false;
        } else if (password1 !== '' && password1.length < 8) {
            setPassword1Error('Passwords must be at least 8 characters long.');
            isValid = false;
        } else if (password1 !== '' && !testPasswordStrength(password1)) {
            setPassword1Error('Please enter a stronger password.');
            isValid = false;
        } else {
            setPassword1Error(null);
        }
        if(password1 !== password2) {
            setPassword2Error('Passwords do not match.');
            isValid = false;
        } else {
            setPassword2Error(null);
        }
        setHasErrors(!isValid);
        if (!isValid) {
            setTimeout(() => {
                const firstErrorDOM = document.getElementsByClassName('input_error')[0];
                if (firstErrorDOM) {
                    firstErrorDOM.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                }
            }, 250);
        }
        return isValid;
    }

    const testValidEmailOnChange = (v, field) => {
        const tempEmail1 = field === 'email' ? v : email;
        const tempEmail2 = field === 'confirmEmail' ? v : confirmEmail;
        if(tempEmail1 !== "" && !validateEmail(v)) {
            switch(field) {
                case 'email':
                    setEmailError('Please enter a valid email.');
                    break;
                case 'confirmEmail':
                    setConfirmEmailError('Please enter a valid email.');
                    break;
            }
        } else if (tempEmail2 !== '' && tempEmail1 !== tempEmail2) {
            setConfirmEmailError('Email addresses do not match.');
        } else if (tempEmail2 !== '' && tempEmail1 === tempEmail2) {
            setEmailError(null);
            setConfirmEmailError(null);
        } else {
            switch(field) {
                case 'email':
                    setEmailError(null);
                    break;
                case 'confirmEmail':
                    setConfirmEmailError(null);
                    break;
            }
        }
    }

    const testValidPasswordOnChange = (val, field) => {
        const tempPass1 = field === 'password1' ? val : password1;
        const tempPass2 = field === 'password2' ? val : password2;
        if (field === 'password1' && tempPass1 !== '' && !testPasswordStrength(tempPass1)) {
            setPassword1Error('Please enter a stronger password.');
        } else if (tempPass1 === '' || (field === 'password1' && testPasswordStrength(tempPass1))) {
            setPassword1Error(null);
        }
        if(tempPass1 !== tempPass2 && tempPass2 !== '') {
            setPassword2Error('Passwords do not match.');
        } else {
            setPassword2Error(null);
        }
    }

    const testPasswordStrength = (pass) => {
        return pass.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@$%^&:;,.?]).{8,16}$/);
    }

    const submitForm = () => {
        if(validateForm()) {
            let payload = {
                email: email ? email.trim() : '',
                confirm_email: confirmEmail ? confirmEmail.trim() : '',
                fname: firstname ? firstname.trim() : '',
                lname: lastname ? lastname.trim() : '',
                company: company ? company.trim() : '',
                address: address ? address.trim() : '',
                city: city ? city.trim() : '',
                state: state ? state.trim() : '',
                title: title ? title.trim() : '',
                country: country ? country.trim() : '',
                postalcode: postalcode ? postalcode.trim() : '',
                wphone: wphone ? wphone.trim() : '',
                mphone: mphone ? mphone.trim() : '',
                url: (eventRoute[0] + "/" + eventRoute[1]).toLowerCase(),
                termsChecked: user ? true : termsChecked,
                eventname: event ? event.name : null,
                userid: user ? user.userid : null,
                session: user ? user.session : null,
                eventid: event ? event.id : null,
                password:  (password1 !== "" && password1 ===password2) ? btoa(password1) : '',
            };

            const userPayload = {
                userPayload: btoa(JSON.stringify(payload))
            };

            if (onSubmit) {
                onSubmit(payload);
            } else {
                postService(
                    API.postAccountInfoURL,
                    userPayload
                ).then((data) => {
                    if (data.isAuthenticated) {
                        setAuthentication(data.token);
                        onLogin();
                    }
                    if (onComplete) {
                        onComplete();
                    }
                });
            }
        }
    }

    const handleCountryChange = async (val) => {
        setCountry(val);
        const selectedCountryObj = countries.filter((cntry) => cntry.name === val ?? 'US')[0];
        if (selectedCountryObj) {
            const states = await getStatesByCountry(selectedCountryObj.iso2);
            if (!states.map((st) => st.name).includes(state)) {
                setState('');
            }
            if (states) {
                const newArray = _.orderBy(
                    _.filter(states, (state) => !blackListStates.includes(state.name)), ['name']).map((stateItem) =>
                    <option key={`state${stateItem.id}`} value={stateItem.name}>
                        {stateItem.name}
                    </option>
                );
                newArray.unshift(<option key={`state_none`} value={''}>-- Select a State -- {requiredFields.includes('state') ? '*' : ''}</option>);
                setStateItems(newArray);
            }
        }
    }

    const countryItems = countries.map((countryItem) => {
        return <option key={`country${countryItem.id}`} value={countryItem.name}>{countryItem.name}</option>
    })

    const buttonText = ignorePurchasing && !event.custom_questions.length ? 'Complete Registration' : user ? 'Update Account' : 'Continue to Next Step';
    return (
        <div className='UserAccountForm'>
            {user && <h1>Update Account</h1>}
            {!user ?
                <p key="p0">Complete the user form below to create an account.{showLogin && <span> If you already have an account, <a href="#" onClick={() => onShowLogin()} key="a0">Login here</a></span>}.</p> : null}
            <div className="RegisterForm">
                <Fieldset title="User Information" style={{marginTop: '20px'}}>
                    <div className='email_container'>
                        {user?.userid ? <div style={{marginBottom: '20px'}}>{user.email}</div> :
                            <>
                                {/*<h4>Your Email will be used to log into your Conference on Demand account.</h4>*/}
                                <TextBox key="tb1" width="49%" tabIndex="1" className="email" id='email'
                                         value={email}
                                         error={emailError}
                                         showBorder={true}
                                         name='email'
                                         onChange={_.debounce(
                                             (e,val) => {
                                                 testValidEmailOnChange(val,'email');
                                                 testEmail(val)
                                             }, 500)}
                                         placeholder="Email Address *"
                                         supersetPlaceholder={true}
                                />
                                <TextBox key="tb2" width="50%" tabIndex="2" className="email"
                                         value={confirmEmail}
                                         error={confirmEmailError}
                                         showBorder={true}
                                         id={'email_2'}
                                         name='confirmemail'
                                         onChange={(e,val) => {
                                             testValidEmailOnChange(val,'confirmEmail');
                                             setConfirmEmail(val);
                                         }}
                                         placeholder="Confirm Email Address *"
                                         supersetPlaceholder={true}
                                />
                            </>}
                    </div>
                    <div className='name_fields'>
                        <TextBox width="49%" supersetPlaceholder={true} id='firstname' showBorder={true} tabIndex="3" name='Firstname' error={firstnameError} className="firstname" key="firstname" value={firstname} onChange={(e,val) => setFirstname(val)} placeholder="First Name *"/>
                        <TextBox width="50%" supersetPlaceholder={true} id='lastname' showBorder={true} tabIndex="4" name='Lastname' error={lastnameError} className="lastname" key="lastname" value={lastname} onChange={(e,val) => setLastname(val)} placeholder="Last Name *"/>
                    </div>
                    <div className='title_fields'>
                        {addedRegistrationFields.includes('title') ?
                            <TextBox supersetPlaceholder={true} id='title' width="49%" name='title' showBorder={true} tabIndex="5" error={titleError} className="title" key="title" value={title} onChange={(e,val) => setTitle(val)} placeholder={`Title/Credentials ${requiredFields.includes('title') ? '*' : ''}`}/> : null}
                        {addedRegistrationFields.includes('company') ?
                            <TextBox supersetPlaceholder={true} id='company' width="50%" name='company' showBorder={true} tabIndex="6" error={companyError} className="company" key="company" value={company} onChange={(e,val) => setCompany(val)} placeholder={`Company ${requiredFields.includes('company') ? '*' : ''}`}/> : null}
                    </div>
                    <div className='address_fields'>
                        {addedRegistrationFields.includes('address') ?
                            <TextBox supersetPlaceholder={true} id='address' width="39%" name='address' showBorder={true} tabIndex="7" error={addressError} className="address" key="address" value={address} onChange={(e,val) => setAddress(val)} placeholder={`Address ${requiredFields.includes('address') ? '*' : ''}`}/>: null}
                        {addedRegistrationFields.includes('city') ?
                            <TextBox supersetPlaceholder={true} width="29%" name='city' showBorder={true} tabIndex="8" error={cityError}  className="city" key="city" value={city} onChange={(e,val) => setCity(val)} placeholder={`City ${requiredFields.includes('city') ? '*' : ''}`}/>: null}
                        {addedRegistrationFields.includes('postalcode') ?
                            <TextBox supersetPlaceholder={true} width="10%" name='postalcode' showBorder={true} tabIndex="9" error={postalcodeError} className="postalcode" key="postalcode" value={postalcode} onChange={(e,val) => setPostalcode(val)} placeholder={`Postal Code ${requiredFields.includes('postalcode') ? '*' : ''}`}/>: null}
                    </div>
                    <div className='country_fields'>
                        {addedRegistrationFields.includes('country') ?
                            <div className={`native_control ${countryError ? 'error' : ''}`}>
                                <label>Select a Country {requiredFields.includes('country') ? '*' : ''}</label>
                                <select style={{width: '100%'}} name="country" value={country} onChange={(e) => { handleCountryChange(e.target.value); }} tabIndex='10'>
                                {countryItems}
                                </select>
                                {countryError && <span className="native_control_input_error">{countryError}</span>}
                            </div>: null}
                        {addedRegistrationFields.includes('state') ?
                            <div className={`native_control ${stateError ? 'error' : ''}`}>
                                <label>Select a State {requiredFields.includes('state') ? '*' : ''}</label>
                                <select style={{width: '250px'}} name="state" value={state} onChange={(e) => { setState(e.target.value); }} tabIndex='11'>
                                    {stateItems}
                                </select>
                                {stateError && <span className="native_control_input_error">{stateError}</span>}
                            </div>: null}
                    </div>
                    <div>
                        {addedRegistrationFields.includes('wphone') ?
                            <TextBox width="39%" name='phone' showBorder={true} tabIndex="12" className="wphone" key="wphone" value={wphone} error={wPhoneError} onChange={(e,val) => setWphone(val)} placeholder={`Work Phone ${requiredFields.includes('wphone') ? '*' : ''}`}/>: null}
                        {addedRegistrationFields.includes('mphone') ?
                            <TextBox width="40%" name='mobile' showBorder={true} tabIndex="13" className="mphone" key="mphone" value={mphone} error={mPhoneError} onChange={(e,val) => setMphone(val)} placeholder={`Mobile Number ${requiredFields.includes('mphone') ? '*' : ''}`}/>: null}
                    </div>
                </Fieldset>

                <Fieldset className='password_wrapper' title={user?.userid ? "Update Password" : "Create a Password"}>
                    <p className='password_security_note'>Passwords should contain at least one number, one lower and one uppercase character, at least one valid special character (!@$%^&:;,.?) and be at least 8 characters long, no longer than 16.</p>
                    {user?.userid && <p style={{marginBottom: 0}}>Fill out the password fields only if you would like to update your current password.</p>}
                    <div className='password_field_container'>
                        <div className='password_fields'>
                            <TextBox
                                tabIndex="12"
                                name='password'
                                autoComplete={'new-password'}
                                showBorder={true}
                                width="18em"
                                error={password1Error}
                                value={password1}
                                onChange={(e,val) => { setPassword1(val); testValidPasswordOnChange(val, 'password1')}}
                                className="password1"
                                inputType={showPassword1 ? 'text' : 'password'}
                                placeholder="Enter Password"
                            />
                            <div className='show_hide_password'>
                                <Icon
                                    iconid='visibility'
                                    onClick={() => setShowPassword1(!showPassword1)}
                                    size='25px' />
                            </div>
                        </div>
                        <div className='password_fields'>
                            <TextBox
                                tabIndex="13"
                                showBorder={true}
                                width="18em"
                                error={password2Error}
                                value={password2}
                                onChange={(e,val) => { setPassword2(val); testValidPasswordOnChange(val, 'password2')}}
                                className="password2"
                                inputType={showPassword2 ? 'text' : 'password'}
                                placeholder="Confirm Password"
                            />
                            <div className='show_hide_password'>
                                <Icon
                                    iconid='visibility'
                                    onClick={() => setShowPassword2(!showPassword2)}
                                    size='25px' />
                            </div>
                        </div>
                    </div>
                </Fieldset>
                {!user ?<div className='terms_container'>
                    {termsError && <p className="terms_error">{termsError}</p>}
                    <CheckBox  checked={termsChecked} onChange={(v) => setTermsChecked(v)} style={{display: 'inline'}} label="I accept the terms and conditions." key="fb1"/>
                    <Button style={{marginTop: '-5px', marginLeft: '31px'}} type='outlined' dense onClick={() => termsDialogRef.current.open()}>Read the Terms and Conditions</Button>
                </div>: null}

                <div style={{marginTop: '40px'}}>
                    {hasErrors && <span className='form_error'>There are errors in the form.</span>}
                    <Button style={{width: "40%", marginLeft: 0, marginRight: '20px'}} type="primary" onClick={submitForm}>{buttonText}</Button>
                    {!user && <Button type='outlined' onClick={onCancel}>Cancel</Button>}
                </div>
            </div>

            <Dialog key="d1" ref={termsDialogRef}
                    width="80%"
                    cardStyle={{maxHeight: '80vh', overflow: 'auto'}}
                    showClose={true}
                    className='terms_and_conditions'
            >
                <TermsAndConditionsContent />
            </Dialog>
        </div>
    )
}
