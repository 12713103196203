import React, {useRef, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {AnimaticHeader} from "./AnimaticHeader";
import {ContactForm} from "./ContactForm";
import {Icon} from "zebra-stripes/Symbols/Icon";
import './CODHomepage.scss';

export const Graduation = () => {

    const [imageKey, setImageKey] = useState(1);
    const imageKeyRef = useRef(1);
    const interval = useRef(null);

    useEffect(() => {
        interval.current = setInterval(() => {
            imageKeyRef.current = imageKeyRef.current === 3 ? 1 : imageKeyRef.current + 1
            setImageKey(imageKeyRef.current);
        }, 4000);
        return () => {
            clearInterval(interval.current);
        }
    }, []);

    return (
        <article className="homepage">
            <AnimaticHeader />
            <div className='hero mini'>
                <a href='/home'><img alt='COD Logo' src='/images/homepage/COD-Live_LOGO2020_4c.png' /></a>
            </div>
            <div className="content_wrapper" id='homepage_hosting'>

                <div>
                    <h3>Graduation</h3>
                    <div className='split'>
                        <div className='image_rotator'>
                            <img className={imageKey === 1 ? 'show' : ''}
                                 src='/images/homepage/1Shot-pg2.jpg'/>
                            <img className={imageKey === 2 ? 'show' : ''}
                                 src='/images/homepage/1Shot-pg3.jpg'/>
                            <img className={imageKey === 3 ? 'show' : ''}
                                 src='/images/homepage/Grad-pg5.jpg'/>
                        </div>
                        <div className='content_box wide'>
                            <p>LIVE Online Graduations that will still give the feel of a polished, produced event and
                                we are offering it at surprisingly affordable prices.</p>
                            <p>Key Benefits:</p>
                            <ul>
                                <li> Custom Look to match your school’s look/logo, colors, etc.</li>

                                <li>Virtually unlimited viewers so all family, friends and faculty can attend</li>

                                <li>Simultaneously stream multiple speakers such as:
                                    <ul>
                                        <li>President/Principal</li>
                                        <li>Guest Speakers</li>
                                        <li>Valedictorian</li>
                                        <li>Speaker</li>
                                        <li>Faculty</li>
                                    </ul>
                                </li>
                                <li>Features:
                                    <ul>
                                        <li>Student’s photo</li>
                                        <li>Degrees earned</li>
                                        <li>Special Designations & Awards</li>
                                        <li>Journal Ad Words or Praise from Friends or Family</li>
                                        <li>Scrolling Live Tweets (Screened) from viewers</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <div className="content_wrapper notop" id='homepage_contact'>
                <div>
                    <h3>Contact</h3>
                    <ContactForm isBlog={false} />
                </div>
            </div>
        </article>
    )
}