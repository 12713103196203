import React, { useContext, useEffect, useState } from "react";
import {Link, useParams} from 'react-router-dom'
import {Icon} from 'zebra-stripes/Symbols/Icon'
import {TextBox} from 'zebra-stripes/Forms';
import './TradeShowHeader.scss';
import {EventContext} from "../../contexts/EventContext";

export const TradeShowHeader = ({
    exhibits = [],
    assetPath = '',
    hasPremiere = false,
}) => {
    const { event } = useContext(EventContext);
    const page = ''; //props.match.url.split('/').slice(-1)[0];
    return (
        <header className={`TradeShowHeader`}>
            <div>
                <img src={`${assetPath}/logo.png`} /><br/>
                <button onClick={() => window.history.back()}>Back</button>
            </div>
            <div>
                {event && hasPremiere ?
                page === 'exhibitors' ?
                    <Link to={`${event?.alias}tradeshow`}>Premiere Exhibitors</Link> :
                    <Link to={`${event?.alias}tradeshow/exhibitors`}>All Exhibitors</Link>
                    :
                    <Link to={`${event?.alias}tradeshow/exhibitors`}>All Exhibitors</Link>
                }
                | <Link to={`${event?.alias}`}>Sessions</Link> | <TradeShowSearch exhibits={exhibits} />
            </div>
        </header>
    );
}



export const TradeShowSearch = (props) =>  {
    const [ search, setSearch ] = useState('');
    const [ results, setResults ] = useState([]);
    const [ isOpen, setIsOpen ] = useState(false);

    const handleResults = (e, res) => {
        setSearch(res);
        let resultsCollection = [];
        if(res) {
            props.exhibits.map((exhibit) => {
                if (exhibit.name.toLowerCase().indexOf(res.toLowerCase()) >= 0) {
                    resultsCollection.push(exhibit);
                }
            });
        }
        setResults(resultsCollection);
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`TradeShowSearch`}>
            <Icon iconid='search' color='rgba(100,100,100,.75)' />
            <TextBox
                className='searchInput'
                value={search}
                style={{margin: 0, color: 'white'}}
                supersetPlaceholder={false}
                width='175px'
                onChange={handleResults}
                placeholder='Search Exhibitors' />
            <div className={`ResultPanel ${search ? 'show' : ''}`}>
                {
                    results.length > 0 ?
                    results.map(result => (
                        <div><Link to={`./${result.name.toLowerCase().replace(/\s/g, '')}`}>{result.name}</Link></div>
                    )) :
                        <p>No Results Found.</p>
                }
            </div>
        </div>
    );
}