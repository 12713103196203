export const productType = {
    Session: 1,
    Track: 2,
    Conference: 3,
    FreeEvent: 4,
    LiveSession: 5,
}

export const fullEventProducts = [
    productType.Conference,
    productType.FreeEvent,
];

export const isFullEventProduct = (productId) => {
    return fullEventProducts.includes(productId);
}
