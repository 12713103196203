import './main.scss';
import React from "react";
import {useParams, useRoutes} from "react-router-dom";
import { CODHomepage } from "./controllers/homepage/CODHomepage";
import { EventHomepage } from "./controllers/EventHomepage";
import { EventSession } from './controllers/EventSession';
import { TrackViwer } from './controllers/TrackViwer'
import { EventContextProvider } from "./contexts/Event";
import { AuthenticationContextProvider } from "./contexts/Authentication";
import { EventLayout } from './components/EventLayout';
import { StripesTheme } from 'zebra-stripes/Core/StripesTheme';
import { theme } from './themes/Theme';
import { icons } from './themes/icons';
import './stylesheets/main.scss';
import { Account } from "./controllers/Account";
import {UserEventProvider} from "./contexts/UserEvent";
import {TradeShowExhibit} from "./controllers/TradeShowExhibit";
import {TradeShow} from "./controllers/TradeShow";
import {Graduation} from "./controllers/homepage/Graduation";
import {CODDemo} from "./controllers/homepage/CODDemo";
import {AVProduction} from "./controllers/homepage/AVProduction";
import {Features} from "./controllers/homepage/Features";
import {About} from "./controllers/homepage/About";
import {Resources} from "./controllers/homepage/Resources";
import {JoinController} from "./controllers/JoinController";
import {EventPasswordReset} from "./controllers/EventPasswordReset";
import {RegistrationProvider} from "./contexts/Registration";
import {EventLogin} from "./controllers/EventLogin";
import {PresenterShare} from "./controllers/PresenterShare";

function App() {
    const routes= [
        {
            children: [
                {
                    path: '/',
                    index: true,
                    element: <CODHomepage /> },
                {
                    path: '/home',
                    index: true,
                    element: <CODHomepage /> },
                {
                    path: '/about',
                    index: true,
                    element: <About /> },
                {
                    path: '/features',
                    index: true,
                    element: <Features /> },
                {
                    path: '/avproduction',
                    index: true,
                    element: <AVProduction /> },
                {
                    path: '/coddemo',
                    index: true,
                    element: <CODDemo /> },
                {
                    path: '/graduation',
                    index: true,
                    element: <Graduation /> },
                {
                    path: '/resources',
                    index: true,
                    element: <Resources /> },
                {
                    path: '/join',
                    index: true,
                    element: <JoinController />,
                },
                {
                    path: '/join/:sessionId',
                    element: <JoinController />,
                },
                {
                    path: '/presenterShare/:password/:sessionId/:username',
                    element: <PresenterShare />,
                },
                {
                    path: '/:client/:eventpath',
                    element: <EventHomepage />,
                },
                {
                    path: '/:client/:eventpath/login',
                    exact: true,
                    element: <EventLogin />,
                },
                {
                    path: '/:client/:eventpath/tradeshow',
                    element: <TradeShow />,
                },
                {
                    path: '/:client/:eventpath/tradeshow/exhibitors',
                    element: <TradeShow />,
                },
                {
                    path: '/:client/:eventpath/tradeshow/:exhibit',
                    element: <TradeShowExhibit />,
                },
                {
                    path: '/:client/:eventpath/trackviewer/:tid',
                    element: <TrackViwer />,
                },
                {
                    path: '/:client/:eventpath/trackviewer',
                    element: <TrackViwer />,
                },
                {
                    path: '/:client/:eventpath/:sessionid',
                    element: <EventSession />,
                },
                {
                    path: '/:client/:eventpath/reset/:resetid',
                    element: <EventPasswordReset />,
                },
                {
                    path: '/:client/:eventpath/account',
                    element: <Account />,
                },
            ]
        }
    ];
    const element = useRoutes(routes);

    const themeObject = {
        spacing: theme.spacing,
        palette: theme.palette,
        icons: icons,
    };

    return (
        <StripesTheme theme={themeObject}>
            <UserEventProvider>
                <EventContextProvider>
                    <AuthenticationContextProvider>
                        <RegistrationProvider>
                            <EventLayout>

                                {element}

                            </EventLayout>
                        </RegistrationProvider>
                    </AuthenticationContextProvider>
                </EventContextProvider>
            </UserEventProvider>
        </StripesTheme>
  );
}

export default App;
