export function useAPI() {
    const postService = (api, data) => {

        return fetch(api, {
            headers: {
                'Accept': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(function(response) {
                return response.json();
            })
            .catch(function(e) {
            })
            .then((Obj) => {
                return Obj;
            });
    }
    return {
        postService,
    }
}
