import React from "react";

export function VHCHeader() {

    return (
        <div className="siteHeader">
            <div>
                <div><img src='/images/vhc/2021_VHC_Banner_left.jpg' /></div>
                <div><img src='/images/vhc/2021_VHC_Banner_middle.jpg' /></div>
                <div><img src='/images/vhc/2021_VHC_Banner_right.jpg' /></div>
            </div>
        </div>
    )
}
