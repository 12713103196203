import React, { useContext, useState, useImperativeHandle, forwardRef } from "react";
import {EventContext} from "../contexts/EventContext";
import {AuthenticationContext} from "../contexts/AuthenticationContext";
import {TextBox, Button} from "zebra-stripes/Forms";
import {TwoColumnLayout} from 'zebra-stripes/Layouts'
import {Icon} from "zebra-stripes/Symbols/Icon";
import './LoginForm.scss';
import {API} from "../controllers/API";
import {useAPI} from "../contexts/useAPI";

const resetMessages = {
    success: 'If the supplied email address contains an account, an email with instructions has been sent to this address to reset your password. Please check your spam folder if you don’t see it.',
    failure: 'There was a problem with your submission, please try again.',
}
export const LoginForm = forwardRef(({
    standalone = false,
    onLogin = () => false,
    sessionid = null,
    hasRegistrations = true,
    baseUrl = null,
    onCancel = () => false,
    onShowRegistration = () => false,
}, ref) => {

    const { event, session } = useContext(EventContext);
    const { user, getUserName, destroySession, login } = useContext(AuthenticationContext);
    const {
        postService
    } = useAPI();
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ emailError, setEmailError ] = useState(null);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ passwordError, setPasswordError ] = useState(null);
    const [ badLoginError, setBadLoginError ] = useState(null);
    const [ showConcurrentLogin, setShowConcurrentLogin ] = useState(false);
    const [ showResetPassword, setShowResetPassword ] = useState(false);
    const [ passwordResetMessage, setPasswordResetMessage ] = useState(null);

    const validateForm = () => {
        let isValid = true;
        if(email.trim() === '') {
            setEmailError('Please enter an email.');
            isValid = false;
        }
        if(!showResetPassword && password.trim() === '') {
            setPasswordError('Please enter an password.');
            isValid = false;
        }
        return isValid;
    }

    const submitForm = async (force = false) => {
        if(validateForm()) {
            login({
                email,
                password,
                force,
                eventid: event?.id,
                sessionid: session?.id,
            }).then((response) => {
                if (response?.isAuthenticated) {
                    if (response.externally_logged && !force) {
                        // the user is already logged on
                        setShowConcurrentLogin(true);
                        setBadLoginError(null);
                    } else {
                        // clean login.
                        setShowConcurrentLogin(false);
                        setBadLoginError(null);
                        onLogin();
                        setShowPassword(false);
                    }
                } else {
                    setBadLoginError('Your email or your password did not match our records, please re-enter your login credentials.');
                }
            });
        }
    }

    const submitPasswordEmailLink = async () => {
        if(validateForm()) {
            await postService(
                API.sendResetPasswordEmailAPI,
                {
                    sessionTest: btoa(email + ' ' + document.location.origin + event.alias)
                }
            ).then((data) => {
                setPasswordResetMessage(data.success ? 'success' : 'failure');
            });
        }
    }

    const handleConcurrentLogin = () => {
        submitForm(true);
    }

    const handleCancelConcurrentLogin = () => {
        setShowConcurrentLogin(false);
        destroySession();
        setEmail('');
        setPassword('');
        onCancel();
    }

    const launchForgotPassword = () => {
        setShowResetPassword(true);
        setPasswordError(null);
        setEmailError(null);
        setBadLoginError(null);

    }
    const closeForgotPassword = () => {
        setPasswordResetMessage(null);
        setShowResetPassword(false);
    }

    const resetForm = () => {
        setEmail('');
        setEmailError(null);
        setPassword('');
        setPasswordError(null);
    }
    useImperativeHandle(ref, () => ({
        resetForm: resetForm,
    }));

    const hasRegistration = event?.hasRegistrations;
    return (
        <div
            className='LoginForm'
        >
            <h3>{showResetPassword ? 'Reset Your password' : 'Member Login'}</h3>
            {showConcurrentLogin ?
                <div className='concurrent_login_message' style={{margin: '10px 0 40px 0'}}>
                    <div>
                        <Icon iconid="alert" color="red" size="40px" />
                    </div>
                    <div>
                        <p>The username, {getUserName()}, is already logged in. If you wish to continue to log in using this email address the other user will be disconnected from the system.</p>
                    </div>
                </div> :
                <TwoColumnLayout
                    className='login_register_two_column'
                    columnOne={
                        <div>
                            <TextBox
                                onChange={(e, v) => setEmail(v)}
                                value={email}
                                tabIndex='100'
                                error={emailError}
                                showBorder={true}
                                width={'100%'}
                                name={'email'}
                                id={'login_email'}
                                onEnterKey={submitForm}
                                placeholder="Email Address"/>
                            {showResetPassword ?
                                passwordResetMessage ? <p className={`reset_message ${passwordResetMessage}`}>{resetMessages[passwordResetMessage]}</p> :
                                <p>You can reset your password by submitting the email address that you have originally signed up with for this event. An email with instructions on how to update your password will be sent to you. </p>
                                : <div className='password_fields'>
                                    <TextBox
                                        onChange={(e, v) => setPassword(v)}
                                        value={password}
                                        tabIndex='101'
                                        width={'100%'}
                                        showBorder={true}
                                        inputType={showPassword ? 'text' : 'password'}
                                        error={passwordError}
                                        name={'password'}
                                        id={'login_password'}
                                        onEnterKey={submitForm}
                                        placeholder="Password"
                                    />
                                    <div className='show_hide_password'>
                                        <Icon
                                            iconid='visibility'
                                            onClick={() => setShowPassword(!showPassword)}
                                            size='25px' />
                                    </div>
                                </div>}
                            {badLoginError &&
                                <em key="badloginerror" className="badlogin_error errors">{badLoginError}</em>}
                        </div>
                    }
                    columnOneWidth={hasRegistration ? "60%" : "75%"}
                    columnTwoWidth={hasRegistration ? "40%" : "25%"}
                    columnTwoStyle={{borderLeft: '1px solid #ccc', padding: '0 0 0 16px'}}
                    columnTwo={
                        hasRegistration ?
                            <div className="login_form_register">
                                <p>Do not have an account yet, please register for access to this event here.</p>
                                <Button type="primary" style={{width: "100%", marginLeft: 0}}
                                    onClick={() => onShowRegistration()}>Register</Button>
                            </div> : <p>This event can only be granted access by the event organizer. Please reach out them to get access to this event.</p>
                    }
                />
            }
            <div className='login_buttons'>
            {showResetPassword ?
                <>
                    <Button type="primary" style={{width:"150px"}} onClick={submitPasswordEmailLink}>Send Reset Email</Button>
                    <Button onClick={closeForgotPassword}>{passwordResetMessage ? 'Back to Login' : 'Cancel'}</Button>
                </>
                : showConcurrentLogin ?
                <>
                    <Button type="primary" style={{width:"150px"}} key="proceed" onClick={handleConcurrentLogin}>Proceed</Button>
                    <Button key="cancel1" onClick={handleCancelConcurrentLogin}>Cancel</Button>
                </>
                :
                <>
                    <Button type="primary" style={{width:"150px"}} key="submit" onClick={() => submitForm()}>Login</Button>
                    {!standalone ? <Button key="cancel2" onClick={onCancel}>Cancel</Button> : null}
                    <a href="#" onClick={launchForgotPassword}>Forgot Password?</a>
                </>
            }
            </div>
        </div>
  )
});
