import React, {useContext, useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {EventContext} from "../contexts/EventContext";
import {AuthenticationContext} from "../contexts/AuthenticationContext";
import {Button} from "zebra-stripes/Forms";
import {Dialog} from 'zebra-stripes/Layouts';
import './EventHeader.scss';
import {LoginForm} from "./LoginForm";
import {AuthenticationEvents} from "../contexts/Authentication";
import {Registration} from "./Registration";
import {ProductType} from "../helpers/ProductHelper";
import {SelectRegistrationOption} from "./SelectRegistrationOption";

export const HideHeaderWhenLoggedOutPaths = [
    '/tradeshow',
];

export function EventHeader(props) {

    const { event, allProductsForEvent, session, eventRoute, launchRegistration$ } = useContext(EventContext);
    const { user, isAuthenticated, logout, loginEvent$ } = useContext(AuthenticationContext);
    const loginDialogRef = useRef(null);
    const loginPanelRef = useRef(null);
    const [ showRegistration, setShowRegistration ] = useState(false);
    const [ ignorePurchasing, setIgnorePurchasing ] = useState(false);
    const [ showRegistrationOptions, setShowRegistrationOptions ] = useState(false);
    const showRegistrationRef = useRef(false);
    useEffect(() => {
        const subscription = loginEvent$.subscribe((event) => {
            switch (event?.action) {
                case AuthenticationEvents.Login:
                    if (!showRegistrationRef.current) {
                        loginDialogRef.current.close();
                    }
                    break;
                default:
                    break;
            }
        });

        subscription.add(launchRegistration$.subscribe((regEvent) => {
            if (regEvent) {
                setShowRegistration(regEvent.show);
                setIgnorePurchasing(regEvent.ignorePurchasing);
                loginDialogRef.current.open();
            }
        }));
        return () => {
            subscription.unsubscribe();
        }
    }, []);
    const navigate = useNavigate();
    let paths = useParams();
    useEffect(() => {
        showRegistrationRef.current = showRegistration;
    }, [showRegistration]);

    const handleLogout = async () => {
        await logout({
            eventId: event?.id,
            sessionId: session?.id,
        });
        if (window.location.pathname.includes('/account')) {
            navigate(event.alias);
        }
    }

    const location = useLocation();
    const hideHeaderWhenLoggedOut = (currentPath) => {
        const containsPartialPattern =
            HideHeaderWhenLoggedOutPaths.filter((pattern) => {
                return currentPath?.includes(pattern);
            });
        return !!containsPartialPattern.length;
    }

    const eventRoutePath = eventRoute ? `/${eventRoute[0]}/${eventRoute[1]}/account` : './account';

    const openLoginDialog = () => {
        loginPanelRef.current.resetForm();
        loginDialogRef.current.open();
    }

    const loggedInDOM = <>
        <p><strong>Welcome {user?.firstname}</strong></p>
        <Link to={eventRoutePath}><Button type='primary'>Account</Button></Link>
        <Button type='primary' onClick={handleLogout}>Logout</Button>
    </>;

    const notLoggedInDOM = <Button type='primary' onClick={openLoginDialog}>
        {event?.hasRegistrations ? 'Login / Register' : 'Login'}
    </Button>;

    const handleRegistrationLauncher = () => {
        // console.log(allProductsForEvent);
        const purchasableProducts = [ProductType.Track, ProductType.Session, ProductType.LiveSession];
        const hasConferenceProduct = allProductsForEvent.filter((prod) => prod.product_id === ProductType.Conference).length > 0;
        const hasAdditionalProducts = allProductsForEvent.filter((prod) => purchasableProducts.includes(prod.product_id)). length > 0;
        // console.log(hasConferenceProduct, hasAdditionalProducts);
        setIgnorePurchasing(false);
        if (hasAdditionalProducts && hasConferenceProduct && !session?.id && !event.isfree) {
            setShowRegistrationOptions(true);
        } else {
            setShowRegistration(true);
        }
    }

    const closeRegOptionsLaunchRegistration = () => {
        setShowRegistrationOptions(false);
        setShowRegistration(true);
    }

    const redirectToSessionListingAndCloseReg = () => {
        setShowRegistrationOptions(false);
        loginDialogRef.current.close();
    }

    return (
        <header className='EventHeader'>
            {event &&
            <div className="account_links">
                {(hideHeaderWhenLoggedOut(location.pathname) && !isAuthenticated) ? null : isAuthenticated ? loggedInDOM : notLoggedInDOM}
            </div>}
            <Dialog className="login-dialog" ref={loginDialogRef}
                    showClose={false}
                    width="90%"
                    cardStyle={{minWidth: '90%', maxHeight: '95vh', overflow: 'auto'}}
            >
                {showRegistration ?
                        <Registration
                            onShowLogin={() => {
                                setShowRegistration(false);
                            }}
                            ignorePurchasing={ignorePurchasing}
                            onCancel={() => {
                                setShowRegistration(false);
                                loginDialogRef.current.close()
                            }}
                        />
                    :
                    <LoginForm
                        ref={loginPanelRef}
                        eventid={event?.id}
                        hasRegistrations={event?.hasRegistrations}
                        onCancel={() => {
                            loginDialogRef.current.close()
                        }}
                        onShowRegistration={handleRegistrationLauncher}
                    />
                }
            </Dialog>
            <SelectRegistrationOption
                show={showRegistrationOptions}
                onCancel={() => {
                    setShowRegistrationOptions(false);
                }}
                onProceedToRegistration={closeRegOptionsLaunchRegistration}
                onRedirectToSessionListing={redirectToSessionListingAndCloseReg}
            />
        </header>
    )
}
