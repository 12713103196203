import React, { useContext, useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import { EventContext } from '../contexts/EventContext';
import {pageResolver} from "./pageResolver";
import {UserAccountForm} from "../components/UserAccountForm";

export function Account() {
    let paths = useParams();
    const [ viewComponent, setViewComponent ] = useState({
        header: null,
        page: null,
    });
    const navigate = useNavigate();
    const { setEventRoute, event } = useContext(EventContext);

    useEffect(() => {
        setEventRoute([paths.client, paths.eventpath]);
        setViewComponent(pageResolver(`${paths.client}/${paths.eventpath}`));
        window.scrollTo(0,0);
    }, []);

    const redirectToLastPage = () => {
        navigate(-1);
    };

    return (
        <article className={`${paths.client}${paths.eventpath}`}>
            {viewComponent.header}
            {event && <UserAccountForm onComplete={redirectToLastPage}/>}
        </article>
    )
}
