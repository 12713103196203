import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { EventContext } from '../contexts/EventContext';
import { pageResolver } from "./pageResolver";
import {AuthenticationContext} from "../contexts/AuthenticationContext";
import {Button} from 'zebra-stripes/Forms';
import './EventSession.scss';
import {ChatEngine} from "../components/SessionViewer/ChatEngine";
import {SupplementalPresentations} from "../components/SessionViewer/SupplementalPresentations";
import {VideoPanel} from "../components/SessionViewer/VideoPanel";
import {UserEventContext} from "../contexts/UserEventContext";
import {LoginForm} from "../components/LoginForm";
import {Registration} from "../components/Registration";
import {RegistrationContext} from "../contexts/RegistrationContext";
import {SessionViewStatus} from "../contexts/Event";
import {Loading} from "../components/Loading";
import { useBeforeunload } from 'react-beforeunload';
import {Icon} from "zebra-stripes/Symbols/Icon";
const moment = require('moment-timezone');


export function EventSession() {
    let paths = useParams();
    const [ viewComponent, setViewComponent ] = useState({
        header: null,
        page: null,
    });
    const chatEngineRef = useRef(null);
    const {
        setEventRoute,
        event,
        session,
        getSessionDateAccess,
        getTimeZone,
        launchRegistration$
    } = useContext(EventContext);
    const { user, getUserInfo, productAccess, pendingProductAccess, resolveSessionAccess, resolvePendingSessionAccess, getProductAccessByEvent, isAuthenticated, userRequestComplete$ } = useContext(AuthenticationContext);
    const { logEvent } = useContext(UserEventContext);
    const { setProduct } = useContext(RegistrationContext);
    const [resolution, setResolution] = useState({
        width: 600,
        height: '100%',
        iframeStyle: null,
    });
    const [hideChat, setHideChat] = useState(false);
    const [showRegistration, setShowRegistration] = useState(true);
    const [hasAccess, setHasAccess] = useState(false);
    const [hasPendingAccess, setHasPendingAccess] = useState({pending: false, date: null, type: null});
    const [hasChat, setHasChat] = useState(false);
    const [dateAccess, setDateAccess] = useState({});
    const [userHasBeenRequested, setUserHasBeenRequested] = useState(false);
    const sessionRef = useRef(null);
    const initialLog = useRef(false);

    useEffect(() => {
        setEventRoute([paths.client, paths.eventpath, paths.sessionid]);
        setViewComponent(pageResolver(`${paths.client}/${paths.eventpath}`));
        const subscription = userRequestComplete$.subscribe((ready) => {
            setUserHasBeenRequested(true);
        });
        const dateInterval = setInterval(() => {
            setDateAccess(getSessionDateAccess(sessionRef.current));
        }, 5000);
        document.addEventListener("keydown", (e) => {
            //document.onkeydown = function(e) {
            // "I" key
            if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
                disabledEvent(e);
            }
            // "J" key
            if (e.ctrlKey && e.shiftKey && e.keyCode == 74) {
                disabledEvent(e);
            }
            // "S" key + macOS
            if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
                disabledEvent(e);
            }
            // "U" key
            if (e.ctrlKey && e.keyCode == 85) {
                disabledEvent(e);
            }
            // "F12" key
            if (e.keyCode == 123) {
                disabledEvent(e);
            }
        }, false);
        window.addEventListener("resize", updateHeight);

        return () => {
            window.removeEventListener("resize", updateHeight);
            clearInterval(dateInterval);
            subscription.unsubscribe();
        }
    }, []);

    useBeforeunload(() => {
        logClose();
    });

    /**
     * Once the contexts resolve the user and session information, and it doesn't have a persisting
     * productAccess object, it will call out to get the access of the current user for this session.
     */
    useEffect(async () => {
        if (session && user && !productAccess) {
            await getProductAccessByEvent(event.id, user.userid);
            setUserHasBeenRequested(true);
            setHasChat(session.GROUP_Chat || session.QA_Chat || session.PRIVATE_Chat);
        }
        if (session) {
            setDateAccess(getSessionDateAccess(session));
            sessionRef.current = session;
        }
    }, [user, session]);

    useEffect(() => {
        if (dateAccess.status === SessionViewStatus.StreamingNow && resolveSessionAccess(session) && chatEngineRef.current) {
            if (!chatEngineRef.current.isInitialized) {
                chatEngineRef.current.initChat(session.id);
            }
        }
    }, [dateAccess.status]);

    useEffect(() => {
        if (session && event && !initialLog.current) {
            logEvent(
                getUserInfo() ? getUserInfo().userid : null,
                event.id,
                session.id,
                "view",
                "session"
            );
            if (user) {
                initialLog.current = true;
            }
        }
    }, [session, event, user]);

    const logClose = () => {
        logEvent(
            getUserInfo() ? getUserInfo().userid : null,
            event.id,
            session.id,
            "close",
            "session"
        );
    }

    useEffect(() => {
        /**
         * will initilize the chat engine once it has the user info and an access object.
         */
        if (user && session && productAccess) {
            setHasAccess(resolveSessionAccess(session));
            setHasPendingAccess(resolvePendingSessionAccess(session));
            setTimeout(() => {
                if (resolveSessionAccess(session) && chatEngineRef.current) {
                    if (!chatEngineRef.current.isInitialized) {
                        chatEngineRef.current.initChat(session.id);
                    }
                }
                updateHeight();
            }, 500);

        } else {
            setHasAccess(false);
        }
    }, [productAccess, pendingProductAccess, session]);

    const broadcastOpenRegistrationPanel = () => {
        launchRegistration$.next({
            show: true,
            ignorePurchasing: false,
        });
    }

    const disabledEvent = (e) => {
        if (e.stopPropagation){
            e.stopPropagation();
        } else if (window.event){
            window.event.cancelBubble = true;
        }
        e.preventDefault();
        return false;
    }

    const updateHeight = () => {
        const sixnine = session?.streamingid.indexOf('webinar.conferenceondemand.net') >= 0;
        const frameEl = document.getElementById('playerFrame');
        const videoWrapperEl = document.getElementById('video_wrapper');
        const width = hasChat && videoWrapperEl ? videoWrapperEl.clientWidth : window.innerWidth < 1000 ? window.innerWidth : window.innerWidth *.8;

        if (frameEl) {
            setResolution({
                width: width,
                height: width * (sixnine ? (9 / 16) : (3/4)),
                iframeStyle: {margin: '0 auto'},
            });
        }
    }

    //console.log(dateAccess, dateAccess.status, dateAccess.status === SessionViewStatus.LiveEventEnded && !session?.media.length);

    const hideRegistration = () => {
        setShowRegistration(false);
        setProduct(null);
    }

    const handleVideoCompletion = () => {
        // nothing right now.
    }

    // console.log(hasPendingAccess);
    // console.log(isAuthenticated, hasAccess, event?.canPurchase, session);
    // console.log(session);
    return (
        <article className={`${paths.client}${paths.eventpath}`}>
            {viewComponent.header}
            <div className={`EventSession`}>
                {isAuthenticated === null ? <Loading /> :
                (session && userHasBeenRequested) ? <>
                <h3>
                    {dateAccess.icon && <img className='streaming' src={`/images/${dateAccess.icon}.png`} />}
                    <span>{session?.sessiontitle ? atob(session?.sessiontitle) : '--'}</span>
                </h3>
                    {/**
                     When the user has pending access, meaning they purchased this session, but dont have access until some time
                     in the future, we need to message that to the user and not present purchase options.
                     */}
                {(hasPendingAccess.pending && session) ?
                    <div style={{position: 'relative'}}>

                        <div className='pending_access'>
                            <Icon iconid={"unlock_alt"}
                                  color={"green"} size="30px"
                            />
                            <p>
                                You have purchased this product, but access to this content does not begin
                                until {moment(hasPendingAccess.date).format('MMMM D, YYYY')}.
                            </p>
                        </div>
                        <div className='live_placeholder upcoming' style={{background: 'url(/images/live-placeholder.jpg)'}}>
                            <p>
                                {`You will be granted access to this session on ${moment(hasPendingAccess.date).format('MMMM D, YYYY')}.`}
                            </p>
                        </div>

                    </div>


                    : (hasAccess && session) ?
                        /**
                           Has access to this session, will proceed to show the player and chat window, if configured.
                         */
                    <div style={{position: 'relative'}}>
                        {(hasChat && dateAccess.status === SessionViewStatus.StreamingNow) &&
                            <div className='toggleChatButton'><Button onClick={() => setHideChat(!hideChat)}>{hideChat ? 'Show Chat' : 'Hide Chat'}</Button></div>}

                        {dateAccess.status === SessionViewStatus.StreamingNow &&
                        /**
                         * This is the view when there is currently a live broadcast happening
                         */
                        <div className={`Live_Streaming_Layout ${hasChat && !hideChat ? 'with_chat' : ''}`}>
                            <div id='video_wrapper'>
                                <iframe id='playerFrame' src={session.streamingid} width={'100%'} style={resolution.iframeStyle} height={resolution.height}
                                        frameBorder="0" scrolling="no" allow="autoplay;camera;microphone"
                                        allowFullScreen={true}
                                        webkitallowfullscreen={'true'}
                                        mozallowfullscreen={'true'}
                                        oallowfullscreen={'true'}
                                        msallowfullscree={'true'}
                                />
                            </div>
                            {hasChat &&
                            <ChatEngine
                                className={hideChat ? 'hide' : ''}
                                userInfo={user}
                                eventId={session.eventid}
                                event={event}
                                chatTypes={{
                                    GROUP: session.GROUP_Chat,
                                    QA: session.QA_Chat,
                                    PRIVATE: session.PRIVATE_Chat
                                }}
                                ref={chatEngineRef}
                                onHide={(stat) => setHideChat(stat)}
                            />}
                        </div>}

                        {/**
                         * This is the display when there is no live event currently running and is upcoming
                         */
                        (dateAccess.status === SessionViewStatus.LiveSoon) &&
                            <div className='live_placeholder upcoming' style={{background: 'url(/images/live-placeholder.jpg)'}}>
                                    <p>
                                        {`This session will go live on ${dateAccess.startDate.format('MMMM D, YYYY, h:mm a')} ${event.timezone}. Please come back to watch the live stream.`}
                                    </p>
                                </div>
                        }

                        {/**
                         * Live event is over UI
                         */
                        (dateAccess.status === SessionViewStatus.LiveEventEnded && !session.media.length) &&
                            <div className='live_placeholder completed'>
                                <p>{`The live stream has ended for this presentation, the session video will be posted shortly, please check back at a later date.`}</p>
                            </div>
                        }

                        {(dateAccess.status === SessionViewStatus.Upcoming ||
                            dateAccess.status === SessionViewStatus.Completed) &&
                        <>
                            <div className={`Live_Streaming_Layout ${hasChat && !hideChat ? 'with_chat' : ''}`}>
                                <VideoPanel
                                    event={event}
                                    event_path={(paths.client + "/" + paths.eventpath).toLowerCase()}
                                    media={session.media}
                                    session={session}
                                    width={resolution.width}
                                    height={resolution.height}
                                    onVideoEnd={handleVideoCompletion}
                                />
                                {hasChat &&
                                <ChatEngine
                                    className={hideChat ? 'hide' : ''}
                                    userInfo={user}
                                    eventId={session.eventid}
                                    event={event}
                                    chatTypes={{
                                        GROUP: session.GROUP_Chat,
                                        QA: session.QA_Chat,
                                        PRIVATE: session.PRIVATE_Chat
                                    }}
                                    onHide={(stat) => setHideChat(stat)}
                                    ref={chatEngineRef}
                                />}
                            </div>
                            <SupplementalPresentations
                                event_path={(paths.client + "/" + paths.eventpath).toLowerCase()}
                                event={event}
                                sessionid={session.id}
                                files={session.presentations}
                            />
                        </>
                        }

                    </div>
                    :
                    isAuthenticated  ?
                        !hasAccess && event.canPurchase ?
                            <div className='session_purchase_container'>
                                <p>You do not have access to this session. To view the content on this page you need to purchase a product.</p>
                                {showRegistration ? <Registration
                                    standAlone={true}
                                    onCancel={hideRegistration}
                                /> : <Button type='primary' onClick={() => setShowRegistration(true)}>Purchase Options</Button>}
                            </div> :
                            <div>
                                <p>You do not currently have access to this session. This might be related to which sessions you purchased or it might be due to the dates you are trying to access the content.</p>
                                <p>Please contact the company who supplied you this link for more information.</p>
                            </div>
                        :
                    <LoginForm
                        eventid={event?.id}
                        hasRegistrations={event?.hasRegistrations}
                        standalone={true}
                        onShowRegistration={() => broadcastOpenRegistrationPanel()}
                    />}


                    </> : <Loading />}
            </div>
        </article>
    )
}
