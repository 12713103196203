import React from "react";
import { Link  } from "react-router-dom";

export function NOHCHeader() {

    return (
        <div className="siteHeader">
            <div>
                <Link to="/nohc/2021/"><img src="/images/nohc/2021.png"  alt='NOHC Virtual 2021'/></Link>
            </div>
        </div>
    )
}

export function NOHCFooter() {

    return (
        <footer className="event_footer">
            <div style={{padding: '20px 0'}}>
                ©NOHC. All Rights Reserved.
            </div>
        </footer>
    )
}
