import React from 'react'
import { Link } from "react-router-dom";
import './AnimaticHeader.scss';

export const menuItems = [
    <div key="menu2"><a href='/features'>Features</a> | </div>,
    <div key="menu3"><a href="/about">About</a> | </div>,
    <div key="menu4" className='hide_on_small'><a href="/home#homepage_testimonials">Testimonials</a> | </div>,
    <div key="menu5" className='toggle_more'>
        <a>More</a> |
        <div className={'submenu'}>
            <div key="menu5_1"><a href="/avproduction">AV Production & Video</a></div>
            <div key="menu5_2"><a href="/coddemo">Demo</a></div>
            <div key="menu5_3"><a href="/graduation">Graduation</a></div>
            <div key="menu5_4"><a href="/resources">Resources</a></div>
        </div>
    </div>,
    <div key="menu6" className='hide_on_small'><a href="#homepage_contact">Contact</a> | </div>,
    <div key="menu7"><a href="/join">Presenter Login</a></div>
];

export const AnimaticHeader = () => {
    return (
        <header className="AnimaticHeader">
            <div>
                {menuItems.map((item) => item)}
            </div>
        </header>
    );
}