import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { EventContext } from '../contexts/EventContext';
import { pageResolver } from "./pageResolver";
import {LoginForm} from "../components/LoginForm";
import {AuthenticationContext} from "../contexts/AuthenticationContext";
import {Registration} from "../components/Registration";

export function EventLogin() {
    let paths = useParams();
    const { user } = useContext(AuthenticationContext);
    const [ viewComponent, setViewComponent ] = useState({
        header: null,
        page: null,
    });
    const [ showRegistration, setShowRegistration ] = useState(false);
    const { setEventRoute, event } = useContext(EventContext);
    const navigate = useNavigate();
    useEffect(() => {
        setEventRoute([paths.client, paths.eventpath]);
        setViewComponent(pageResolver(`${paths.client}/${paths.eventpath}`));
    }, []);

    useEffect(() => {
        if (user) {
            redirectToHomepage();
        }
    }, [user])

    const redirectToHomepage = () => {
        navigate(`/${paths.client}/${paths.eventpath}`);
    };

    return (
        <article className={`${paths.client}${paths.eventpath}`}>
            {viewComponent.header}
            <div className='EventLogin'>
                <div>
                    {event &&
                    (showRegistration ?
                        <Registration
                            onShowLogin={() => {
                                setShowRegistration(false);
                            }}
                            onCancel={() => {
                                setShowRegistration(false);
                            }}
                        />
                        :
                        <LoginForm
                            eventid={event?.id}
                            hasRegistrations={event?.hasRegistrations}
                            onLogin={redirectToHomepage}
                            standalone={true}
                            onShowRegistration={() => setShowRegistration(true)}
                        />
                    )}
                </div>
            </div>
            {viewComponent.footer}
        </article>
    )
}
