import React, {useRef, useState} from "react";
import { Link } from "react-router-dom";
import {AnimaticHeader} from "./AnimaticHeader";
import {ContactForm} from "./ContactForm";
import {Icon} from "zebra-stripes/Symbols/Icon";
import './CODHomepage.scss';

export const Resources = (props) => {
    return (
        <article className="homepage">
            <AnimaticHeader />
            <div className='hero mini'>
                <a href='/home'><img alt='COD Logo' src='/images/homepage/COD-Live_LOGO2020_4c.png' /></a>
            </div>
            <div className="content_wrapper" id='homepage_hosting'>

                <div>
                    <h3>Resources</h3>
                    <div className='content_box'>
                        <ul className='resource_list'>
                            <li>
                                <img src='/images/pdf.svg' />
                                <a href='https://www.conferenceondemand.net/images/homepage/COD_BEST_PRACTICES_FOR_WEBINAR_HOST.pdf'>COD Best Practices</a>
                            </li>
                            <li>
                                <img src='/images/pdf.svg' />
                                <a href='https://www.conferenceondemand.net/images/homepage/COD_LIVE_Comparison_Chart_v3.pdf'>COD +Live Comparison Chart</a>
                            </li>
                            <li>
                                <img src='/images/pdf.svg' />
                                <a href='https://www.conferenceondemand.net/images/homepage/Breakouts_and_Virtual_Events.pdf'>Breakouts and Virtual Events</a>
                            </li>
                        </ul>
                    </div>
                </div>




                {/* “Set & Go” Room Docs */}
                <div className="content_wrapper">
                    <div>
                        <h3>“Set &amp; Go” Room Docs</h3>


                        <div className='content_box'>
                            <h4>How To Give Access To Camera &amp; Microphone On Your PC:</h4>
                            <ul className='resource_list'>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/HowToGiveAccessToCam_Mic/COD_How-to-unlock-camera-and-microphone-in-chrome.pdf'>COD How to unlock camera and microphone in Chrome</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/HowToGiveAccessToCam_Mic/COD_How-to-unlock-camera-and-microphone-in-edge.pdf'>COD How to unlock camera and microphone in Edge</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/HowToGiveAccessToCam_Mic/COD_How-to-unlock-camera-and-microphone-in-firefox.pdf'>COD How to unlock camera and microphone in FireFox</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/HowToGiveAccessToCam_Mic/COD_How-to-unlock-camera-and-microphone-in-opera.pdf'>COD How to unlock camera and microphone in Opera</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/HowToGiveAccessToCam_Mic/COD_How-to-unlock-camera-and-microphone-in-safari.pdf'>COD How to unlock camera and microphone in Safari</a>
                                </li>
                            </ul>
                        </div>


                        <div className='content_box'>
                            <h4>Interact with Audience</h4>

                            <ul className='resource_list'>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/InteractWithAudience/COD_Breakout_rooms_v2.pdf'>COD Breakout Rooms v2</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/InteractWithAudience/COD_Call-to-action.pdf'>COD Call To Action</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/InteractWithAudience/COD_Decisions-professional-voting.pdf'>COD Decisions Professional Voting</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/InteractWithAudience/COD_Room_chat_EN.pdf'>COD Room Chat EN</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/InteractWithAudience/COD_Tests-and-surveys.pdf'>COD Tests And Surveys</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/InteractWithAudience/COD_Vote-manager.pdf'>COD Vote Manager</a>
                                </li>
                            </ul>
                        </div>


                        <div className='content_box'>
                            <h4>Share Content:</h4>

                            <ul className='resource_list'>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/ShareContent/COD_Screen-sharing.pdf'>COD Screen Sharing</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/ShareContent/COD_Share-content--presenter-menu.pdf'>COD Share Content Presenter Menu</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/ShareContent/COD_Vimeo-player.pdf'>COD Vimeo Player</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/ShareContent/COD_whiteboard.pdf'>COD Whiteboard</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/ShareContent/COD_Youtube-player.pdf'>COD Youtube Player</a>
                                </li>
                            </ul>
                        </div>


                        <div className='content_box'>
                            <h4>Webinar &amp; Meetings Room Features:</h4>

                            <ul className='resource_list'>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/WebinarMeetingRoomFeatures/COD_Audio_video_EN.pdf'>COD Audio Video EN</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/WebinarMeetingRoomFeatures/COD_Participant-roles.pdf'>COD Participant Roles</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/WebinarMeetingRoomFeatures/COD_Room-features-menu.pdf'>COD Room Features Menu</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/WebinarMeetingRoomFeatures/COD_Webinar-room-header-navbar.pdf'>COD Webinar Room Header Navbar</a>
                                </li>

                            </ul>
                        </div>


                        <div className='content_box'>
                            <h4>Other:</h4>

                            <ul className='resource_list'>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/Other/COD_FAQ_v2.pdf'>COD FAQ v2</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/Other/COD_How_to_turn_on_audio_video_all_browsers_EN.pdf'>COD How to Turn on Audio Video All Browsers EN</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/Other/COD_How-to-join-meeting-on-mobiles.pdf'>COD How to Join Meeting on Mobiles</a>
                                </li>
                                <li>
                                    <img src='/images/pdf.svg' />
                                    <a href='https://www.conferenceondemand.net/images/homepage/Other/COD_Sharing_sound_from_an_application.pdf'>COD Sharing Sound From an Application</a>
                                </li>
                            </ul>
                        </div>



                    </div>
                </div>



            </div>
            <div className="content_wrapper notop" id='homepage_contact'>
                <div>
                    <h3>Contact</h3>
                    <ContactForm isBlog={false} />
                </div>
            </div>
        </article>
    )
}