import React, { useContext, useEffect, useState, useRef, useImperativeHandle, forwardRef } from "react";
import {TextArea, TextBox, Button, DropDown, Item} from 'zebra-stripes/Forms'
import {TabMenu} from 'zebra-stripes/Layouts'
import io from "socket.io-client";
import { API } from '../../controllers/API';
import {AuthenticationContext} from "../../contexts/AuthenticationContext";
import {useAPI} from "../../contexts/useAPI";
import {EventContext} from "../../contexts/EventContext";
import './ChatEngine.scss';
const m = require('moment-timezone');
const socket = io(API.socketAPI);

// event emitters
//socket.emit(`/api/conference/message`, data); // data: { chat_id: "string", user_id: "string", message: "string" }
//socket.emit(`/api/conference/invite`, data); // data: { chat_id: "string", user_id: "string", new_chat_id: "string" }
//socket.emit(`/api/conference/question`, data); // data: { chat_id: "string", user_id: "string", message: "string" }

// event listeners
//socket.on(`/api/conference/invite/${"chat_id"}/${"user_id"}`, receivePrivateInviteCallback);
//socket.on(`/api/conference/question/${"chat_id"}`, receiveQuestionCallback);
//socket.on(`/api/conference/message/${data.event.id}`, this.receiveMessageCallback);

const intToRGB = (i) => {
    var c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();
    return "00000".substring(0, 6 - c.length) + c;
};

const LightenDarkenColor = (col,amt) => {
    var usePound = false;
    if ( col[0] == "#" ) {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col,16);

    var r = (num >> 16) + amt;

    if ( r > 255 ) r = 255;
    else if  (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if ( b > 255 ) b = 255;
    else if  (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;

    if ( g > 255 ) g = 255;
    else if  ( g < 0 ) g = 0;

    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
}

const hashCode = (str) => { // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
};

export const ChatEngine = forwardRef((props, ref) => {
    const {
        userInfo = null,
        className = '',
        onHide = () => false,
        showHide = true,
        chatTypes = {
            GROUP: true,
            QA: false,
            PRIVATE: false
        },
        postButtonText = null,
        event = {},
        forceShow = false,
        presenterView = false,
        onNewChat = () => false,
    } = props;
    const { getTimeZone } = useContext(EventContext);
    const {
        postService
    } = useAPI();
    const chatEnabledRef = useRef({GROUP_Chat: true});
    const [chatId, setChatId] = useState(null);
    const chatIds = useRef([]);
    const [chatMessages, setChatMessages] = useState([]);
    const chatMessagesRef = useRef([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [currentMessage, setCurrentMessage] = useState('');
    const [chatInitialized, setChatInitialized] = useState(false);
    const [messageCounts, setMessageCounts] = useState({
        presenter: 0,
        group: 0
    });
    const chatTestIntervRef = useRef(null);
    const groupMessagesRef = useRef(null);
    const presenterMessagesRef = useRef(null);

    const receiveSocketMessage = useRef(null);
    const receiveSocketQuestion = useRef(null);

    useEffect(() => {
        return () => {
            socket.disconnect();
            if(chatTestIntervRef.current) {
                clearInterval(chatTestIntervRef.current);
            }
        }
    }, []);

    useEffect(() => {
        if (chatInitialized && !userInfo) {
            unloadChat();
        }
    }, [userInfo]);

    useEffect(() => {
        setTimeout(() => popToRecent(), 10);
    }, [chatMessages, selectedTab]);

    useImperativeHandle(ref, () => ({
        initChat,
        unloadChat,
        isInitialized: chatInitialized,
    }));

    const initChat = (id, isPresenter, sids) => {
        setChatId(id);
        const timeZoneDiff = (event.timezone ?
            m().tz(getTimeZone(event.timezone)).utcOffset() : 0);

        postService(
            API.sessionChatURL,
            {
                chat_id: sids ? sids : id, // will get all associated with track if we pass it through.
                user_id: userInfo?.userid || 0,
                type: null
            }
        )
            .then((data) => {
                const messages = data.filter((mess) => {
                    let privateMessage = testPrivateMessage(atob(mess.message));
                    return !privateMessage.isPrivateMessage ||
                        (privateMessage.isPrivateMessage && privateMessage.isYourMessage) ||
                        (mess.chat_id === userInfo?.userid) ||
                        (sids?.includes(mess.chat_id));
                });
                messages.forEach(message => {
                    message.time = m(message.time).add(timeZoneDiff + 180, 'minutes').toDate();
                });
                if(!forceShow) {
                    requestChatAvailability(id);
                }
                chatMessagesRef.current = messages;
                setChatMessages(messages);
                chatIds.current = sids ? sids.split(',').map((id) => (Number(id))) : [id];
            });

        if(chatTestIntervRef.current) {
            clearInterval(chatTestIntervRef.current);
        }
        if(!forceShow) {
            chatTestIntervRef.current = setInterval(() => {
                requestChatAvailability(id);
            }, 10000);
        }
        setChatInitialized(true);
        receiveSocketMessage.current = socket.on(`/api/conference/message/${id}`, receiveMessageCallback);
        receiveSocketQuestion.current = socket.on(`/api/conference/question/${id}`, receiveMessageCallback);

    };

    const unloadChat = () => {
        setChatInitialized(false);
        if(chatTestIntervRef.current) {
            clearInterval(chatTestIntervRef.current);
        }
    }

    const requestChatAvailability = async (id) => {
        return postService(
            API.chatAliveAPI,
            {
                session_id: id
            }
        )
            .then((result) => {
                chatEnabledRef.current = result;
            });
    }

    const receiveMessageCallback = (mess) => {
        if((mess.data.type === 'presenter' && chatEnabledRef.current?.QA_Chat) || (mess.data.type === 'group' && chatEnabledRef.current?.GROUP_Chat)) {
            const messages = chatMessagesRef.current.slice();
            let privateMessage = testPrivateMessage(atob(mess.data.message));
            mess.data.private = privateMessage.isYourMessage;
            const iAmThePresenter = (mess.data.chat_id === userInfo.userid);
            /*console.log(privateMessage);
            console.log('INCOMING!!!!!!!!');
             console.log(!privateMessage.isPrivateMessage || (privateMessage.isPrivateMessage && privateMessage.isYourMessage));
             console.log(chatIds.current, chatIds.current.includes(mess.data.user_id));*/
            if(
                (
                    mess.data.type !== 'presenter' ||
                    (mess.data.type === 'presenter' && mess.data.user_id === userInfo.userid) ||
                    (mess.data.type === 'presenter' && chatIds.current.includes(mess.data.user_id))||
                    iAmThePresenter
                ) &&
                (
                    //(type === 'presenter' && this.state.chat_ids.includes(message.user_id) && !privateMessage.isPrivateMessage) ||
                    iAmThePresenter || !privateMessage.isPrivateMessage || (privateMessage.isPrivateMessage && privateMessage.isYourMessage)
                )
            ) {
                // console.log('incoming:', mess.data.time, m(mess.data.time).format('hh:mm a'));
                messages.push(mess.data);
                onNewChat(mess.data);
                chatMessagesRef.current = messages;
                setChatMessages(messages);
                setMessageCounts({
                    presenter: selectedTab === 0 && mess.data.type === 'presenter' ? messageCounts.presenter + 1 : 0,
                    group: selectedTab === 1 && mess.data.type === 'group' ? messageCounts.group + 1 : 0,
                });
            }
        }
    };

    const testPrivateMessage = (message) => {
        let isPrivateMessage = false;
        let isYourMessage = false;
        if (message.indexOf('[@') >= 0) {
            if (message.match(/\[@(.*)\]/) && message.match(/\[@(.*)\]/)[1] && message.match(/\[@(.*)\]/)[1]*1 !== 0) {
                isPrivateMessage = true;
                isYourMessage = message.match(/\[@(.*)\]/)[1]*1 === userInfo.userid*1
            }
        }
        return {
            isPrivateMessage: isPrivateMessage,
            isYourMessage: isYourMessage
        };
    }

    const popToRecent = () => {
        if(groupMessagesRef.current) {
            groupMessagesRef.current.scrollTop = groupMessagesRef.current.scrollHeight;
        }
        if(presenterMessagesRef.current) {
            presenterMessagesRef.current.scrollTop = presenterMessagesRef.current.scrollHeight;
        }
    };

    const postChatMessage = (location) => {
        if (currentMessage.trim() !== '' &&
            ((location === 'presenter' && chatEnabledRef.current?.QA_Chat) ||
            (location === 'group' && chatEnabledRef.current?.GROUP_Chat))) {
            const chat_destination = location === 'presenter' ? '/api/conference/question' : '/api/conference/message';
            const payload = {
                chat_id: chatId*1,
                user_id: userInfo.userid*1,
                user_name: (userInfo.firstname || userInfo.lastname) ? btoa(userInfo.firstname + " " + userInfo.lastname) : btoa('Anonymous'),
                message: btoa(currentMessage.trim()),
                type: location,
                presenter: presenterView,
                time: (new Date()).getTime()
            };

            const postMessagePayload = {...payload, ...{event_id: event.id}};
            postService(
                API.chatPostAPI,
                postMessagePayload,
            );
            setCurrentMessage('');
            socket.emit(chat_destination, payload);
        }
    };

    const displayMessages = (type) => {
        // console.log(type, chatMessages);
        return chatMessages.map((message,i) => {
            //console.log('--------------------', this.state.chat_ids);
            //console.log('display messages', message.type, type);
            if(message.type === type) {
                if (message.type === 'presenter') {
                   // console.log('will consider message', message, (type === 'presenter' && chatIds.current.includes(message.user_id)));
                }
                const iAmThePresenter = (message.chat_id === userInfo?.userid);
                let privateMessage = testPrivateMessage(atob(message.message));
                message.private = privateMessage.isYourMessage;
                /*console.log(type, this.state.chat_ids.includes(message.user_id), privateMessage.isPrivateMessage, privateMessage.isYourMessage);
                console.log((type === 'presenter' && message.user_id === this.props.userInfo.userid) );
                console.log((type === 'presenter' && message.user_id === this.state.chat_id));
                console.log((type === 'presenter' && this.state.chat_ids.includes(message.user_id) && !privateMessage.isPrivateMessage));
                console.log((type === 'presenter' && this.state.chat_ids.includes(message.user_id) && privateMessage.isPrivateMessage && privateMessage.isYourMessage));*/
                if((type !== 'presenter' ||
                    (type === 'presenter' && message.user_id === userInfo?.userid) ||
                    /*(type === 'presenter' && message.user_id === this.state.chat_id) ||*/
                    (type === 'presenter' && chatIds.current.includes(message.user_id) && !privateMessage.isPrivateMessage) ||
                    (type === 'presenter' && chatIds.current.includes(message.user_id) && privateMessage.isPrivateMessage && privateMessage.isYourMessage) ||
                    (iAmThePresenter))
                ) {
                    return iAmThePresenter ? <PresenterChatItem
                            key={`mess${i}`}
                            chat_id={chatId}
                            userInfo={userInfo}
                            message={message}
                            event={event}
                            chatEnabled={chatEnabledRef.current?.QA_Chat}
                        /> :
                        <ChatItem
                            key={`mess${i}`}
                            chat_id={chatId}
                            userInfo={userInfo}
                            message={message} />;
                }
            }
        })
    };

    const GroupChatDOM = chatTypes.GROUP ?
        <div className='chat_layout' key='gchat'>
            <div ref={groupMessagesRef} className='chat_messages'>
                {displayMessages('group')}
            </div>
            <div className='chat_post_input'>
                <TextBox
                    value={currentMessage}
                    onChange={(e, val) => setCurrentMessage(val)}
                    placeholder='Please Enter a Message...'
                    onEnterKey={() => postChatMessage('group')}
                    supersetPlaceholder={false}
                    disabled={!chatEnabledRef.current?.GROUP_Chat}
                />
                {chatEnabledRef.current?.GROUP_Chat ?
                <div>
                    {showHide && <Button type='secondary' onClick={() => onHide(true)}>Hide</Button>}
                    <Button disabled={currentMessage === '' || !chatEnabledRef.current} type='primary' onClick={() => postChatMessage('group')}>{postButtonText ?? 'POST TO GROUP'}</Button>
                </div> : <p className='chat_disabled'>The chat feature has been disabled.</p>}
            </div>
        </div>
        : null;
    const QAChatDOM = chatTypes.QA ?
        <div className='chat_layout' key='qachat'>
            <div ref={presenterMessagesRef} className='chat_messages'>
                {displayMessages('presenter')}
            </div>
            <div className='chat_post_input'>
                <TextBox
                    value={currentMessage}
                    onChange={(e, val) => setCurrentMessage(val)}
                    placeholder='Please Enter a Message...'
                    onEnterKey={() => postChatMessage('presenter')}
                    supersetPlaceholder={false}
                    disabled={!chatEnabledRef.current?.QA_Chat}
                />
                {chatEnabledRef.current?.QA_Chat ?
                <div>
                    <Button onClick={() => onHide(true)}>Hide</Button>
                    <Button type='primary' disabled={currentMessage === '' || !chatEnabledRef.current} onClick={() => postChatMessage('presenter')}>POST TO PRESENTERS</Button>
                </div> : <p className='chat_disabled'>The chat feature has been disabled.</p>}
            </div>
        </div>
        : null;

    const useTab = !!GroupChatDOM && !!QAChatDOM;
    /*console.log('----------------------');
    console.log(this.props.chatTypes.GROUP, this.props.chatTypes.QA);
    console.log(useTab);
    console.log(GroupChatDOM, QAChatDOM);*/
    return (
            <div className={`ChatEngine ${className} ${useTab ? '' : 'no-tabs'}`}>
                {chatId ?
                    useTab ?
                    <TabMenu key='tabmenu'  onClick={(e,item) => {
                        setSelectedTab(item);
                        setMessageCounts({
                            presenter: 0,
                            group: 0
                        });
                    }} contentStyle={{position: 'relative', height: '600px'}}>
                        <Item selected={selectedTab === 0} key="tab1" label={`Group Chat ${messageCounts.group ? '('+messageCounts.group+')' : ''}`}>
                            {GroupChatDOM}
                        </Item>
                        {chatTypes.QA &&
                        <Item selected={selectedTab === 1} key="tab2" label={`Q&A ${messageCounts.presenter ? '(' + messageCounts.presenter+')' : ''}`}>
                            {QAChatDOM}
                        </Item>
                        }
                    </TabMenu>
                    :
                    [GroupChatDOM,QAChatDOM]
                : <p key='ntavail'>Initializing...</p>}
            </div>
    )
});

const cleanMessage = (mess) => {
    return mess.replace(/\[@(.*)\]/, '');
}

const ChatItem = (props) => {
    const {userInfo, message, chat_id} = props;
    const userName = atob(message.user_name);
    const borderColor = intToRGB(hashCode(userName));
    const backgroundColor = LightenDarkenColor(borderColor, 98);
    const cleanedMessage = cleanMessage(atob(message.message));
    return userInfo && (
        <div
             style={{borderColor: '#' + borderColor, backgroundColor: '#' + backgroundColor}}
             className={`${message.presenter ? 'presenter' : ''} ${message.user_id !== userInfo.userid ? 'external' : ''}`}>
            <label
                key='m2'>
                {userName}
                {message.presenter ? <span className='presenter_label'>PRESENTER</span> : null}
                {!message.presenter && userInfo.userid !== 0 && message.user_id === userInfo.userid && ' (you)'}<span>{m(message.time).format('h:mm a')}</span></label>
            <p key='m1'>
                {cleanedMessage}
                {(message.type === 'presenter' && (message.user_id !== chat_id)) && <span>SENT ONLY TO PRESENTERS</span>}
                {message.private && <span>SENT ONLY TO YOU</span>}
            </p>
        </div>
    )
}

export const PresenterChatItem = (props) => {
    const { user } = useContext(AuthenticationContext);
    const {
        postService
    } = useAPI();
    const {
        userInfo = user,
        message = {},
        event = {},
        chat_id = null,
        chatEnabled = true,
    } = props;

    const [ showReply, setShowReply ] = useState(false);
    const [ currentMessage, setCurrentMessage ] = useState('');

    const toggleReplyToUser = () => {
        setShowReply(!showReply);
    }

    const updateMessage = (e, mess) => {
        setCurrentMessage(mess);
    };

    const postChatMessage = (location) => {
        if (currentMessage.trim() !== '' && chatEnabled) {
            //const location = this.refs.chatdest.value;
            const chat_destination = location === 'presenter' ? '/api/conference/question' : '/api/conference/message';
            //this.refs.chatdest.value = 'group';
            const messageText = `[@${message.user_id}]${currentMessage.trim()}`;
            const payload = {
                chat_id: chat_id*1,
                user_id: userInfo.userid*1,
                user_name: btoa(userInfo.firstname + " " + userInfo.lastname),
                message: btoa(messageText),
                type: location,
                presenter: true,
                time: (new Date()).getTime()
            };
            setShowReply(false);
            const postMessagePayload = {...payload, ...{event_id: event.id}};
            postService(
                API.chatPostAPI,
                postMessagePayload,
            );
            setCurrentMessage('');
            socket.emit(chat_destination, payload);
        }
    };

    const userName = atob(message.user_name);
    const borderColor = intToRGB(hashCode(userName));
    const backgroundColor = LightenDarkenColor(borderColor, 98);
    const cleanedMessage = cleanMessage(atob(message.message));
    return (
        <div
            style={{borderColor: '#' + borderColor, backgroundColor: '#' + backgroundColor}}
            className={`${message.presenter ? 'presenter' : ''} ${message.user_id !== userInfo.userid ? 'external' : ''}`}>
            <label
                key='m2'>
                {userName}
                {!message.presenter && message.user_id === userInfo.userid && ' (you)'}<span>{m(message.time).format('h:mm a')}</span></label>
            <p key='m1'>
                {cleanedMessage}
                {chatEnabled &&
                <div>
                    {(message.user_id !== userInfo.userid && message.user_id < 1000000) &&
                    <Button onClick={toggleReplyToUser}>{showReply ? 'Hide' : 'Private Reply'}</Button>}
                    <div className={`chat_post_input reply ${showReply ? 'show' : ''}`}>
                        <TextBox
                            value={currentMessage}
                            onChange={(e, val) => setCurrentMessage(val)}
                            placeholder='Please Enter a Message...'
                            onEnterKey={() => postChatMessage('presenter')}
                            supersetPlaceholder={false}
                            disabled={!chatEnabled}
                        />
                        <div>
                            <Button type='primary'
                                        disabled={currentMessage === '' || !chatEnabled}
                                        onClick={() => postChatMessage('presenter')}>POST TO USER</Button>
                        </div>
                    </div>
                </div>
                }
            </p>
        </div>
    )
}