import React, { useState, useEffect, useContext, useRef } from 'react'
import Stripe from 'stripe';
import {Elements, CardElement, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {TwoColumnLayout, Dialog} from 'zebra-stripes/Layouts'
import {ProductPricingSummary} from './ProductPricingSummary'
import {calculateTotalPrice} from '../../helpers/ProductHelper'
import {DropDown, TextArea, Item, TextBox, Fieldset, Button} from 'zebra-stripes/Forms'
import {API} from "../../controllers/API";
import './Payment.scss';
import './CheckoutForm.scss';

import {EventContext} from "../../contexts/EventContext";
import {AuthenticationContext} from "../../contexts/AuthenticationContext";
import {RegistrationContext} from "../../contexts/RegistrationContext";

const promoErrors = {
    "NONEXISTENT" : "This promotion cannot be found.",
    "INCOMPATIBLE" : "This promotion cannot be coupled with this product.",
    "MAXREACHED" : "Max usage for this promo has been reached."
};

const stripePromise = loadStripe(API.stripeAPI);

export const StripeForm = (props) => {
    const { onSuccess } = props;
    const { user } = useContext(AuthenticationContext);
    const { promo, userObj, product, validatePromo, setBillingInfo, completeRegistration, regClientSecret } = useContext(RegistrationContext);
    const { event } = useContext(EventContext);
    const [ billingFirstname, setBillingFirstname ] = useState(user?.firstname || userObj.fname);
    const [ billingLastname, setBillingLastname ] = useState(user?.lastname || userObj.lname);
    const [ billingAddress, setBillingAddress ] = useState(user?.address || userObj.address);
    const [ billingCity, setBillingCity ] = useState(user?.city || userObj.city);
    const [ billingState, setBillingState ] = useState(user?.state || userObj.state);
    const [ promoInput, setPromoInput ] = useState('');
    const [ promoError, setPromoError ] = useState(null);
    const [ validPromo, setValidPromo ] = useState(false);
    const [ disablePromoButton, setDisablePromoButton ] = useState(true);

    useEffect(() => {
        setBillingInfo({
            firstname: billingFirstname,
            lastname: billingLastname,
            address: billingAddress,
            city: billingState,
            state: billingCity,
        })
    }, [billingFirstname, billingLastname, billingAddress, billingState, billingCity]);

    const finalPricing = calculateTotalPrice(product, promo);

    const testPromoValue = (e,val) => {
        setPromoInput(val);
        setDisablePromoButton(!val || val === "");
    }

    const validatePromoState = async () => {
        validatePromo(promoInput, (promoResponse) => {
            setValidPromo(promoResponse.success);
            setPromoError(!promoResponse.success ? promoErrors[promoResponse.error] : null);
        });
    }

    const handleSubmit = async () => {
        const payResponse = await completeRegistration(user || userObj);
        props.onSuccess(payResponse);
    };

    const options = {
        clientSecret: regClientSecret,
    };

    return (
        <div className='StripeForm'>
            <ProductPricingSummary promo={promo} product={product}/>
            <div className='billing_info'>
                {product?.cost > 0 ?
                <>
                    <h3>Billing Information</h3>
                    <Fieldset className='user_billing_fields'>
                        <div>
                            <TextBox
                                onBlur={setBillingFirstname}
                                onEnterKey={setBillingFirstname}
                                placeholder="First Name" width="49%" value={billingFirstname}/>
                            <TextBox
                                onBlur={setBillingLastname}
                                onEnterKey={setBillingLastname}
                                placeholder="Last Name"  width="49%" value={billingLastname}/>
                        </div>
                    </Fieldset>

                    <Fieldset title="Enter a Promotional Code" className={"promo_code " + (validPromo ? "valid" : "")}>
                        <TextBox value={promoInput} error={promoError} onChange={testPromoValue} placeholder="Promo Code" width="75%"/>
                        <Button
                            style={validPromo ? {backgroundColor: 'green', width: 'calc(25% - 10px)', margin: '10px 0 0 10px'} : {width: 'calc(25% - 10px)', margin: '10px 0 0 10px'}}
                            disabled={disablePromoButton}
                            icon={validPromo ? "check" : null}
                            iconColor={'white'}
                            type="primary"
                            onClick={validatePromoState}
                        >
                            Add
                        </Button>
                    </Fieldset>
                </> :
                <>
                    <h3>Complete Registration</h3>
                    <p>Click below to complete your registration.</p>
                </>
                }
                {finalPricing.final_price === 0 ?
                    <div style={{textAlign: 'center'}}>
                        <Button
                            style={{width: '100%'}}
                            type="primary"
                            onClick={handleSubmit}
                        >
                            Complete Registration
                        </Button>
                    </div>
                    :
                    <Fieldset title="Payment Information">
                        {regClientSecret && <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm
                                onSuccess={onSuccess}
                            />
                        </Elements>}
                    </Fieldset>
                }
            </div>
        </div>
    )
}



export function CheckoutForm(props) {
    const { submitPayment } = useContext(RegistrationContext);
    const [disabled, setDisabled] = useState(false);
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const stripe = useStripe();
    const elements = useElements();

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                fontFamily: 'Arial, sans-serif',
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        // setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);
        const payResponse = await submitPayment(stripe, elements);

        if (payResponse.error) {
            setError(`Payment failed ${payResponse.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            props.onSuccess(payResponse.paymentIntent);
        }
    };

    const prePopulate = (e,b) => {
        /*setTimeout(() => {
            console.log('HAVE ELEMENT???', document.getElementById('Field-postalCodeInput'));
        }, 1000);*/
    }

    return (
        <form id="payment-form">
            <PaymentElement id="card-element" onReady={prePopulate} options={cardStyle} onChange={handleChange} />
            <Button
                style={{width:'100%', margin: '20px 0 0 0'}}
                disabled={processing || disabled || succeeded}
                id="submit"
                type='primary'
                onClick={handleSubmit}
            >
                <span id="button-text">
                  {processing ? (
                      <div className="spinner" id="spinner"></div>
                  ) : (
                      "Pay now"
                  )}
                </span>
            </Button>
            {/* Show any error that happens when processing the payment */}
            {error && (
                <div className="card-error" role="alert">
                    {error}
                </div>
            )}
        </form>
    );
}