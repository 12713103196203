import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EventContext } from '../contexts/EventContext';
import { pageResolver } from "./pageResolver";
import './TrackViewer.scss';
import './EventPasswordReset.scss';
import {Button, TextBox} from "zebra-stripes/Forms";
import {API} from "./API";
import {useAPI} from "../contexts/useAPI";
import {Icon} from "zebra-stripes/Symbols/Icon";

const resetMessages = {
    success: 'Your password has been updated. Please proceed to the login page.',
    failure: 'There was a problem with your submission, please try again.',
}

export function EventPasswordReset() {
    let paths = useParams();
    const [ viewComponent, setViewComponent ] = useState({
        header: null,
        page: null,
    });
    const { eventRoute } = useContext(EventContext);
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [password1Error, setPassword1Error] = useState(null);
    const [password2Error, setPassword2Error] = useState(null);
    const [invalidLink, setInvalidLink] = useState(false);
    const [ passwordResetMessage, setPasswordResetMessage ] = useState(null);
    const [ resetID, setResetID ] = useState(paths.resetid);
    const { setEventRoute, event } = useContext(EventContext);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const {
        postService
    } = useAPI();

    useEffect(() => {
        setEventRoute([paths.client, paths.eventpath]);
        setViewComponent(pageResolver(`${paths.client}/${paths.eventpath}`));

        postService(
            API.testResetPasswordAPI,
            {
                sessionTest: btoa(resetID + ' ' + document.location.origin)
            }
        ).then((data) => {
            setInvalidLink(!data.valid);
        });

    }, []);

    const validateForm = () => {
        let isValid = true;

        if(password1 === '') {
            setPassword1Error('Please enter a password.');
            isValid = false;
        } else if (password1 !== '' && password1.length > 16) {
            setPassword1Error('Passwords cannot be longer than 16 characters.');
            isValid = false;
        } else if (password1 !== '' && password1.length < 8) {
            setPassword1Error('Passwords must be at least 8 characters long.');
            isValid = false;
        } else if (password1 !== '' && !testPasswordStrength(password1)) {
            setPassword1Error('Please enter a stronger password.');
            isValid = false;
        } else {
            setPassword1Error(null);
        }
        if(password1 !== password2) {
            setPassword2Error('Passwords do not match.');
            isValid = false;
        } else {
            setPassword2Error(null);
        }
        return isValid;
    }

    const testValidPasswordOnChange = (val, field) => {
        const tempPass1 = field === 'password1' ? val : password1;
        const tempPass2 = field === 'password2' ? val : password2;
        if (field === 'password1' && tempPass1 !== '' && !testPasswordStrength(tempPass1)) {
            setPassword1Error('Please enter a stronger password.');
        } else if (tempPass1 === '' || (field === 'password1' && testPasswordStrength(tempPass1))) {
            setPassword1Error(null);
        }
        if(tempPass1 !== tempPass2 && tempPass2 !== '') {
            setPassword2Error('Passwords do not match.');
        } else {
            setPassword2Error(null);
        }
    }

    const testPasswordStrength = (pass) => {
        return pass.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@$%^&:;,.?]).{8,16}$/);
    }

    const submitForm = async () => {
        if(validateForm()) {
            await postService(
                API.executePasswordUpdateAPI,
                {
                    sessionTest: btoa(resetID + ' ' + btoa(password1) + ' ' + document.location.origin + event.alias)
                }
            ).then((data) => {
                setPasswordResetMessage(data.success ? 'success' : 'failure');
            });

            setPasswordResetMessage('success');
        }
    }

    const continueToHome = () => {
        window.location = '/' + eventRoute.join('/');
    }

    const continueToLogin = () => {
        window.location = '/' + eventRoute.join('/') + '/login';
    }

    return (
        <article className={`${paths.client}${paths.eventpath}`}>
            {viewComponent.header}
            <div className='password_reset'>
                <div>
                    <h3>
                        Password Reset
                    </h3>
                    <div style={{position: 'relative'}}>
                        {invalidLink ?
                            <>
                                <p>The supplied link to update your password is invalid. Please proceed to the homepage, and you can re-request to update your password.</p>
                                <p><Button type="primary" style={{width:"150px"}} key="submit" onClick={continueToHome}>Continue</Button></p>
                            </> :
                            passwordResetMessage ?
                            <>
                                <p className={`reset_message ${passwordResetMessage}`}>{resetMessages[passwordResetMessage]}</p>
                                <p><Button type="primary" style={{width:"150px"}} key="submit" onClick={continueToLogin}>Continue</Button></p>
                            </>:
                            <>
                                <p>To complete your reset, enter the new password you would like to change your account to use. </p>
                                <p>Passwords should contain at least one number, one lower and one uppercase character, at least one valid special character (!@$%^&:;,.?) and be at least 8 characters long, no longer than 16.</p>

                                <div className='password_fields'>
                                    <TextBox id='pass1' tabIndex="1"
                                        name='password'
                                        autoComplete="new-password"
                                        showBorder={true}
                                        width="100%"
                                        error={password1Error}
                                        value={password1}
                                         onChange={(e,val) => { setPassword1(val); testValidPasswordOnChange(val, 'password1')}}
                                        className="password1"
                                         inputType={showPassword1 ? 'text' : 'password'}
                                        placeholder="Enter New Password"
                                    />
                                    <div className='show_hide_password'>
                                        <Icon
                                            iconid='visibility'
                                            onClick={() => setShowPassword1(!showPassword1)}
                                            size='25px' />
                                    </div>
                                </div>
                                <div className='password_fields'>
                                    <TextBox
                                        id='pass2'
                                        tabIndex="2"
                                        autoComplete={false}
                                        showBorder={true}
                                        width="100%"
                                        error={password2Error}
                                        value={password2}
                                        onChange={(e,val) => { setPassword2(val); testValidPasswordOnChange(val, 'password2')}}
                                        className="password2"
                                        inputType={showPassword2 ? 'text' : 'password'}
                                        placeholder="Confirm New Password"
                                    />
                                    <div className='show_hide_password'>
                                        <Icon
                                            iconid='visibility'
                                            onClick={() => setShowPassword2(!showPassword2)}
                                            size='25px' />
                                    </div>
                                </div>

                                <p><Button type="primary" style={{width:"150px"}} key="submit" onClick={submitForm}>Update</Button></p>
                            </>
                        }
                    </div>
                </div>
            </div>
        </article>
    )
}
