import React, {useContext, useEffect } from "react";
import {ProductCheckout} from "./Payment/ProductCheckout";
import {ProductCard} from "./Payment/ProductCard";
import {Button} from "zebra-stripes/Forms";
import {RegistrationContext} from "../contexts/RegistrationContext";
import {AuthenticationContext} from "../contexts/AuthenticationContext";
import {EventContext} from "../contexts/EventContext";

export const PurchaseOptions = (props) => {

    const { purchaseOptions } = useContext(EventContext);
    const { setProduct, product } = useContext(RegistrationContext);
    const { user, isAuthenticated } = useContext(AuthenticationContext);
    const {
        onBackToUserInfo = () => false,
        onSetProduct = (product) => false,
    } = props;

    useEffect(() => {
        // setProduct(purchaseOptions?.length === 1 ? purchaseOptions[0] : null);
    }, []);

    const buyAction = (obj) => {
        setProduct(obj);
        onSetProduct(obj);
    }

    const purchaseItemDOM = purchaseOptions?.map((p,i) => <ProductCard
        key={"pc" + i}
        product={p}
        onClick={() => buyAction(p)}
    />);

    return (
        <div className='PurchaseOptions'>
            {!isAuthenticated && <div className='navigation_links'>
                <Button type='primary' dense onClick={onBackToUserInfo}>Back to User Information</Button>
                <Button type='outlined' dense onClick={props.onCancel}>Cancel</Button>
            </div>}
            {purchaseItemDOM}
        </div>
    );
}
