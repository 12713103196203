import React, { useState, useContext } from 'react'
import {CheckBox, Fieldset, TextBox, TextArea} from 'zebra-stripes/Forms'
import {Button} from 'zebra-stripes/Forms'
import {TwoColumnLayout} from 'zebra-stripes/Layouts'
import {AuthenticationContext} from "../../contexts/AuthenticationContext";
import {API} from "../API";
import {useAPI} from "../../contexts/useAPI";
import './ContactForm.scss';

export const ContactForm = (props) => {

    const {
        isBlog = false,
        list = ['2062481420', '1854083709', '1384674305'], // test list: '1680505076'
        ca = 'ee75d7ab-677c-4a44-834f-21715d9ce133',
    }= props;

    const { validateEmail, validatePhone } = useContext(AuthenticationContext);
    const {
        postService
    } = useAPI();

    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [message, setMessage] = useState('');
    const [phone, setPhone] = useState('');
    const [title, setTitle] = useState('');
    const [company, setCompany] = useState('');
    const [hotTrends, setHotTrends] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [firstnameError, setFirstnameError] = useState(null);
    const [lastnameError, setLastnameError] = useState(null);
    const [messageError, setMessageError] = useState(null);
    const [phoneError, setPhoneError] = useState(null);
    const [sending, setSending] = useState(false);
    const [sendingError, setSendingError] = useState(false);


    const validateForm = () => {
        let isValid = true;

        if(!validateEmail(email)) {
            setEmailError('Please enter a valid email.');
            isValid = false;
        } else {
            setEmailError(null);
        }
        if(firstname.trim() === "") {
            setFirstnameError('Please enter a first name.');
            isValid = false;
        } else {
            setFirstnameError(null);
        }
        if(lastname.trim() === "") {
            setLastnameError('Please enter a last name.');
            isValid = false;
        } else {
            setLastnameError(null);
        }
        if(!isBlog && message.trim() === '') {
            setMessageError('Please supply a message no larger than 300 characters.');
            isValid = false;
        } else {
            setMessageError(null);
        }
        if(phone.trim() === "" || phone.trim() !== "" && !validatePhone(phone)) {
            setPhoneError('Please enter a valid phone number.');
            isValid = false;
        } else {
            setPhoneError(null);
        }
        return isValid;
    }

    const submitForm = () => {
        if(validateForm()) {
            setSending(true);
            const formPayload = {
                email: email,
                fname: firstname,
                lname: lastname,
                company: company,
                phone: phone,
                title: title,
                message: message,
                subject: isBlog ? 'User Signed Up For Hot Trends Newsletter' : 'Animatic Website Inquiry',
                hottrends: hotTrends,
            };
            postService(API.submitContactAPI, formPayload)
                .then((response) => {
                    if (response.success) {
                        if (hotTrends) {
                            var baseCCURL = 'https://visitor2.constantcontact.com/api/signup?ca=' + ca + '&email=' + email + '&first_name=' + firstname + '&last_name=' + lastname
                            if (company) {
                                baseCCURL += "&company=" + company;
                            }
                            if (title) {
                                baseCCURL += "&job_title=" + title;
                            }
                            if (phone) {
                                baseCCURL += "&phone=" + phone;
                            }
                            list.map((list, i) => {
                                baseCCURL += "&list_" + i + "=" + list;
                            });
                            postService(baseCCURL, {});
                        }
                        setTimeout(function () {
                            setEmail('');
                            setFirstname('');
                            setLastname('');
                            setCompany('');
                            setPhone('');
                            setTitle('');
                            setMessage('');
                            setSending(false);
                        }, 3000);
                        setSendingError(false);
                    } else {
                        setSendingError(true);
                        setSending(false);
                    }


                });
        }
    }

    return (
        <div className="ContactForm">
            {sendingError && <p className='error'>there was an error sending the form. Please try again.</p>}
            <TwoColumnLayout
                columnOne={<TextBox width="100%" showBorder={true} name='Firstname' error={firstnameError} key="firstname" value={firstname} onChange={(e,val) => setFirstname(val)} placeholder="First Name *"/>}
                columnTwo={<TextBox width="100%" showBorder={true} name='Lastname' error={lastnameError} key="lastname" value={lastname} onChange={(e,val) => setLastname(val)} placeholder="Last Name *"/>}
                gutter="16"
            />
            <TwoColumnLayout
                columnOne={<TextBox width="100%" showBorder={true} name='Email' error={emailError} key="email" value={email} onChange={(e,val) => setEmail(val)} placeholder="Email *"/>}
                columnTwo={<TextBox width="100%" showBorder={true} name='Phone' error={phoneError} key="phone" value={phone} onChange={(e,val) => setPhone(val)} placeholder="Phone *"/>}
                gutter="16"
            />
            <TwoColumnLayout
                columnOne={<TextBox width="100%" showBorder={true} name='Company' key="company" value={company} onChange={(e,val) => setCompany(val)} placeholder="Company"/>}
                columnTwo={<TextBox width="100%" showBorder={true} name='Title' key="title" value={title} onChange={(e,val) => setTitle(val)} placeholder="Title"/>}
                gutter="16"
            />
            <Fieldset style={{display: isBlog ? 'none' : 'block'}}>
                <TextArea maxLength="300" width="100%" placeholder="Message *" onChange={(e,v) => setMessage(v)} error={messageError}/>
            </Fieldset>
            <div>
                <div style={{float: 'right', marginTop: '14px', fontSize: '16px'}}>Phone: 888-280-4800</div>
                <div style={{float: 'left'}}><button key="submit" onClick={submitForm}>Submit</button></div>
                <div style={{float: 'left'}}><CheckBox checked={true} onChange={(v) => setHotTrends(v)} style={{marginTop: '5px', visibility: isBlog ? 'hidden' : 'visible'}} label="Subscribe to HotTrends Email" /></div>
            </div>
            <div className={`sending_message ${sending ? 'show' : ''}`}>Your message has been sent.</div>
        </div>
    );
}
