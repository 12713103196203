import React, { useContext, useEffect } from "react";
import {EventContext} from "../../contexts/EventContext";
import {Button} from "zebra-stripes/Forms";
import './VHCHomepage.scss';
import {VHCHeader} from "./VHCHeader";
import {SessionListing} from "../../components/SessionListing";

export function VHCHomepage() {
    const { event } = useContext(EventContext);

    const openEventApp = (url) => {
        window.open(url , '_blank');
    }

    return (
        <div className='VCHHomepage2021'>
            <div className='main_homepage_content'>
                <div>
                    <div className='conference_buttons'>
                        <Button dense onClick={() => openEventApp('https://vahealthcatalyst.org/wp-content/uploads/2021/09/Speaker-Bios.pdf')}>Speaker Bios</Button>
                        <Button dense onClick={() => openEventApp('https://vahealthcatalyst.org/wp-content/uploads/2021/09/Sponsors-pdf.pdf')}>Sponsor Information</Button>
                        <Button dense onClick={() => openEventApp('https://vahealthcatalyst.org/wp-content/uploads/2021/09/CE-Info.pdf')}>Continuing Education Credit Information</Button>
                        <Button dense onClick={() => openEventApp('https://vahealthcatalyst.org/wp-content/uploads/2021/09/2021-Catalyst-Annual-Summit-Program-Book-3.pdf')}>Program Book</Button>
                    </div>
                    <div className='homepage_copy'>
                        <h1>Welcome to the home of the 2021 Catalyst Annual Summit! As a fully virtual event you’ll be able to watch sessions live, or on-demand after the event.</h1>
                        <p>This event page is where you will attend the summit panels, and take part in virtual networking. Register at <a href='#' onClick={() => this.openEventApp('https://bit.ly/CatalystSummit')}>bit.ly/CatalystSummit</a> to pay $135 for professionals and $65 for students. More details will follow closer to the summit, and be on the lookout for your special thank you gift in the mail from Catalyst!</p>
                        <p>Please take a moment to check out the agenda below. Each day brings a balance of nationally-renowned expertise and Virginia specific innovations and information.  The schedule also includes opportunities for networking and one on one Q/A with our speakers. If you have any questions or need assistance before the event please email Daniel Hillard at <a href='mailto:dhillard@vahealthcatalyst.org'>dhillard@vahealthcatalyst.org</a>.</p>
                    </div>

                    <SessionListing />


                    {/*<div className={`speaker_bio ${this.state.currentspeaker.show ? 'show' : ''}`} style={{left: this.state.currentspeaker.x + 'px', top: (this.state.currentspeaker.y - 200) + 'px'}}
                         onMouseOver={() => clearTimeout(this.closeBox)} onMouseOut={() => this.launchSpeakerBio(null,null,false)}
                    >
                        {this.state.currentspeaker.image && <div className='bio_image'><img src={`${Global.CONFIG.API_SERVER}${this.state.currentspeaker.image}`} /></div>}
                        <div dangerouslySetInnerHTML={{__html : this.state.currentspeaker.bio}} />
                    </div>*/}
                </div>
                <div className='sponsors'>
                    <img src='/images/vhc/2021_Catalyst_Summit_Sponsor_Logos.png' />
                </div>
            </div>
        </div>
    )
}
