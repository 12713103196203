import React, { useContext, useEffect } from "react";
import { TradeShowHeader } from './TradeShowHeader';
import {SmallExhibitCard} from "./SmallExhibitCard";

export const AllExhibitors = (props) => {

    const {
        exhibits = [],
        assetPath = '',
    } = props;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div>
            <div>
                <div>
                    <TradeShowHeader {...props} />
                    <h1>ALL EXHIBITORS</h1>
                </div>
            </div>

            <div className={`exhibit_content`}>
                <div className='all_exhibitor_list'>
                    {exhibits.map((exhibit, i) => <SmallExhibitCard key={`smallExhibit${i}`} assetPath={assetPath} exhibit={exhibit} />)}
                </div>
            </div>
        </div>
    )
}
