import React, { useState, useEffect, useRef } from "react";
import {useParams} from "react-router-dom";
import {useAPI} from "../contexts/useAPI";
import {API} from "./API";
import './JoinController.scss';
import {ChatEngine} from "../components/SessionViewer/ChatEngine";
import './PresenterShare.scss';

const m = require('moment-timezone');

export const PresenterShare = (props) => {

    const {
    } = props;

    let paths = useParams();
    const [event, setEvent] = useState(null);
    const [session, setSession] = useState(null);
    const [showChat, setShowChat] = useState(false);
    const [hasNewChat, setHasNewChat] = useState(0);
    const [interceptButtonLocationY, setInterceptButtonLocationY] = useState(0);
    const chatEngineRef = useRef(null);
    const password = paths.password;
    const sessionID = paths.sessionId;
    const name = atob(paths?.username || '');
    const iframeRef = useRef(null);
    const {
        postService
    } = useAPI();

    useEffect(() => {
        if (sessionID) {
            const getSessionPayload = {
                session_id: sessionID
            };
            postService(API.sessionInfo, getSessionPayload)
                .then((data) => {
                    if (data) {
                        setEvent(data.event);
                        setSession(data.session);
                    }
                    setTimeout(() => {
                        if (chatEngineRef.current) {
                            chatEngineRef.current.initChat(sessionID, true);
                        }
                    }, 500);
                });
        }
        /*window.addEventListener("resize", orientCloseButton);
        setTimeout(() => {
            orientCloseButton();
        }, 1000);

        return () => {
            window.removeEventListener("resize", orientCloseButton);
        }*/
    }, []);

    const orientCloseButton = () => {
        const location = iframeRef.current.getBoundingClientRect();
        const bottom = location.bottom - 50;
        setInterceptButtonLocationY(bottom);
        console.log('iframe', bottom);
    }

    const stopPublishing = () => {
        const stopPublishingURL = `https://screenshare.conferenceondemand.net/WebRTCAppEE/rest/v2/broadcasts/${password}`;
        fetch(stopPublishingURL, {
            method: 'DELETE'
        }).then(response => response.json())
            .then((json) => {
            });
    };

    const toggleChatSidebar = () => {
        setShowChat(!showChat);
        setHasNewChat(0);
    }

    const handleNewChat = () => {
        if (!showChat) {
            setHasNewChat(hasNewChat + 1);
        }
    }

    const hasChat = session && (session.GROUP_Chat || session.QA_Chat || session.PRIVATE_Chat);
    const splitName = name.split(' ');
    const userInfo = {
        email: '',
        firstname: splitName[0],
        lastname: splitName.length > 1 ? splitName[1] : '',
        userid: Number(sessionID)
    };

    const returnToCOD = () => {

    }

    return (
        <article className={'presenterShare'}>
            <div className={`main_layout_frame ${showChat ? 'chat' : ''}`}>
                <iframe ref={iframeRef} src={`https://www.vmixcall.com/call.aspx?Key=${password}&Name=${name}`}  allow='camera;microphone' width='100%' height='400px' />
                {/*<div style={{top: interceptButtonLocationY + 'px'}} className='click-intercept' onClick={returnToCOD}/>*/}
                {hasChat &&
                <ChatEngine
                    className={`presenter_chat ${!showChat ? 'hide' : ''}`}
                    userInfo={userInfo}
                    eventId={session.eventid}
                    event={event}
                    presenterView={true}
                    chatTypes={{
                        GROUP: session.GROUP_Chat,
                        QA: session.QA_Chat,
                        PRIVATE: session.PRIVATE_Chat
                    }}
                    ref={chatEngineRef}
                    onNewChat={handleNewChat}
                    onHide={(stat) => {
                        setShowChat(!stat);
                    }}
                />
                }
            </div>
            <div className={`actions ${hasNewChat ? 'newchat' : ''}`}>
                <div>
                    {/*<a href={`https://screenshare.conferenceondemand.net/WebRTCAppEE/screen.html?name=${password}`} target='_blank'><button>Screenshare</button></a>*/}
                    {hasChat && <button className='chat_button' onClick={toggleChatSidebar}>{showChat ? 'Close' : 'Open'} Public Chat {hasNewChat ? <span>({hasNewChat})</span> : null}</button>}
                    {/*<button onClick={this.stopPublishing}>Stop Publishing</button>*/}
                </div>
            </div>
        </article>
    )
}
